import { createSlice } from "@reduxjs/toolkit";
import { featuredVideoInitialState } from "./types";

const initialState: featuredVideoInitialState = {
  featuredVideos: [],
  edit: false,
  featuredVideo: null,
  loadingFeaturedVideo: false,
  loadingCreateFeaturedVideo: false,
  count: 0,
  previous: "",
  next: "",
};

export const FeaturedVideo = createSlice({
  name: "featuredVideoReducer",
  initialState,
  reducers: {
    getFeaturedVideoRequest: (state, payload) => {
      state.loadingFeaturedVideo = true;
    },
    getFeaturedVideoSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingFeaturedVideo = false;
      state.featuredVideos = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getFeaturedVideoFail: (state) => {
      state.loadingFeaturedVideo = false;
    },

    getFeaturedVideoNextRequest: (state, payload) => {
      state.loadingFeaturedVideo = true;
    },
    getFeaturedVideoPreviousRequest: (state, payload) => {
      state.loadingFeaturedVideo = true;
    },
    createFeaturedVideoRequest: (state, payload) => {
      state.loadingCreateFeaturedVideo = true;
    },
    updateFeaturedVideoRequest: (state, payload) => {
      state.loadingCreateFeaturedVideo = true;
    },
    createFeaturedVideoSuccess: (state) => {
      state.loadingCreateFeaturedVideo = false;
    },
    createFeaturedVideoFail: (state) => {
      state.loadingCreateFeaturedVideo = false;
    },
    getFeaturedVideoByIdRequest: (state, payload) => {
      state.edit = true;
    },
    featuredVideoEditSuccess: (state, { payload }) => {
      state.featuredVideo = payload;
    },
    featuredVideoEditFail: (state) => {
      state.edit = false;
    },
    updateFeaturedVideoSuccess: (state) => {
      state.loadingCreateFeaturedVideo = false;
    },
    updateFeaturedVideoFail: (state) => {
      state.loadingCreateFeaturedVideo = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.featuredVideo = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getFeaturedVideoRequest,
  getFeaturedVideoFail,
  getFeaturedVideoSuccess,
  getFeaturedVideoNextRequest,
  getFeaturedVideoPreviousRequest,
  createFeaturedVideoFail,
  createFeaturedVideoRequest,
  createFeaturedVideoSuccess,
  featuredVideoEditSuccess,
  updateFeaturedVideoFail,
  updateFeaturedVideoSuccess,
  updateFeaturedVideoRequest,
  clearAllData,
  getFeaturedVideoByIdRequest,
  featuredVideoEditFail,
} = FeaturedVideo.actions;

export default FeaturedVideo.reducer;
