import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  organizations: [],
  edit: false,
  organization: null,
  count: 0,
  previous: "",
  next: "",
  loadingOrganization: false,
  loadingCreateOrganization:false,
  loading_updated: false,
  loadingOrganizationCountry: false,
  countries: [],
};

export const updateOrganizationRequest = createAction(
  "updateOrganizationRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);

export const getOrganizationRequest = createAction(
  "getOrganizationRequest",
  function prepare({ values, rowsPerPage, page }) {
    return {
      payload: {
        values,
        rowsPerPage,
        page,
      },
    };
  }
);
// export const createOrganizationRequest = createAction(
//   "createOrganizationRequest",
//   function prepare({ rowsPerPage, page, filterData }) {
//     return {
//       payload: {
//         rowsPerPage,
//         page,
//         filterData,
//       },
//     };
//   }
// );
// get next request
export const getOrganizationNextRequest = createAction(
  "getOrganizationNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getOrganizationPreviousRequest = createAction(
  "getOrganizationPreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);

export const Organization = createSlice({
  name: "OrganizationReducer",
  initialState,
  reducers: {
    loadingOrganization: (state) => {
      state.loadingOrganization = true;
    },
    getOrganizationSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingOrganization = false;
      state.organizations = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getOrganizationFail: (state) => {
      state.loadingOrganization = false;
    },
    getOrganizationCountrySuccess: (state, { payload: { results } }) => {
      state.loadingOrganizationCountry = false;
      state.countries = results;
    },
    createOrganizationRequest: (state, payload) => {
      state.loadingCreateOrganization = true;
      state.closeModal = true;
    },
    getOrganizationCountryRequest: (state) => {
      state.loadingOrganizationCountry = true;
    },
    getOrganizationCountryFail: (state) => {
      state.loadingOrganizationCountry = false;
    },
    createOrganizationSuccess: (state) => {
      state.loadingCreateOrganization = false;
    },
    createOrganizationFail: (state) => {
      state.loadingCreateOrganization = false;
    },
    getOrganizationByIdRequest: (state, payload) => {
      state.edit = true;
    },
    organizationEditSuccess: (state, { payload }) => {
      state.organization = payload;
    },
    organizationEditFail : (state) => {
      state.edit = false;
    },
    updateOrganizationSuccess: (state) => {
      state.loadingCreateOrganization = false;
    },
    updateOrganizationFail: (state) => {
      state.loadingCreateOrganization = false;
    },
    clearOrganizationData: (state) => {
      state.edit = false;
      state.organization = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingOrganization,
  getOrganizationSuccess,
  getOrganizationFail,
  createOrganizationSuccess,
  createOrganizationFail,
  organizationEditSuccess,
  updateOrganizationSuccess,
  updateOrganizationFail,
  clearOrganizationData,
  getOrganizationCountrySuccess,
  getOrganizationCountryFail,
  createOrganizationRequest,
  getOrganizationCountryRequest,
  getOrganizationByIdRequest,
  organizationEditFail,
} = Organization.actions;
export default Organization.reducer;
