import { createSlice } from "@reduxjs/toolkit";
import { initialStateBlog } from "./types";
const initialState: initialStateBlog = {
  blog: null,
  blogs: [],
  loadingBlog: false,
  edit: false,
  loadingUpdateBlog: false,
  categories: [],
  loadingBlogCategory: false,
  blogTags: [],
  loadingBlogTag: false,
  authors: [],
  loadingBlogAuthor: false,
  count: 0,
  previous: "",
  next: "",
};

export const Blog = createSlice({
  name: "BlogReducer",
  initialState,
  reducers: {
    loadingBlog: (state) => {
      state.loadingBlog = true;
    },
    getBlogRequest: (state, payload) => {
      state.loadingBlog = true;
    },
    getBlogSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingBlog = false;
      state.blogs = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getBlogFail: (state) => {
      state.loadingBlog = false;
    },
    //
    createBlogRequest: (state, payload) => {
      state.loadingUpdateBlog = true;
    },
    createBlogSuccess: (state) => {
      state.loadingUpdateBlog = false;
    },
    createBlogFail: (state) => {
      state.loadingUpdateBlog = false;
    },
    //
    loadingBlogCategoryRequest: (state) => {
      state.loadingBlogCategory = true;
    },
    getBlogCategoryRequest: (state, payload) => {
      state.loadingBlogCategory = true;
    },
    getBlogCategorySuccess: (state, { payload: { results } }) => {
      state.loadingBlogCategory = false;
      state.categories = results;
    },
    getBlogCategoryFail: (state) => {
      state.loadingBlogCategory = false;
    },
    //
    loadingBlogAuthorRequest: (state) => {
      state.loadingBlogAuthor = true;
    },
    getBlogAuthorRequest: (state, payload) => {
      state.loadingBlogAuthor = true;
    },
    getBlogAuthorSuccess: (state, { payload: { results } }) => {
      state.loadingBlogAuthor = false;
      state.authors = results;
    },
    getBlogAuthorFail: (state) => {
      state.loadingBlogAuthor = false;
    },
    //
    loadingBlogTagRequest: (state) => {
      state.loadingBlogTag = true;
    },
    getBlogTagRequest: (state, payload) => {
      state.loadingBlogTag = true;
    },
    getBlogTagSuccess: (state, { payload: { results } }) => {
      state.loadingBlogTag = false;
      state.blogTags = results;
    },
    getBlogTagFail: (state) => {
      state.loadingBlogTag = false;
    },
    //
    getBlogNextRequest: (state, payload) => {
      state.loadingBlog = true;
    },
    getBlogPreviousRequest: (state, payload) => {
      state.loadingBlog = true;
    },
    //
    getBlogByIdRequest: (state, payload) => {
      state.edit = true;
    },
    editBlogSuccess: (state, { payload }) => {
      state.blog = payload;
    },
    editBlogFail: (state) => {
      state.edit = false;
    },
    //
    updateBlogRequest: (state, payload) => {
      state.loadingUpdateBlog = true;
    },
    updateBlogSuccess: (state) => {
      state.loadingUpdateBlog = false;
    },
    updateBlogFail: (state) => {
      state.loadingUpdateBlog = false;
    },
    //
    clearBlogData: (state) => {
      state.blog = null;
      state.edit = false;
    },
  },
});

export const {
  loadingBlog,
  getBlogRequest,
  getBlogSuccess,
  getBlogFail,
  //
  createBlogRequest,
  createBlogSuccess,
  createBlogFail,
  //
  loadingBlogCategoryRequest,
  getBlogCategoryRequest,
  getBlogCategorySuccess,
  getBlogCategoryFail,
  //
  loadingBlogTagRequest,
  getBlogTagRequest,
  getBlogTagSuccess,
  getBlogTagFail,
  //
  getBlogNextRequest,
  getBlogPreviousRequest,
  //
  getBlogByIdRequest,
  editBlogSuccess,
  editBlogFail,
  //
  updateBlogRequest,
  updateBlogSuccess,
  updateBlogFail,
  //
  loadingBlogAuthorRequest,
  getBlogAuthorRequest,
  getBlogAuthorSuccess,
  getBlogAuthorFail,
  //
  clearBlogData,
} = Blog.actions;
export default Blog.reducer;
