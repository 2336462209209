import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const generalFaqsURL = "api/v1/admin/website-setup-app/general-faqs";

//get general faqs
export const getGeneralFaqs = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${generalFaqsURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};

//get generalFaqs by id
export const getGeneralFaqsById = (id: number) => {
  return axiosInstance.get(`${generalFaqsURL}/${id}`);
};
//search generalFaqs
export const searchGeneralFaqs = ({ rowsPerPage, search }: searchProps) => {
  return `${generalFaqsURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};
//update generalFaqs
export const updateGeneralFaqs = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${generalFaqsURL}/${id}`, body);
//create generalFaqs
export const createGeneralFaqs = (body: string | FormData) =>
  axiosInstance.post(`${generalFaqsURL}`, body);
