import {
  createTagSuccess,
  createTagFail,
  createTagRequest,
  getTagSuccess,
  getTagFail,
  updateTagFail,
  updateTagSuccess,
  getTagRequest,
  updateTagRequest,
  getTagNextRequest,
  getTagPreviousRequest,
  clearTagData,
  getTagByIdRequest,
  tagEditFail,
  tagEditSuccess,
} from "./tagSlice";
import { mergeMap } from "rxjs";
import { createTag, getTag, getTagById, updateTag } from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get Tag
const getTagEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTagRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTag(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTagSuccess(action?.payload) : getTagFail()
    )
  );

//get Tag By Id epic
const getTagByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTagByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTagById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { errors: e };
      }
    }),
    map((action) =>
      action?.payload ? tagEditSuccess(action.payload) : tagEditFail()
    )
  );

//get next
const getTagNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTagNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTagSuccess(action?.payload) : getTagFail()
    )
  );

//get previous
const getTagPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTagPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTagSuccess(action?.payload) : getTagFail()
    )
  );
//create Tag epic
const createTagEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createTagRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        const { name, isActive } = values;
        try {
          const body = JSON.stringify({ name, isActive });
          const response = await createTag(body);
          if (response) {
            dispatch(getTagRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload ? createTagSuccess() : createTagFail();
    })
  );

//update Tag epic
const updateTagEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateTagRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, isActive } = values;
      try {
        const body = JSON.stringify({
          name,
          isActive,
        });
        const response = await updateTag(body, id);
        if (response) {
          dispatch(getTagRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearTagData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => (action?.payload ? updateTagSuccess() : updateTagFail()))
  );

export const blogTagEpics = combineEpics(
  getTagEpic,
  createTagEpic,
  updateTagEpic,
  getTagNext,
  getTagPrevious,
  getTagByIdEpic
);
