import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { UserWiseReport } from "./types";

const UserWiseReportModURL = '/api/v1/inventory-financial-report-app/user-wise-sales-report';


export const getUserWiseReportSupplier = () =>
axiosInstance.get(`${UserWiseReportModURL}/supplier?offset=0&limit=${0}`);
export const getUserWiseReportUser = () =>
axiosInstance.get(`${UserWiseReportModURL}/user?offset=0&limit=${0}`);
export const getUserWiseReportItem = () =>
axiosInstance.get(`${UserWiseReportModURL}/item?offset=0&limit=${0}`);
export const getUserWiseReportAllReport = (data:any) =>{
  const {updatedValue} = data;
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${UserWiseReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  )};
export const getUserWiseReportQuickReport = (data: any) => {
  const { rowsPerPage, page ,updatedValue} = data;
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${UserWiseReportModURL}/${summary?"summary":"detail"}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};

export const searchUserWiseReport = ({ rowsPerPage, search ,updatedValue}: any) => {
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;

  return `${UserWiseReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${rowsPerPage}&search=${search}&date_after=${startDateAd}&date_before=${endDateAd}`;
};