import { Form, Formik } from "formik";
import * as Yup from "yup";
import "../LoginPages/login.css";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
// import Button from "../../Component/Button/Button";
import { useAppDispatch } from "../../../AppUtils/Utils/appHooks";
import AuthRightSide from "../AuthRightSide";
import AppTextField from "../../../Component/AppElements/AppTextField/AppTextField";
import { confirmPasswordReq } from "../Redux/authSlice";
import { useRef } from "react";
import { useImmer } from "use-immer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import messages from "../../../AppUtils/Utils/validationConstants";
import { resetPassword } from "../Redux/types";
import jwt_decode from "jwt-decode";
import { useHistory, useParams } from "react-router-dom";
import AppButton from "../../../Component/AppElements/Button/AppButton";

const ResetPasswordConfirm = () => {
  // @ts-ignore
  const { token } = useParams();
  console.log(token, "what is token");
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useImmer(false);
  const [confirmPassword, setShowConfirmPassword] = useImmer(false);
  // const decoded = jwt_decode(token);
  let history = useHistory();
  // initial values for the reset password
  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  //validation rule for the form field in formik
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(messages.required)
      .matches(
        /^(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/,
        messages.password
      ),
    confirmPassword: Yup.string()
      .required(messages.required)
      .oneOf([Yup.ref("newPassword"), null], messages.confirmPassword),
  });

  //submit handler for formik
  const onSubmit = (values: resetPassword) => {
    // const token = props.match.params.token;
    dispatch(
      // @ts-ignore
      confirmPasswordReq({ ...values, token, history })
    );
  };
  const focusConfirmPassword = useRef<HTMLInputElement>(null);
  const handlePassword = () => {
    setShowPassword((prev) => !prev);
  };
  const handleConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  return (
    <div className="form-wrapper">
      <Paper elevation={20} className="paperStyle">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          flexWrap="nowrap"
          className="paper-style-wrapper"
        >
          <Grid item xs={6} sm={6} md={6} className="form-container">
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} mb={2}>
                <Typography variant="h4">Choose a New Password</Typography>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, handleChange, handleBlur, isSubmitting }) => {
                    return (
                      <Form autoComplete="off" className="form-horizontal ">
                        <Grid container spacing={2}>
                          <AppTextField
                            xs={12}
                            autoComplete="on"
                            size="small"
                            required
                            focusElement={focusConfirmPassword}
                            onBlur={handleBlur}
                            label="New Password"
                            type={showPassword ? "text" : "password"}
                            variant="outlined"
                            name="newPassword"
                            onChange={handleChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handlePassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <AppTextField
                            xs={12}
                            autoComplete="on"
                            size="small"
                            required
                            inputRef={focusConfirmPassword}
                            onBlur={handleBlur}
                            label="Confirm Password"
                            type={confirmPassword ? "text" : "password"}
                            variant="outlined"
                            name="confirmPassword"
                            onChange={handleChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleConfirmPassword}
                                    edge="end"
                                  >
                                    {confirmPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid container my={2} className="button-wrapper">
                          <Grid item>
                            <AppButton
                              title="Reset"
                              loading={isSubmitting}
                              submit={true}
                            />
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
          </Grid>

          {/* Login page right side */}
          <AuthRightSide />
        </Grid>
      </Paper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="meraki-tag-container"
      >
        <Grid item>
          <Typography variant="body2" className="meraki-tag">
            &copy; {new Date().getFullYear()}
            <Typography
              className="meraki-link"
              component="a"
              variant="body2"
              href="http://merakitechs.com/"
              target="_blank"
            >
              Meraki Techs Pvt. Ltd.
            </Typography>
            All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPasswordConfirm;
