export const websiteAlbumPermission = ["view_album", "add_album", "edit_album"];
export const websiteBannerPermission = [
  "view_banner",
  "add_banner",
  "edit_banner",
];
export const websiteFeaturedImagePermission = [
  "view_featured_image",
  "add_featured_image",
  "edit_featured_image",
];

export const websiteFeaturedProductPermission = [
  "view_featured_product",
  "add_featured_product",
  "edit_featured_product",
];

export const websiteFeaturedVideoPermission = [
  "view_featured_video",
  "add_featured_video",
  "edit_featured_video",
];

export const websiteOurPartnerPermission = [
  "view_our_partner",
  "add_our_partner",
  "edit_our_partner",
];

export const websiteGeneralFaqsPermission = [
  "view_general_faqs",
  "add_general_faqs",
  "edit_general_faqs",
];

export const websiteOurTeamPermission = [
  "view_our_team",
  "add_our_team",
  "edit_our_team",
];

export const websiteBlogTagPermission = [
  "view_blog_tag",
  "add_blog_tag",
  "edit_blog_tag",
];

export const websiteBlogCategoryPermission = [
  "view_blog_category",
  "add_blog_category",
  "edit_blog_category",
];

export const websiteBlogPermission = ["view_blog", "add_blog", "edit_blog"];

export const websitePromoCodePermission = [
  "view_promo_code",
  "add_promo_code",
  "edit_promo_code",
];
