import {
  createCountryFail,
  createCountryRequest,
  createCountrySuccess,
  getCountryFail,
  getCountrySuccess,
  loadingCountry,
  updateCountryFail,
  updateCountrySuccess,
  getCountryRequest,
  updateCountryRequest,
  getCountryNextRequest,
  getCountryPreviousRequest,
  clearCountryData,
  getCountryByIdRequest,
  countryEditFail,
  countryEditSuccess,
} from "./countrySlice";
import { mergeMap } from "rxjs";
import {
  createCountry,
  getCountry,
  getCountryById,
  updateCountry,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get Country epic
const getCountryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCountryRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCountry());
      try {
        const response = await getCountry(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCountrySuccess(action?.payload) : getCountryFail()
    )
  );

//get country by Id
const getCountryByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCountryByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCountryById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? countryEditSuccess(action?.payload) : countryEditFail()
    )
  );

//get next
const getCountryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCountryNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCountry());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCountrySuccess(action?.payload) : getCountryFail()
    )
  );
//get previous
const getCountryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCountryPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCountry());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCountrySuccess(action?.payload) : getCountryFail()
    )
  );
//create Country epic
const createCountryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createCountryRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await createCountry(body);
        if (response) {
          dispatch(getCountryRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createCountrySuccess() : createCountryFail();
    })
  );
//update Country epic
const updateCountryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateCountryRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, value as string | Blob);
        }
        const response = await updateCountry(body, id);
        if (response) {
          dispatch(getCountryRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearCountryData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateCountrySuccess() : updateCountryFail()
    )
  );


export const countryEpics = combineEpics(
  getCountryEpic,
  createCountryEpic,
  updateCountryEpic,
  getCountryNext,
  getCountryPrevious,
  getCountryByIdEpic
);
