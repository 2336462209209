import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { searchProps, getApiProps } from "../../../../AppUtils/Utils/globalTypes";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

const discountSchemeModURL = "api/v1/core-app/discount-scheme";

//get data
export const getDiscountSchema = (data: getApiProps) => {
  const { rowsPerPage, page, search} = data;
  return axiosInstance.get(`${discountSchemeModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get discount scheme by id
export const getDiscountSchemeById = (id: number) => {
  return axiosInstance.get(`${discountSchemeModURL}/${id}`);
};

//search
export const searchDiscountSchema = ({ rowsPerPage, search}: searchProps) => {
 return  `${discountSchemeModURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;

}

//update function
export const updateDiscountSchema = (
  body: string | FormData,
  id: number
) => axiosInstance.patch(`${discountSchemeModURL}/${id}`, body);

//create
export const createDiscountSchema = (body: string | FormData) =>
  axiosInstance.post(`${discountSchemeModURL}`, body);

