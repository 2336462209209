import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  error: {
    color: "red",
    fontSize: "0.8rem",
    fontWeight: "200",
    fontStyle: "normal",
  },
}));
