import { createSlice, createAction } from "@reduxjs/toolkit";
import { SupplierWiseReportInitialState } from "./types";
const initialState: SupplierWiseReportInitialState = {
  users: [],
  supplierWiseDetails: [],
  //loading 
  loadingGenerateReport: false,
  loadingSupplierWiseReportUser: false,
  loadingSupplierWiseReportDetail: false,
  count: 0,
  previous: "",
  next: "",
};

// export const getSupplierWiseReportDetailRequest = createAction(
//   "getSupplierWiseReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const SupplierWiseReport = createSlice({
  name: "SupplierWiseReportReducer",
  initialState,
  reducers: {
    //loading
    loadingSupplierWiseReportDetail: (state) => {
      state.loadingSupplierWiseReportDetail = true;
    },
    //external reducer request
    getSupplierWiseReportDetailRequest: (state, { payload }) => {
      state.loadingSupplierWiseReportDetail = true;
    },
    getSupplierWiseReportUserRequest: (state) => {
      state.loadingSupplierWiseReportUser = true;
    },
    getSupplierWiseReportAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getSupplierWiseReportAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getSupplierWiseReportQuickSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getSupplierWiseReportQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getSupplierWiseReportNextRequest: (state, payload) => {
      state.loadingSupplierWiseReportDetail = true;
    },
    getSupplierWiseReportPreviousRequest: (state, payload) => {
      state.loadingSupplierWiseReportDetail = true;
    },
    //external get reducer  
    getSupplierWiseReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.supplierWiseDetails = results;
      state.loadingSupplierWiseReportDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSupplierWiseReportDetailFail: (state) => {
      state.loadingSupplierWiseReportDetail = false;
    },
    getSupplierWiseReportUserSuccess: (state, { payload: { results } }) => {
      state.loadingSupplierWiseReportUser = false;
      state.users = results;
    },
    getSupplierWiseReportUserFail: (state) => {
      state.loadingSupplierWiseReportUser = false;
    },
    getSupplierWiseReportAllSummarySuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.supplierWiseDetails = results;
    },
    getSupplierWiseReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getSupplierWiseReportQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.supplierWiseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSupplierWiseReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getSupplierWiseReportAllDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.supplierWiseDetails = results;
    },
    getSupplierWiseReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getSupplierWiseReportQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.supplierWiseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSupplierWiseReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearSupplierWiseReportData: (state) => {
      state.users = [];
    },
    clearPrintUserActivityLogData: (state) => {
      state.supplierWiseDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getSupplierWiseReportDetailRequest,
  getSupplierWiseReportUserRequest,
  getSupplierWiseReportAllSummaryRequest,
  getSupplierWiseReportAllDetailRequest,
  getSupplierWiseReportQuickSummaryRequest,
  getSupplierWiseReportQuickDetailRequest,
  getSupplierWiseReportPreviousRequest,
  getSupplierWiseReportNextRequest,
  //reducer get data
  getSupplierWiseReportDetailSuccess,
  getSupplierWiseReportDetailFail,
  getSupplierWiseReportUserSuccess,
  getSupplierWiseReportUserFail,
  getSupplierWiseReportQuickDetailSuccess,
  getSupplierWiseReportQuickDetailFail,
  getSupplierWiseReportAllDetailSuccess,
  getSupplierWiseReportAllDetailFail,
  getSupplierWiseReportQuickSummarySuccess,
  getSupplierWiseReportQuickSummaryFail,
  getSupplierWiseReportAllSummarySuccess,
  getSupplierWiseReportAllSummaryFail,
  //loading
  loadingSupplierWiseReportDetail,
  //clear
  clearSupplierWiseReportData,
  clearPrintUserActivityLogData,
} = SupplierWiseReport.actions;
export default SupplierWiseReport.reducer;
