import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
import axios from "axios";
//get OrganizationRule data api
export const getOrganizationRule = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `api/v1/core-app/organization-rule?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

export const getTaxGroup = ({ rowsPerPage }: any) =>
  axiosInstance.get(
    `api/v1/core-app/organization-rule/tax-group?offset=0&limit=${rowsPerPage}`
  );
//get organization-rule by Id
export const getOrganizationRuleById = (id: number) => {
  return axiosInstance.get(`api/v1/core-app/organization-rule/${id}`);
};

//handle search
export const searchOrganizationRule = ({
  rowsPerPage,
  search,
}: searchProps) => {
  return `api/v1/core-app/organization-rule?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create OrganizationRule
export const createOrganizationRule = (body: string | FormData) =>
  axiosInstance.post(`api/v1/core-app/organization-rule`, body);
//update OrganizationRule
export const updateOrganizationRule = (body: string | FormData, id: number) =>
  axiosInstance.patch(`api/v1/core-app/organization-rule/${id}`, body);
