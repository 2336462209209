import { createSlice } from "@reduxjs/toolkit";
import { ourPartnerInitialState } from "./types";

const initialState: ourPartnerInitialState = {
  ourPartners: [],
  edit: false,
  ourPartner: null,
  loadingOurPartner: false,
  loadingCreateOurPartner: false,
  count: 0,
  previous: "",
  next: "",
};

export const OurPartner = createSlice({
  name: "ourPartnerReducer",
  initialState,
  reducers: {
    getOurPartnerRequest: (state, payload) => {
      state.loadingOurPartner = true;
    },
    getOurPartnerSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingOurPartner = false;
      state.ourPartners = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getOurPartnerFail: (state) => {
      state.loadingOurPartner = false;
    },

    getOurPartnerNextRequest: (state, payload) => {
      state.loadingOurPartner = true;
    },
    getOurPartnerPreviousRequest: (state, payload) => {
      state.loadingOurPartner = true;
    },
    createOurPartnerRequest: (state, payload) => {
      state.loadingCreateOurPartner = true;
    },
    updateOurPartnerRequest: (state, payload) => {
      state.loadingCreateOurPartner = true;
    },
    createOurPartnerSuccess: (state) => {
      state.loadingCreateOurPartner = false;
    },
    createOurPartnerFail: (state) => {
      state.loadingCreateOurPartner = false;
    },
    getOurPartnerByIdRequest: (state, payload) => {
      state.edit = true;
    },
    ourPartnerEditSuccess: (state, { payload }) => {
      state.ourPartner = payload;
    },
    ourPartnerEditFail: (state) => {
      state.edit = false;
    },
    updateOurPartnerSuccess: (state) => {
      state.loadingCreateOurPartner = false;
    },
    updateOurPartnerFail: (state) => {
      state.loadingCreateOurPartner = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.ourPartner = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getOurPartnerRequest,
  getOurPartnerFail,
  getOurPartnerSuccess,
  getOurPartnerNextRequest,
  getOurPartnerPreviousRequest,
  createOurPartnerFail,
  createOurPartnerRequest,
  createOurPartnerSuccess,
  ourPartnerEditSuccess,
  updateOurPartnerFail,
  updateOurPartnerSuccess,
  updateOurPartnerRequest,
  clearAllData,
  getOurPartnerByIdRequest,
  ourPartnerEditFail,
} = OurPartner.actions;

export default OurPartner.reducer;
