import spinner from "../../Assets/spinner.gif";
const Spinner = ({ color, height }: any) => {
  return (
    <img
      src={spinner}
      alt="spinner"
      color={color}
      height={height}
      className="ml-4"
    />
  );
};

export default Spinner;
