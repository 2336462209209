import { Tooltip } from "@mui/material";
import { tooltipProps } from "./types";

const TooltipContent = ({ title, children }: tooltipProps) => {
  return (
    <Tooltip title={title} placement="top" disableInteractive>
      <div>{children}</div>
    </Tooltip>
  );
};

export default TooltipContent;
