import { combineReducers } from "@reduxjs/toolkit";
import CommonAppSlice from "../Pages/CommonAppRedux/CommonAppSlice";
import PaymentMode from "../Pages/CoreSetupPages/PaymentMode/Redux/PaymentModeSlice";
import User from "../Pages/CoreSetupPages/User/Redux/userSlice";
import Country from "../Pages/CoreSetupPages/Country/Redux/countrySlice";
import Province from "../Pages/CoreSetupPages/Province/Redux/provinceSlice";
import District from "../Pages/CoreSetupPages/District/Redux/districtSlice";
import Palika from "../Pages/CoreSetupPages/Palika/Redux/palikaSlice";
import TaxGroup from "../Pages/CoreSetupPages/TaxGroup/Redux/taxGroupSlice";
import Auth from "../Pages/Auth/Redux/authSlice";
import Customer from "../Pages/POSPages/Customer/Redux/customerSlice";
// core set up
import Supplier from "../Pages/POSPages/Supplier/Redux/supplierSlice";
import UserRole from "../Pages/CoreSetupPages/UserRole/Redux/userRoleSlice";
import Organization from "../Pages/CoreSetupPages/OrganizationSetup/Redux/organizationSlice";
import Title from "../Pages/CoreSetupPages/Title/Redux/titleSlice";
import DiscountSchema from "../Pages/CoreSetupPages/DiscountScheme/Redux/DiscountSchemeSlice";
import Purchase from "../Pages/POSPages/Purchase/Redux/purchaseSlice";
import AdditionalCharge from "../Pages/CoreSetupPages/AdditionalChargeType/Redux/AdditionalChargeSlice";
import Bank from "../Pages/CoreSetupPages/Bank/Redux/BankSlice";
import OrganizationRule from "../Pages/CoreSetupPages/OrganizationRule/Redux/organizationRuleSlice";
//extra
import PaymentDetails from "../Pages/CommonAppPages/PaymentModeDetails/Redux/paymentDetailsSlice";
//viewDetails
import ItemListDetail from "../Pages/ViewDetails/ItemList/Redux/ItemListDetailSlice";
import PurchaseReport from "../Pages/POSPages/Purchase/PurchaseReport/Redux/purchaseReportSlice";
import SaleReport from "../Pages/POSPages/Purchase/SaleReport/Redux/saleReportSlice";
import CustomerEdit from "../Pages/CoreSetupPages/CustomerEdit/Redux/customerEditSlice";
import AuthReferral from "../Pages/ReferrerLogin/Redux/authReferralSlice";
import PurchaseReturn from "../Pages/POSPages/PurchaseReturnPages/Redux/purchaseReturnSlice";
import Sale from "../Pages/POSPages/Sale/Redux/saleSlice";
import SaleReturn from "../Pages/POSPages/SaleReturn/Redux/saleReturnSlice";
import CustomerWiseReport from "../Pages/POSPages/InventoryReport/CustomerWiseReport/Redux/customerWiseReportSlice";
import SupplierWiseReport from "../Pages/POSPages/InventoryReport/SupplierWiseSaleReport/Redux/supplierWiseReportSlice";
//
import PartyPayment from "../Pages/POSPages/PartyPayment/Redux/partyPaymentSlice";
import CreditClearance from "../Pages/POSPages/CreditClearance/Redux/creditClearanceSlice";
import Password from "../Pages/Auth/Password/Redux/passwordSlice";
import UserWiseReport from "../Pages/POSPages/InventoryReport/UserWiseReport/Redux/userWiseReportSlice";
import StockAnalysisReportInventory from "../Pages/POSPages/InventoryReport/StockAnalysis/Redux/stockAnalysisReportInventorySlice";
import StockAnalysis from "../Pages/POSPages/StockAnalysis/Redux/stockAnalysisSlice";
// product setup
import ProductCategory from "../Pages/ProductSetupPages/ProductCategory/Redux/productCategorySlice";
import Product from "../Pages/ProductSetupPages/Product/Redux/productSlice";

//website setup
import Album from "../Pages/WebsiteSetupPages/Album/Redux/albumSlice";
import Banner from "../Pages/WebsiteSetupPages/HomeBanner/Redux/bannerSlice";
import FeaturedImage from "../Pages/WebsiteSetupPages/FeaturedImages/Redux/featuredImagesSlice";
import FeaturedProduct from "../Pages/WebsiteSetupPages/FeaturedProduct/Redux/featuredProductSlice";
import FeaturedVideo from "../Pages/WebsiteSetupPages/FeaturedVideo/Redux/featuredVideoSlice";
import OurPartner from "../Pages/WebsiteSetupPages/OurPartner/Redux/ourPartnerSlice";
import GeneralFaqs from "../Pages/WebsiteSetupPages/GeneralFaqs/Redux/generalFaqsSlice";
import OurTeam from "../Pages/WebsiteSetupPages/OurTeam/Redux/ourTeamSlice";
import BlogTag from "../Pages/WebsiteSetupPages/BlogTag/Redux/tagSlice";
import BlogCategory from "../Pages/WebsiteSetupPages/BlogCategory/Redux/categorySlice";
import Blog from "../Pages/WebsiteSetupPages/Blog/Redux/blogSlice";
import CustomerOrder from "../Pages/POSPages/Order/Redux/orderSlice";
import PromoCode from "../Pages/WebsiteSetupPages/PromoCode/Redux/promoCodeSlice";

export const rootReducer = combineReducers({
  // master
  commonApp: CommonAppSlice,
  user: User,
  auth: Auth,
  paymentMode: PaymentMode,
  country: Country,
  province: Province,
  district: District,
  palika: Palika,
  taxGroup: TaxGroup,
  userRole: UserRole,
  organization: Organization,
  title: Title,
  discountSchema: DiscountSchema,
  additionalCharge: AdditionalCharge,
  bank: Bank,
  paymentDetail: PaymentDetails,
  organizationRule: OrganizationRule,
  // inventory
  supplier: Supplier,
  purchase: Purchase,
  purchaseReturn: PurchaseReturn,
  //purchase Report
  purchaseReport: PurchaseReport,
  saleReport: SaleReport,
  saleReturn: SaleReturn,

  partyPayment: PartyPayment,

  // inv common app
  inventorySale: Sale,
  customer: Customer,
  //viewDetails
  itemListDetail: ItemListDetail,
  //foreign worker
  //customer edit
  customerEdit: CustomerEdit,
  //referral login,
  authReferral: AuthReferral,
  //change password
  password: Password,

  //inventory
  customerOrder: CustomerOrder,
  customerWiseReport: CustomerWiseReport,
  supplierWiseReport: SupplierWiseReport,
  creditClearance: CreditClearance,
  userWiseReport: UserWiseReport,
  stockAnalysisReportInventory: StockAnalysisReportInventory,
  stockAnalysis: StockAnalysis,

  //product setup
  productCategory: ProductCategory,
  product: Product,

  //website setup
  album: Album,
  banner: Banner,
  featuredImage: FeaturedImage,
  featuredProduct: FeaturedProduct,
  featuredVideo: FeaturedVideo,
  ourPartner: OurPartner,
  generalFaqs: GeneralFaqs,
  ourTeam: OurTeam,
  blogTag: BlogTag,
  blogCategory: BlogCategory,
  blog: Blog,
  promoCode: PromoCode,
});
