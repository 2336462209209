import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const ourPartnerURL = "api/v1/admin/website-setup-app/our-partners";

//get ablum
export const getOurPartner = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${ourPartnerURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};

//get ourPartner by id
export const getOurPartnerById = (id: number) => {
  return axiosInstance.get(`${ourPartnerURL}/${id}`);
};
//search ourPartner
export const searchOurPartner = ({ rowsPerPage, search }: searchProps) => {
  return `${ourPartnerURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};
//update ourPartner
export const updateOurPartner = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${ourPartnerURL}/${id}`, body);
//create ourPartner
export const createOurPartner = (body: string | FormData) =>
  axiosInstance.post(`${ourPartnerURL}`, body);
