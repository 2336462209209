import {
  createPalikaFail,
  createPalikaRequest,
  createPalikaSuccess,
  //
  getPalikaRequest,
  getPalikaFail,
  getPalikaSuccess,
  loadingPalika,
  //
  updatePalikaFail,
  updatePalikaSuccess,
  updatePalikaRequest,
  //
  getPalikaNextRequest,
  getPalikaPreviousRequest,
  //
  clearPalikaData,
  //
  getPalikaByIdRequest,
  //
  palikaEditSuccess,
  palikaEditFail,
  //
  getPalikaDistrictRequest,
  getPalikaDistrictSuccess,
  getPalikaDistrictFail,
  //
  getDefaultPalikaRequest,
  getDefaultPalikaSuccess,
  getDefaultPalikaFail,
} from "./palikaSlice";
import { mergeMap } from "rxjs";
import {
  createPalika,
  getPalika,
  getPalikaById,
  getPalikaDistrict,
  updatePalika,
  getDefaultPalika,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get Palika epic
const getPalikaEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPalikaRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingPalika());
      try {
        const response = await getPalika(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getPalikaSuccess(action?.payload) : getPalikaFail()
    )
  );

//get palike by id epic
const getPalikaByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPalikaByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPalikaById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? palikaEditSuccess(action?.payload) : palikaEditFail()
    )
  );

//get palika district
const getPalikaDistrictEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPalikaDistrictRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPalikaDistrict();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPalikaDistrictSuccess(action?.payload)
        : getPalikaDistrictFail()
    )
  );

//get default palika epic
const getDefaultPalikaEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDefaultPalikaRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDefaultPalika();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDefaultPalikaSuccess(action?.payload)
        : getDefaultPalikaFail()
    )
  );

//get next
const getPalikaNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPalikaNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingPalika());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getPalikaSuccess(action?.payload) : getPalikaFail()
    )
  );
//get previous
const getPalikaPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPalikaPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingPalika());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getPalikaSuccess(action?.payload) : getPalikaFail()
    )
  );
//create Palika epic
const createPalikaEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createPalikaRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { name, district, isDefault, active } = values;
      try {
        const body = JSON.stringify(values);
        const response = await createPalika(body);
        if (response) {
          dispatch(getPalikaRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createPalikaSuccess() : createPalikaFail();
    })
  );
//update Palika epic
const updatePalikaEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updatePalikaRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, district, isDefault, active, remarks } = values;
      try {
        const body = JSON.stringify(values);
        const response = await updatePalika(body, id);
        if (response) {
          dispatch(getPalikaRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearPalikaData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updatePalikaSuccess() : updatePalikaFail()
    )
  );

//

export const palikaEpics = combineEpics(
  getPalikaEpic,
  createPalikaEpic,
  updatePalikaEpic,
  getPalikaNext,
  getPalikaPrevious,
  getPalikaByIdEpic,
  getPalikaDistrictEpic,
  getDefaultPalikaEpic
);
