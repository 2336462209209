import {
  getAlbumRequest,
  getAlbumFail,
  getAlbumSuccess,
  createAlbumFail,
  createAlbumRequest,
  createAlbumSuccess,
  updateAlbumFail,
  updateAlbumSuccess,
  clearAllData,
  getAlbumNextRequest,
  getAlbumPreviousRequest,
  updateAlbumRequest,
  getAlbumByIdRequest,
  albumEditSuccess,
  albumEditFail,
} from "./albumSlice";
import { mergeMap } from "rxjs";
import { getAlbum, createAlbum, updateAlbum, getAlbumById } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get Album
const getAlbumEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAlbumRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getAlbum(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getAlbumSuccess(action?.payload) : getAlbumFail()
    )
  );

//get album by id epic
const getAlbumByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAlbumByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getAlbumById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? albumEditSuccess(action.payload) : albumEditFail()
    )
  );

//create
const createAlbumEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createAlbumRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { image, ...restValues } = values;
      console.log("value of image in the epic", image);
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof image !== "string") {
          body.append("image", image);
        }
        const response = await createAlbum(body);
        if (response) {
          dispatch(getAlbumRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createAlbumSuccess() : createAlbumFail();
    })
  );

//update
const updateAlbumEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateAlbumRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      const { image, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof image !== "string") {
          body.append("image", image);
        }
        const response = await updateAlbum(body, id);
        if (response) {
          dispatch(getAlbumRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updateAlbumSuccess() : updateAlbumFail();
    })
  );

//get next
const getAlbumNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAlbumNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getAlbumSuccess(action?.payload)
        : getAlbumFail();
    })
  );

//get previous
const getAlbumPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAlbumPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getAlbumSuccess(action?.payload) : getAlbumFail()
    )
  );

export const albumEpic = combineEpics(
  getAlbumEpic,
  createAlbumEpic,
  updateAlbumEpic,
  getAlbumNext,
  getAlbumPrevious,
  getAlbumByIdEpic
);
