import { createSlice } from "@reduxjs/toolkit";
import { saleReturnInitialState, specificSaleDetail } from "./types";

const initialState: saleReturnInitialState = {
  saleReturns: [],
  saleReturnCustomer: {},
  saleReturnItems: [],
  loadingSpecificSale: false,
  loadingCreateSaleReturn: false,
  specificSales: [],
  loadingAllSales: false,
  allSales: [],
  specificSaleDetails: [],
  // sale return additional charges
  loadingSaleReturnAdditionalCharges: false,
  saleReturnAdditionalCharges: [],
  loadingSaleReturn: false,
  loadingSpecificSaleDetails: false,
  loadingSaleReturnItem: false,
  loadingSaleReturnPaymentMode: false,
  // sale return view details
  loadingSaleReturnViewDetails: false,
  saleReturnRowData: null,
  saleReturnId: "",
  viewSaleReturnDetails: [],
  saleReturnDetailCount: 0,
  saleReturnDetailNext: 0,
  saleReturnDetailPrevious: 0,
  // retrieve details
  loadingSaleReturnDetails: false,
  saleReturnPaymentModes: [],
  count: 0,
  previous: "",
  next: "",
  // sale return id
  createdSaleReturnId: "",
  loadingSaleReturnCustomer: false,
};

// sale slice
export const SaleReturn = createSlice({
  name: "saleReturnReducer",
  initialState,
  reducers: {
    loadingSaleReturn: (state) => {
      state.loadingSaleReturn = true;
    },
    getSaleReturnRequest: (state, payload) => {
      state.loadingSaleReturn = true;
    },
    getSaleReturnSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSaleReturn = false;
      state.saleReturns = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSaleReturnFail: (state) => {
      state.loadingSaleReturn = false;
    },
    getSaleReturnNextRequest: (state, payload) => {
      state.loadingSaleReturn = true;
    },
    getSaleReturnPreviousRequest: (state, payload) => {
      state.loadingSaleReturn = true;
    },

    getSpecificSaleRequest: (state, payload) => {
      state.loadingSpecificSale = true;
    },
    getSpecificSaleSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSpecificSale = false;
      state.specificSales = results;
    },
    getSpecificSaleFail: (state) => {
      state.loadingSpecificSale = false;
    },

    // Get all sales request
    getAllSalesRequest: (state, payload) => {
      state.loadingAllSales = true;
    },
    getAllSalesSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingAllSales = false;
      state.allSales = results;
    },
    getAllSalesFail: (state) => {
      state.loadingAllSales = false;
    },
    loadingAllSalesReq: (state) => {
      state.loadingAllSales = true;
    },

    // payment modes
    getSaleReturnPaymentModeRequest: (state) => {
      state.loadingSaleReturnPaymentMode = true;
    },
    getSaleReturnPaymentModeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSaleReturnPaymentMode = false;
      state.saleReturnPaymentModes = results;
    },
    getSaleReturnPaymentModesFail: (state) => {
      state.loadingSpecificSale = false;
    },

    //additional charge
    getSaleReturnAdditionalChargeRequest: (state) => {
      state.loadingSaleReturnAdditionalCharges = true;
    },
    getSaleReturnAdditionalChargeSuccess: (state, { payload }) => {
      state.saleReturnAdditionalCharges = payload.results;
      state.loadingSaleReturnAdditionalCharges = false;
    },
    getSaleReturnAdditionalChargeFail: (state) => {
      state.loadingSaleReturnAdditionalCharges = false;
    },
    getSpecificSaleDetailsRequest: (state, payload) => {
      state.loadingSpecificSaleDetails = true;
    },
    getSpecificSaleDetailsSuccess: (state, { payload: { results } }) => {
      state.loadingSpecificSale = false;
      state.specificSaleDetails = results?.map((result: any) => ({
        ...result,
        netAmount: 0 * result?.saleCost,
        grossAmount: 0 * result?.saleCost,
        discountRate:
          Number(result.discountAmount * 100) /
          ((result?.saleCost / 1.13) * result?.qty === 0
            ? 1
            : (result?.saleCost / 1.13) * result?.qty),
        discountAmount: 0,
        taxAmount: 0,
        returnQty: 0,
      }));
    },
    getSpecificSaleDetailsFail: (state) => {
      state.loadingSpecificSale = false;
    },

    createSaleReturnRequest: (state, payload) => {
      state.loadingCreateSaleReturn = true;
    },
    createSaleReturnSuccess: (state, { payload }) => {
      state.loadingCreateSaleReturn = false;
      state.createdSaleReturnId = payload?.response?.data?.id;
    },
    createSaleReturnFail: (state) => {
      state.loadingCreateSaleReturn = false;
    },
    updateSaleReturnSuccess: (state) => {
      state.loadingCreateSaleReturn = false;
    },
    updateSaleReturnFail: (state) => {
      state.loadingCreateSaleReturn = false;
    },
    updateSaleReturnDetailsSuccess: (state, { payload }) => {
      state.specificSaleDetails = payload;
    },
    loadingSaleReturnCustomerReq: (state) => {
      state.loadingSaleReturnCustomer = true;
    },
    getSaleReturnCustomerRequest: (state, payload) => {
      state.loadingSaleReturnCustomer = true;
    },
    loadingSaleReturnCustomer: (state) => {
      state.loadingSaleReturnCustomer = true;
    },
    getSaleReturnCustomerSuccess: (state, { payload }) => {
      state.saleReturnCustomer = payload?.results[0];
      state.loadingSaleReturnCustomer = false;
    },
    getSaleReturnCustomerFail: (state) => {
      state.loadingSaleReturnCustomer = false;
    },
    clearSaleReturnCustomer: (state) => {
      state.saleReturnCustomer = {};
    },
    //  view sale return details
    getViewSaleReturnDetailsRequest: (state, { payload }) => {
      state.loadingSaleReturnViewDetails = true;
      state.saleReturnRowData = payload?.rowData;
      state.saleReturnId = payload?.id;
    },
    getViewSaleReturnDetailsSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSaleReturnViewDetails = false;
      state.viewSaleReturnDetails = results;
      state.saleReturnDetailCount = count;
      state.saleReturnDetailNext = next;
      state.saleReturnDetailPrevious = previous;
    },
    getViewSaleReturnDetailsFail: (state) => {
      state.loadingSaleReturnViewDetails = false;
    },
    getViewSaleReturnDetailsNextRequest: (state, payload) => {
      state.loadingSaleReturnViewDetails = true;
    },
    getViewSaleReturnDetailsPreviousRequest: (state, payload) => {
      state.loadingSaleReturnViewDetails = true;
    },
    // get specific details
    getSaleReturnDetailsRequest: (state, payload) => {
      state.loadingSaleReturnDetails = true;
    },
    getSaleReturnDetailsSuccess: (state, { payload }) => {
      state.saleReturnRowData = payload;
      state.loadingSaleReturnDetails = false;
    },
    getSaleReturnDetailsFail: (state) => {
      state.loadingSaleReturnDetails = false;
    },
    //
    clearSpecificSaleDetails: (state) => {
      state.specificSaleDetails = [];
    },
    clearSaleReturnData: (state) => {
      state.loadingSaleReturn = false;
      state.specificSaleDetails = [];
      state.saleReturnCustomer = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadingSaleReturn,
  getSaleReturnSuccess,
  getSaleReturnFail,
  getSaleReturnRequest,
  clearSaleReturnData,
  createSaleReturnRequest,
  createSaleReturnFail,
  createSaleReturnSuccess,
  loadingSaleReturnCustomerReq,
  getSaleReturnCustomerRequest,
  getSaleReturnCustomerSuccess,
  getSaleReturnCustomerFail,
  updateSaleReturnDetailsSuccess,
  getSaleReturnNextRequest,
  getSaleReturnPreviousRequest,
  getSpecificSaleRequest,
  getSpecificSaleSuccess,
  getSpecificSaleFail,
  getSpecificSaleDetailsRequest,
  getSpecificSaleDetailsSuccess,
  getSpecificSaleDetailsFail,
  clearSpecificSaleDetails,
  // payment modes
  getSaleReturnPaymentModeRequest,
  getSaleReturnPaymentModeSuccess,
  getSaleReturnPaymentModesFail,
  // additional charge
  getSaleReturnAdditionalChargeRequest,
  getSaleReturnAdditionalChargeSuccess,
  getSaleReturnAdditionalChargeFail,
  // sale return view details
  getViewSaleReturnDetailsRequest,
  getViewSaleReturnDetailsSuccess,
  getViewSaleReturnDetailsFail,
  getViewSaleReturnDetailsNextRequest,
  getViewSaleReturnDetailsPreviousRequest,
  //retrieve data
  getSaleReturnDetailsSuccess,
  getSaleReturnDetailsRequest,
  getSaleReturnDetailsFail,
  loadingSaleReturnCustomer,
  // all sales
  getAllSalesRequest,
  getAllSalesFail,
  getAllSalesSuccess,
  loadingAllSalesReq,

  clearSaleReturnCustomer,
} = SaleReturn.actions;

export default SaleReturn.reducer;
