import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const featuredImageURL = "api/v1/admin/website-setup-app/featured-images";

//get ablum
export const getFeaturedImage = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${featuredImageURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};

//get featuredImage by id
export const getFeaturedImageById = (id: number) => {
  return axiosInstance.get(`${featuredImageURL}/${id}`);
};
//search featuredImage
export const searchFeaturedImage = ({ rowsPerPage, search }: searchProps) => {
  return `${featuredImageURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};
//update featuredImage
export const updateFeaturedImage = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${featuredImageURL}/${id}`, body);
//create featuredImage
export const createFeaturedImage = (body: string | FormData) =>
  axiosInstance.post(`${featuredImageURL}`, body);
