import {
  getCustomerWiseReportUserRequest,
  getCustomerWiseReportAllSummaryRequest,
  getCustomerWiseReportAllDetailRequest,
  getCustomerWiseReportQuickSummaryRequest,
  getCustomerWiseReportQuickDetailRequest,
  //reducer get data
  getCustomerWiseReportUserSuccess,
  getCustomerWiseReportUserFail,
  getCustomerWiseReportQuickDetailSuccess,
  getCustomerWiseReportQuickDetailFail,
  getCustomerWiseReportAllDetailSuccess,
  getCustomerWiseReportAllDetailFail,
  getCustomerWiseReportQuickSummarySuccess,
  getCustomerWiseReportQuickSummaryFail,
  getCustomerWiseReportAllSummarySuccess,
  getCustomerWiseReportAllSummaryFail,
  getCustomerWiseReportDetailSuccess,
  getCustomerWiseReportDetailFail,
  loadingCustomerWiseReportDetail,
  getCustomerWiseReportPreviousRequest,
  getCustomerWiseReportNextRequest,
  getCustomerWiseReportDetailRequest,
} from "./customerWiseReportSlice";
import { mergeMap } from "rxjs";
import *  as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";

export const controller = new AbortController();

//user
const getCustomerWiseReportUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseReportUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseReportUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseReportUserSuccess(action?.payload)
        : getCustomerWiseReportUserFail()
    )
  );
//item

//get all purchase summary detail
const getCustomerWiseReportAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseReportAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseReportAllReport(action?.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseReportAllSummarySuccess(action?.payload)
        : getCustomerWiseReportAllSummaryFail()
    )
  );
//quick summary
const getCustomerWiseReportQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseReportQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseReportQuickReport(action.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseReportQuickSummarySuccess(action?.payload)
        : getCustomerWiseReportQuickSummaryFail()
    )
  );

//get all purchase summary detail
const getCustomerWiseReportAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseReportAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseReportAllReport(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseReportAllDetailSuccess(action?.payload)
        : getCustomerWiseReportAllDetailFail()
    )
  );
//quick Detail
const getCustomerWiseReportQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseReportQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseReportQuickDetailSuccess(action?.payload)
        : getCustomerWiseReportQuickDetailFail()
    )
  );

//next and previous
//get next
const getCustomerWiseReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCustomerWiseReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseReportDetailSuccess(action?.payload)
        : getCustomerWiseReportDetailFail()
    )
  );
//get previous
const getCustomerWiseReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCustomerWiseReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseReportDetailSuccess(action?.payload)
        : getCustomerWiseReportDetailFail()
    )
  );
//het purchase report details
const getCustomerWiseReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerWiseReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerWiseReportQuickReport(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerWiseReportDetailSuccess(action?.payload)
        : getCustomerWiseReportDetailFail()
    )
  );
export const CustomerWiseReportEpics = combineEpics(
  getCustomerWiseReportUserEpic,
  getCustomerWiseReportAllSummaryEpic,
  getCustomerWiseReportQuickSummaryEpic,
  getCustomerWiseReportQuickDetailEpic,
  getCustomerWiseReportAllDetailEpic,
  getCustomerWiseReportPrevious,
  getCustomerWiseReportNext,
  getCustomerWiseReportEpic,
);
