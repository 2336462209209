import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { defaultRowsPerPage } from "../../../../AppUtils/Utils/globalConstant";
export const purchaseURL = "api/v1/admin/inventory-app/inv-purchase/purchase";
//get purchase  data api
export const getPurchase = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${purchaseURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchPurchase = ({ rowsPerPage, search }: searchProps) => {
  return `${purchaseURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create purchase
export const createPurchase = (body: string | FormData) =>
  axiosInstance.post(`${purchaseURL}`, body);
//update purchase
export const updatePurchase = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${purchaseURL}/${id}`, body);

// get purchase edit details
export const getPurchaseEditDetailsById = (id: number) =>
  axiosInstance.get(`${purchaseURL}/${id}`);
// get purchase edit details
export const getPurchaseEditDetails = (id: number) =>
  axiosInstance.get(`${purchaseURL}/purchase-detail?purchase_main=${id}`);
//get purchase supplier
export const getPurchaseSupplier = ({ rowsPerPage, id }: any) =>
  axiosInstance.get(
    `${purchaseURL}/suppliers?offset=0&limit=${rowsPerPage}&id=${id}`
  );
//get purchase item
export const getPurchaseItem = (rowsPerPage: number) =>
  axiosInstance.get(`${purchaseURL}/product-variants?offset=0&limit=${rowsPerPage}`);
//get purchase payment mode
export const getPurchasePaymentMode = () =>
  axiosInstance.get(`${purchaseURL}/payment-methods?offset=0&limit=0`);
// get purchase organization rule
export const getPurchaseOrganizationRule = () =>
  axiosInstance.get(`${purchaseURL}/organization-rule`);
// get purchase details
export const getPurchaseViewDetails = (data: getApiProps) => {
  const { rowsPerPage, page, id } = data;
  return axiosInstance.get(
    `${purchaseURL}/purchase-detail?purchase_main=${id}&offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}`
  );
};
//additional charge
export const getPurchaseAdditionalCharge = () =>
  axiosInstance.get(`${purchaseURL}/additional-charge-types?offset=0&limit=0`);
//discount scheme
export const getPurchaseDiscountScheme = () =>
  axiosInstance.get(
    `${purchaseURL}/discount-scheme?offset=0&limit=${defaultRowsPerPage}`
  );
