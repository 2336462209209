import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const saleURL = "api/v1/admin/inventory-app/inv-sale/sale";

//GET sale data API
export const getSale = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${saleURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//handle search
export const searchSale = ({ rowsPerPage, search }: searchProps) => {
  return `${saleURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};

//Create Sale
export const createSale = (body: string | FormData) =>
  axiosInstance.post(`${saleURL}`, body);

//GET Sale Customer
export const getSaleCustomer = ({
  defaultRowsPerPage,
  createdCustomerId,
}: any) => {
  return axiosInstance.get(
    `/${saleURL}/customers?offset=0&limit=${defaultRowsPerPage}&id=${
      createdCustomerId ?? ""
    }`
  );
};

//GET Sale order platforms
export const getSaleOrderPlatforms = () => {
  return axiosInstance.get(`/${saleURL}/order-platforms`);
};

//GET Sale delivery platforms
export const getSaleDeliveryPartners = () => {
  return axiosInstance.get(`/${saleURL}/delivery-partners`);
};

export const getSaleItem = (rowsPerPage: number) =>
  axiosInstance.get(
    `/${saleURL}/purchase-details?offset=0&limit=${rowsPerPage}`
  );

//payment mode
export const getSalePaymentMode = () =>
  axiosInstance.get(`/${saleURL}/payment-methods?offset=0&limit=0`);

//view details

export const getSaleDetails = ({ id, rowsPerPage, page }: any) =>
  axiosInstance.get(`${saleURL}/${id}`);

// get sale organization rule
export const getSaleOrganizationRule = () =>
  axiosInstance.get(`${saleURL}/organization-rule`);

//additional charge
export const getSaleAdditionalCharge = () =>
  axiosInstance.get(`${saleURL}/additional-charge-types?offset=0&limit=0`);

// get customer details
export const getPrintCustomerDetails = (id: number) =>
  axiosInstance.get(`${saleURL}/${id}`);
