import { createSlice } from "@reduxjs/toolkit";
import { purchaseReturnInitialState } from "./types";
const initialState: purchaseReturnInitialState = {
  purchaseReturns: [],
  purchaseReturnSuppliers: [],
  purchaseReturnItems: [],
  loadingSpecificPurchase: false,
  loadingCreatePurchaseReturn: false,
  specificPurchases: [],
  specificPurchaseDetails: [],
  loadingPurchaseReturn: false,
  loadingSpecificPurchaseDetails: false,
  loadingPurchaseReturnSupplier: false,
  loadingPurchaseReturnItem: false,
  loadingSelectedPackingType: false,
  packingTypes: [],
  // payment modes
  loadingPurchaseReturnPaymentMode: false,
  purchaseReturnPaymentModes: [],
  // additional charges
  loadingPurchaseReturnAdditionalCharges: false,
  purchaseReturnAdditionalCharges: [],
  // vie details
  loadingPurchaseReturnViewDetails: true,
  purchaseReturnRowData: null,
  purchaseReturnId: "",
  viewPurchaseReturnDetails: [],
  purchaseReturnDetailCount: 0,
  purchaseReturnDetailNext: "",
  purchaseReturnDetailPrevious: "",
  count: 0,
  previous: "",
  next: "",
};
// purchase slice
export const PurchaseReturn = createSlice({
  name: "purchaseReturnReducer",
  initialState,
  reducers: {
    loadingPurchaseReturn: (state) => {
      state.loadingPurchaseReturn = true;
    },
    getPurchaseReturnRequest: (state, payload) => {
      state.loadingPurchaseReturn = true;
    },
    getPurchaseReturnSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingPurchaseReturn = false;
      state.purchaseReturns = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPurchaseReturnFail: (state) => {
      state.loadingPurchaseReturn = false;
    },
    getPurchaseReturnNextRequest: (state, payload) => {
      state.loadingPurchaseReturn = true;
    },
    getPurchaseReturnPreviousRequest: (state, payload) => {
      state.loadingPurchaseReturn = true;
    },

    //view details
    getViewPurchaseReturnDetailsRequest: (state, { payload }) => {
      state.loadingPurchaseReturnViewDetails = true;
      state.purchaseReturnRowData = payload?.rowData;
      state.purchaseReturnId = payload?.id;
    },
    getViewPurchaseReturnDetailsSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingPurchaseReturnViewDetails = false;
      state.viewPurchaseReturnDetails = results;
      state.purchaseReturnDetailCount = count;
      state.purchaseReturnDetailNext = next;
      state.purchaseReturnDetailPrevious = previous;
    },
    getViewPurchaseReturnDetailsFail: (state) => {
      state.loadingPurchaseReturnViewDetails = false;
    },
    getViewPurchaseReturnDetailsNextRequest: (state, payload) => {
      state.loadingPurchaseReturnViewDetails = true;
    },
    getViewPurchaseReturnDetailsPreviousRequest: (state, payload) => {
      state.loadingPurchaseReturnViewDetails = true;
    },
    // get specific request
    loadingSpecificPurchaseReq: (state, payload) => {
      state.loadingSpecificPurchase = true;
    },
    getSpecificPurchaseRequest: (state, payload) => {
      state.loadingSpecificPurchase = true;
    },
    getSpecificPurchaseSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSpecificPurchase = false;
      state.specificPurchases = results;
    },
    getSpecificPurchaseFail: (state) => {
      state.loadingSpecificPurchase = false;
    },

    getPurchaseReturnPaymentModeRequest: (state) => {
      state.loadingPurchaseReturnPaymentMode = true;
    },
    getPurchaseReturnPaymentModeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingPurchaseReturnPaymentMode = false;
      state.purchaseReturnPaymentModes = results;
    },
    getPurchaseReturnPaymentModesFail: (state) => {
      state.loadingSpecificPurchase = false;
    },
    getSpecificPurchaseDetailsRequest: (state, payload) => {
      state.loadingSpecificPurchaseDetails = true;
    },
    getSpecificPurchaseDetailsSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSpecificPurchase = false;
      state.specificPurchaseDetails = results;
    },
    getSpecificPurchaseDetailsFail: (state) => {
      state.loadingSpecificPurchase = false;
    },

    createPurchaseReturnRequest: (state, payload) => {
      state.loadingCreatePurchaseReturn = true;
    },
    createPurchaseReturnSuccess: (state) => {
      state.loadingCreatePurchaseReturn = false;
    },
    createPurchaseReturnFail: (state) => {
      state.loadingCreatePurchaseReturn = false;
    },
    updatePurchaseReturnSuccess: (state) => {
      state.loadingCreatePurchaseReturn = false;
    },
    updatePurchaseReturnFail: (state) => {
      state.loadingCreatePurchaseReturn = false;
    },
    updatePurchaseReturnDetailsSuccess: (state, { payload }) => {
      state.specificPurchaseDetails = payload;
    },
    loadingPurchaseReturnSupplierReq: (state) => {
      state.loadingPurchaseReturnSupplier = true;
    },
    // supplier reducers
    getPurchaseReturnSupplierRequest: (state, payload) => {
      state.loadingPurchaseReturnSupplier = true;
    },
    getPurchaseReturnSuppliersSuccess: (state, { payload }) => {
      state.purchaseReturnSuppliers = payload.results;
      state.loadingPurchaseReturnSupplier = false;
    },
    getPurchaseReturnSuppliersFail: (state) => {
      state.loadingPurchaseReturnSupplier = false;
    },
    getSelectedPackingTypeRequest: (state, payload) => {
      state.loadingSelectedPackingType = true;
    },
    getSelectedPackingTypesSuccess: (state, { payload }) => {
      state.packingTypes = payload;
      state.loadingSelectedPackingType = false;
    },
    getSelectedPackingTypesFail: (state) => {
      state.loadingPurchaseReturnItem = false;
    },
    clearSpecificPurchaseDetails: (state) => {
      state.specificPurchaseDetails = [];
    },
    //additional charge
    getPurchaseReturnAdditionalChargeRequest: (state) => {
      state.loadingPurchaseReturnAdditionalCharges = true;
    },
    getPurchaseReturnAdditionalChargeSuccess: (state, { payload }) => {
      state.purchaseReturnAdditionalCharges = payload.results;
      state.loadingPurchaseReturnAdditionalCharges = false;
    },
    getPurchaseReturnAdditionalChargeFail: (state) => {
      state.loadingPurchaseReturnAdditionalCharges = false;
    },
    // clear data
    clearPurchaseReturnData: (state) => {
      state.loadingPurchaseReturn = false;
      state.specificPurchaseDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingPurchaseReturn,
  getPurchaseReturnSuccess,
  getPurchaseReturnFail,
  getPurchaseReturnRequest,
  clearPurchaseReturnData,
  createPurchaseReturnRequest,
  createPurchaseReturnFail,
  createPurchaseReturnSuccess,
  loadingPurchaseReturnSupplierReq,
  getPurchaseReturnSupplierRequest,
  getPurchaseReturnSuppliersSuccess,
  getPurchaseReturnSuppliersFail,
  updatePurchaseReturnDetailsSuccess,
  getPurchaseReturnNextRequest,
  getPurchaseReturnPreviousRequest,
  // get specific purchases
  getSpecificPurchaseRequest,
  loadingSpecificPurchaseReq,
  getSpecificPurchaseSuccess,
  getSpecificPurchaseFail,
  getSpecificPurchaseDetailsRequest,
  getSpecificPurchaseDetailsSuccess,
  getSpecificPurchaseDetailsFail,
  clearSpecificPurchaseDetails,
  getSelectedPackingTypesSuccess,
  getSelectedPackingTypeRequest,
  getSelectedPackingTypesFail,
  getPurchaseReturnPaymentModeRequest,
  getPurchaseReturnPaymentModeSuccess,
  getPurchaseReturnPaymentModesFail,
  // view details
  getViewPurchaseReturnDetailsRequest,
  getViewPurchaseReturnDetailsSuccess,
  getViewPurchaseReturnDetailsFail,
  getViewPurchaseReturnDetailsNextRequest,
  getViewPurchaseReturnDetailsPreviousRequest,
  // additional charge
  getPurchaseReturnAdditionalChargeRequest,
  getPurchaseReturnAdditionalChargeSuccess,
  getPurchaseReturnAdditionalChargeFail,
} = PurchaseReturn.actions;
export default PurchaseReturn.reducer;
