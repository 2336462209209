import React, { memo, useEffect, useRef } from "react";
import TooltipContent from "../../Tooltip/TooltipContent";
import Spinner from "../../Loader/Spinner";
import { useImmer } from "use-immer";

const AppButton = ({
  className,
  tooltipTitle,
  disabled,
  title,
  loading,
  content,
  color,
  onClick,
  reference,
  id,
  children,
  submit,
  isDelete,
  name,
  style,
  checkTitleToDisplayAddIcon,
  autoFocus,
  spinnerHeight = "30px",
}: any) => {
  const [setEvent, ShowSetEvent] = useImmer(true);

  useEffect(() => {
    if (id === "save" && !loading) {
      const saveBtn = document.getElementById("save");
      const handleSave = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === "s") {
          event.preventDefault(); // prevent default behavior of saving the page
          saveBtn?.click(); // click the button
        }
      };
      document.addEventListener("keydown", handleSave);
      return () => {
        document.removeEventListener("keydown", handleSave);
      };
    }
    if (id === "saveAndPrint" && !loading) {
      const savePrintBtn = document.getElementById("saveAndPrint");
      const handleSaveAndPrint = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === "p") {
          event.preventDefault(); // prev ent default behavior of saving the page
          savePrintBtn?.click(); // click the button
        }
      };
      document.addEventListener("keydown", handleSaveAndPrint);
      return () => {
        document.removeEventListener("keydown", handleSaveAndPrint);
      };
    }
  }, []);

  return (
    <TooltipContent title={tooltipTitle ? tooltipTitle : title ? title : ""}>
      <button
        style={style}
        name={name ?? tooltipTitle}
        type={submit ? "submit" : "button"}
        id={id}
        onClick={onClick}
        disabled={loading || disabled}
        className={
          className
            ? className
            : submit
            ? "submit_button"
            : isDelete
            ? "delete_button"
            : "default_button"
        }
        ref={reference}
        autoFocus={autoFocus}
      >
        <span>
          {loading ? <Spinner color={color} height={spinnerHeight} /> : title}
        </span>
        {children}
      </button>
    </TooltipContent>
  );
};

export default memo(AppButton);
