import { Switch } from "react-router-dom";

import ProtectedRoutes from "./ProtectedRoutes";
import { lazyWithReload } from "./Utils";
import {
  websiteAlbumPermission,
  websiteBannerPermission,
  websiteBlogCategoryPermission,
  websiteBlogPermission,
  websiteBlogTagPermission,
  websiteFeaturedImagePermission,
  websiteFeaturedProductPermission,
  websiteFeaturedVideoPermission,
  websiteGeneralFaqsPermission,
  websiteOurPartnerPermission,
  websiteOurTeamPermission,
  websitePromoCodePermission,
} from "../Component/Layout/Sidebar/Website/WebsitePermissionConstants";

const WebisteSetup = lazyWithReload(() => import("../Pages/WebsiteSetupPages"));

const Album = lazyWithReload(
  () => import("../Pages/WebsiteSetupPages/Album/AlbumPages")
);

const Banner = lazyWithReload(
  () => import("../Pages/WebsiteSetupPages/HomeBanner/HomeBannerPages")
);

const FeaturedImage = lazyWithReload(
  () => import("../Pages/WebsiteSetupPages/FeaturedImages/FeaturedImagePages")
);

const FeaturedProduct = lazyWithReload(
  () =>
    import("../Pages/WebsiteSetupPages/FeaturedProduct/FeaturedProductPages")
);

const FeaturedVideo = lazyWithReload(
  () => import("../Pages/WebsiteSetupPages/FeaturedVideo/FeaturedVIdeoPages")
);

const OurPartner = lazyWithReload(
  () => import("../Pages/WebsiteSetupPages/OurPartner/OurPartnerPages")
);

const GeneralFaqs = lazyWithReload(
  () => import("../Pages/WebsiteSetupPages/GeneralFaqs/GeneralFaqs")
);

const OurTeam = lazyWithReload(
  () => import("../Pages/WebsiteSetupPages/OurTeam/OurTeamPages")
);

//blog
const BlogTag = lazyWithReload(
  () => import("../Pages/WebsiteSetupPages/BlogTag/TagPages")
);

const BlogCategory = lazyWithReload(
  () => import("../Pages/WebsiteSetupPages/BlogCategory/CategoryPages")
);

const Blog = lazyWithReload(
  () => import("../Pages/WebsiteSetupPages/Blog/BlogPages")
);

const PromoCode = lazyWithReload(
  () => import("../Pages/WebsiteSetupPages/PromoCode/PromoCode")
);

const WebsiteSetupRoutes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoutes
          exact
          path="/website-setup"
          component={WebisteSetup}
          permission={[{ mainModule: true }]}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/album"
          component={Album}
          permission={websiteAlbumPermission}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/home-banner-slider"
          component={Banner}
          permission={websiteBannerPermission}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/featured-image"
          component={FeaturedImage}
          permission={websiteFeaturedImagePermission}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/featured-product"
          component={FeaturedProduct}
          permission={websiteFeaturedProductPermission}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/featured-video"
          component={FeaturedVideo}
          permission={websiteFeaturedVideoPermission}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/our-partner"
          component={OurPartner}
          permission={websiteOurPartnerPermission}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/general-faqs"
          component={GeneralFaqs}
          permission={websiteGeneralFaqsPermission}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/our-team"
          component={OurTeam}
          permission={websiteOurTeamPermission}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/blog-tag"
          component={BlogTag}
          permission={websiteBlogTagPermission}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/blog-category"
          component={BlogCategory}
          permission={websiteBlogCategoryPermission}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/blog"
          component={Blog}
          permission={websiteBlogPermission}
        />
        <ProtectedRoutes
          exact
          path="/website-setup/promo-code"
          component={PromoCode}
          permission={websitePromoCodePermission}
        />
      </Switch>
    </>
  );
};

export default WebsiteSetupRoutes;
