import { Grid, TextField } from "@mui/material";
import { ErrorMessage } from "formik";
import React, { memo } from "react";
import { useAppStyles } from "../../../AppUtils/AppStyles/AppStyles";
import { handleFocus } from "../../../AppUtils/FocusElement/focusElement";
import TextError from "../../TextError/TextError";
import "./AppTextField.css";

const AppTextField = ({
  xs,
  autoFocus,
  name,
  value,
  inputRef,
  label,
  focusElement,
  onChange,
  required,
  isNotFormik,
  type,
  InputLabelProps,
  InputProps,
  placeholder,
  error,
  onBlur,
  disabled,
  handleButtonClick,
  id,
  md,
  lg,
  xl,
  className,
  helperText,
  style,
  color,
  focused,
  onKeyUp,
  multiline,
  rows,
  margin,
  fullWidth,
}: any) => {
  const classes = useAppStyles();
  const handleFocusElement = (e: React.KeyboardEvent<HTMLDivElement>) =>
    handleFocus(focusElement, e, required && value);

  return (
    <>
      <Grid
        item
        mr={0.5}
        ml={0.5}
        xs={xs}
        md={md}
        lg={lg}
        xl={xl}
        className="textfield-wrapper"
      >
        <TextField
          disabled={disabled}
          style={style}
          name={name}
          required={required}
          error={error}
          color={color}
          fullWidth={fullWidth}
          type={type}
          focused={focused}
          autoComplete="new-password"
          autoFocus={autoFocus ?? false}
          className={className ? className : classes.fullWidth}
          value={value}
          id={id ?? name}
          label={label}
          variant="outlined"
          inputRef={inputRef}
          onBlur={onBlur}
          InputLabelProps={InputLabelProps}
          InputProps={InputProps}
          helperText={helperText}
          placeholder={placeholder}
          onKeyDown={handleButtonClick ? handleButtonClick : handleFocusElement}
          onKeyUp={onKeyUp}
          onChange={onChange}
          multiline={multiline}
          rows={rows}
          margin={margin}
        />

        {!isNotFormik && <ErrorMessage name={name} component={TextError} />}
      </Grid>
    </>
  );
};

export default memo(AppTextField);
