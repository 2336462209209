import { createSlice, createAction } from "@reduxjs/toolkit";
import {  saleReportInitialState } from "./types";
const initialState: saleReportInitialState = {
  customers: [],
  users:[],
  items:[],
  saleDetails:[],
  //loading 
  loadingGenerateReport: false,
  loadingSaleReportCustomer:false,
  loadingSaleReportItem:false,
  loadingSaleReportUser:false,
  loadingSaleReportDetail:false,
  // loadingSaleReportAllSummary:false,
  // loadingSaleReportAllDetail:false,
  // loadingSaleReportQuickSummary:false,
  // loadingSaleReportQuickDetail:false,
  count: 0,
  previous: "",
  next: "",
};

// export const getSaleReportDetailRequest = createAction(
//   "getSaleReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const SaleReport = createSlice({
  name: "SaleReportReducer",
  initialState,
  reducers: {
    //loading
    loadingSaleReportDetail: (state) => {
      state.loadingSaleReportDetail = true;
    },
     //external reducer request
     getSaleReportDetailRequest:(state,{payload})=>{
     state.loadingSaleReportDetail=true;
     },
     getSaleReportCustomerRequest:(state,payload)=>{
      state.loadingSaleReportCustomer=true;
    },
    getSaleReportItemRequest:(state)=>{
      state.loadingSaleReportItem=true;
    },
    getSaleReportUserRequest:(state)=>{
      state.loadingSaleReportUser=true;
    },
    getSaleReportAllSummaryRequest:(state,{payload})=>{
        state.loadingGenerateReport=true;
    },
    getSaleReportAllDetailRequest:(state,{payload})=>{
      state.loadingGenerateReport=true;
    },
    getSaleReportQuickSummaryRequest:(state,{payload})=>{
      state.loadingGenerateReport=true;
    },
    getSaleReportQuickDetailRequest:(state,{payload})=>{
    state.loadingGenerateReport=true;
    },
    getSaleReportNextRequest: (state, payload) => {
      state.loadingSaleReportDetail = true;
    },
    getSaleReportPreviousRequest: (state, payload) => {
      state.loadingSaleReportDetail = true;
    },
    loadingSaleReportCustomerSearch: (state) => {
      state.loadingSaleReportCustomer = true;
    },
    
    //external get reducer  
    getSaleReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.saleDetails = results;
      state.loadingSaleReportDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSaleReportDetailFail: (state) => {
      state.loadingSaleReportDetail = false;
    },
    getSaleReportUserSuccess: (state,{ payload: { results } }) => {
      state.loadingSaleReportUser = false;
      state.users = results;
    },
    getSaleReportUserFail: (state) => {
      state.loadingSaleReportUser = false;
    },
    getSaleReportCustomerSuccess: (state,{ payload: { results } }) => {
      state.loadingSaleReportCustomer = false;
      state.customers = results;
    },
    getSaleReportCustomerFail: (state) => {
      state.loadingSaleReportCustomer = false;
    },
    getSaleReportItemSuccess: (state,{ payload: { results } }) => {
      state.loadingSaleReportItem = false;
      state.items = results;
    },
    getSaleReportItemFail: (state) => {
      state.loadingSaleReportItem = false;
    },
    getSaleReportAllSummarySuccess: (state,{ payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
    },
    getSaleReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getSaleReportQuickSummarySuccess:  (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSaleReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getSaleReportAllDetailSuccess: (state,{ payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
    },
    getSaleReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getSaleReportQuickDetailSuccess:  (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.saleDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSaleReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearSaleReportData: (state) => {
      state.customers = [];
      state.users = [];
      state.items = [];
    },
    clearPrintDetails:(state)=>{
      state.saleDetails=[];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getSaleReportDetailRequest,
  getSaleReportCustomerRequest,
  getSaleReportItemRequest,
  getSaleReportUserRequest,
  getSaleReportAllSummaryRequest,
  getSaleReportAllDetailRequest,
  getSaleReportQuickSummaryRequest,
  getSaleReportQuickDetailRequest,
  getSaleReportPreviousRequest,
  getSaleReportNextRequest,
  loadingSaleReportCustomerSearch,
  //reducer get data
  getSaleReportDetailSuccess,
  getSaleReportDetailFail,
  getSaleReportItemSuccess,
  getSaleReportItemFail,
  getSaleReportCustomerSuccess,
  getSaleReportCustomerFail,
  getSaleReportUserSuccess,
  getSaleReportUserFail,
  getSaleReportQuickDetailSuccess,
  getSaleReportQuickDetailFail,
  getSaleReportAllDetailSuccess,
  getSaleReportAllDetailFail,
  getSaleReportQuickSummarySuccess,
  getSaleReportQuickSummaryFail,
  getSaleReportAllSummarySuccess,
  getSaleReportAllSummaryFail,
  //loading
  loadingSaleReportDetail,
  //clear
  clearSaleReportData,
  clearPrintDetails,
} = SaleReport.actions;
export default SaleReport.reducer;
