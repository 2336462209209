import {
  createCustomerEditFail,
  createCustomerEditRequest,
  createCustomerEditSuccess,
  getCustomerEditFail,
  getCustomerEditSuccess,
  updateCustomerEditFail,
  updateCustomerEditSuccess,
  getCustomerEditRequest,
  getCustomerEditNextRequest,
  getCustomerEditPreviousRequest,
  updateCustomerEditRequest,
  clearCustomerEditData,
  getCustomerEditByIdRequest,
  customerEditEditSuccess,
  customerEditEditFail,
  //external reducer request
  getCustomerEditDepartmentRequest,
  getCustomerEditDoctorRequest,
  getCustomerEditProvinceRequest,
  getCustomerEditDistrictRequest,
  getCustomerEditTitleRequest,
  getCustomerEditCustomerTypeRequest,
  //external reducer get data
  getCustomerEditTitleSuccess,
  getCustomerEditTitleFail,
  getCustomerEditDoctorSuccess,
  getCustomerEditDoctorFail,
  getCustomerEditDepartmentSuccess,
  getCustomerEditDepartmentFail,
  getCustomerEditProvinceSuccess,
  getCustomerEditProvinceFail,
  getCustomerEditDistrictSuccess,
  getCustomerEditDistrictFail,
  getCustomerEditCustomerTypeSuccess,
  getCustomerEditCustomerTypeFail,
  getCustomerEditPalikaRequest,
  getCustomerEditPalikaSuccess,
  getCustomerEditPalikaFail,
  getCustomerEditWardRequest,
  getCustomerEditWardSuccess,
  getCustomerEditWardFail,
  getCustomerEditJaatiTypeRequest,
  getCustomerEditJaatiTypeSuccess,
  getCustomerEditJaatiTypeFail,
  getCustomerEditDiscountSchemeRequest,
  getCustomerEditDiscountSchemeSuccess,
  getCustomerEditDiscountSchemeFail,
  getCustomerEditServiceTypeSuccess,
  getCustomerEditServiceTypeFail,
  getCustomerEditServiceTypeRequest,
  getCustomerEditDepartmentInvestigationDetailRequest,
  getCustomerEditDepartmentInvestigationDetailSuccess,
  getCustomerEditDepartmentInvestigationDetailFail,
  //print details
  getPrintCustomerEditCustomerDetailsRequest,
  getPrintCustomerEditCustomerDetailsSuccess,
  getPrintCustomerEditCustomerDetailsFail,
  getCustomerEditPrintCountRequest,
  getCustomerEditPrintCountSuccess,
  getCustomerEditPrintCountFail,
  customerEditPrintCountRequest,
  customerEditPrintCountSuccess,
  customerEditPrintCountFail,
  getCustomerEditCountryRequest,
  getCustomerEditCountrySuccess,
  getCustomerEditCountryFail,
  getCustomerEditOccupationRequest,
  getCustomerEditOccupationSuccess,
  getCustomerEditOccupationFail,
} from "./customerEditSlice";
import { mergeMap } from "rxjs";
import {
  createCustomerEdit,
  getCustomerEdit,
  getCustomerEditById,
  getCustomerEditTitle,
  updateCustomerEdit,
} from "./api";
import * as API from "./api";

import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get CustomerEdit
const getCustomerEditEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCustomerEdit(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditSuccess(action?.payload)
        : getCustomerEditFail()
    )
  );

//get CustomerEdit by id
const getCustomerEditByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCustomerEditById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? customerEditEditSuccess(action?.payload)
        : customerEditEditFail()
    )
  );

//get next
const getCustomerEditNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditSuccess(action?.payload)
        : getCustomerEditFail()
    )
  );

//get previous
const getCustomerEditPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditSuccess(action?.payload)
        : getCustomerEditFail()
    )
  );

//create CustomerEdit epic
const createCustomerEditEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createCustomerEditRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, saveAndPrint } }) => {
        try {
          // const body = new FormData();
          // for (let [key, value] of Object.entries(values)) {
          //   // @ts-ignore
          //   body.append(`${key}`, value);
          // }
          const body = JSON.stringify(values);
          const response = await createCustomerEdit(body);
          if (response) {
            dispatch(getCustomerEditRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            dispatch(clearCustomerEditData());
            !saveAndPrint && dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createCustomerEditSuccess(action?.payload)
        : createCustomerEditFail();
    })
  );

//update CustomerEdit epic
const updateCustomerEditEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateCustomerEditRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      // alert(id);
      try {
        let body = JSON.stringify(values);
        // for (let [key, value] of Object.entries(values)) {
        //   // @ts-ignore
        //   body.append(`${key}`, value);
        // }
        const response = await updateCustomerEdit(body, id);
        if (response) {
          dispatch(getCustomerEditRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearCustomerEditData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateCustomerEditSuccess() : updateCustomerEditFail()
    )
  );

//get customerEdit get data data

const getCustomerEditDepartmentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditDepartmentRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditDepartment();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditDepartmentSuccess(action?.payload)
        : getCustomerEditDepartmentFail()
    )
  );

const getCustomerEditDoctorEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditDoctorRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditDoctor();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditDoctorSuccess(action?.payload)
        : getCustomerEditDoctorFail()
    )
  );

const getCustomerEditWardEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditWardRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditWard(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditWardSuccess(action?.payload)
        : getCustomerEditWardFail()
    )
  );

const getCustomerEditJaatiTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditJaatiTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditJaatiType(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditJaatiTypeSuccess(action?.payload)
        : getCustomerEditJaatiTypeFail()
    )
  );
const getCustomerEditDiscountSchemeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditDiscountSchemeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditDiscountScheme(
          action?.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditDiscountSchemeSuccess(action?.payload)
        : getCustomerEditDiscountSchemeFail()
    )
  );
const getCustomerEditDistrictEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditDistrictRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditDistrict();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditDistrictSuccess(action?.payload)
        : getCustomerEditDistrictFail()
    )
  );
const getCustomerEditProvinceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditProvinceRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditProvince();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditProvinceSuccess(action?.payload)
        : getCustomerEditProvinceFail()
    )
  );
const getCustomerEditCustomerTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditCustomerTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditCustomerType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditCustomerTypeSuccess(action?.payload)
        : getCustomerEditCustomerTypeFail()
    )
  );
const getCustomerEditTitleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditTitleRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditTitle();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditTitleSuccess(action?.payload)
        : getCustomerEditTitleFail()
    )
  );

const getCustomerEditPalikaEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditPalikaRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditPalika(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditPalikaSuccess(action?.payload)
        : getCustomerEditPalikaFail()
    )
  );

const getCustomerEditDepartmentInvestigationDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditDepartmentInvestigationDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditDepartmentInvestigationDetail(
          action?.payload
        );
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditDepartmentInvestigationDetailSuccess(action?.payload)
        : getCustomerEditDepartmentInvestigationDetailFail()
    )
  );

const getCustomerEditServiceTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditServiceTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditServiceType(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditServiceTypeSuccess(action?.payload)
        : getCustomerEditServiceTypeFail()
    )
  );
const getPrintCustomerEditCustomerDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPrintCustomerEditCustomerDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getPrintCustomerDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPrintCustomerEditCustomerDetailsSuccess(action?.payload)
        : getPrintCustomerEditCustomerDetailsFail()
    )
  );

const getCustomerEditPrintCountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditPrintCountRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditPrintCount(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditPrintCountSuccess(action?.payload)
        : getCustomerEditPrintCountFail()
    )
  );

//post count
const customerEditInvoicePrintCountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(customerEditPrintCountRequest.match),
    mergeMap(async (action) => {
      try {
        const body = JSON.stringify(action.payload);
        const response = await API.printCount(body);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? customerEditPrintCountSuccess(action?.payload)
        : customerEditPrintCountFail()
    )
  );

const getCustomerEditCountryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditCountryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditCountry();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditCountrySuccess(action?.payload)
        : getCustomerEditCountryFail()
    )
  );

const getCustomerEditOccupationEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerEditOccupationRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getCustomerEditOccupation();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerEditOccupationSuccess(action?.payload)
        : getCustomerEditOccupationFail()
    )
  );

export const customerEditEpics = combineEpics(
  getCustomerEditEpic,
  createCustomerEditEpic,
  updateCustomerEditEpic,
  getCustomerEditNext,
  getCustomerEditPrevious,
  getCustomerEditByIdEpic,
  //extranal request
  getCustomerEditTitleEpic,
  getCustomerEditCustomerTypeEpic,
  getCustomerEditDepartmentEpic,
  getCustomerEditDoctorEpic,
  getCustomerEditProvinceEpic,
  getCustomerEditDistrictEpic,
  getCustomerEditWardEpic,
  getCustomerEditJaatiTypeEpic,
  getCustomerEditDiscountSchemeEpic,
  getCustomerEditPalikaEpic,
  getCustomerEditServiceTypeEpic,
  getCustomerEditDepartmentInvestigationDetailEpic,
  getCustomerEditPrintCountEpic,
  getPrintCustomerEditCustomerDetails,
  customerEditInvoicePrintCountEpic,
  getCustomerEditOccupationEpic,
  getCustomerEditCountryEpic
);
