import { createSlice } from "@reduxjs/toolkit";
import { getGlobalOrganizationRuleSuccess } from "../../../CommonAppRedux/CommonAppSlice";
const initialState: any = {
  organizationRules: [],
  loadingOrganizationRule: false,
  loadingCreateOrganizationRule: false,
  edit: false,
  organizationRule: null,
  loadingTaxGroup: false,
  taxGroups: [],
  count: 0,
  previous: "",
  next: "",
};
export const OrganizationRule = createSlice({
  name: "OrganizationRule Reducer",
  initialState,
  reducers: {
    getOrganizationRuleRequest: (state, payload) => {
      state.loadingOrganizationRule = true;
    },
    getOrganizationRuleSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingOrganizationRule = false;
      state.organizationRules = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getOrganizationRuleFail: (state) => {
      state.loadingOrganizationRule = false;
    },
    getOrganizationRuleNextRequest: (state, payload) => {
      state.loadingOrganizationRule = true;
    },
    getOrganizationRulePreviousRequest: (state, payload) => {
      state.loadingOrganizationRule = true;
    },
    loadingTaxGroupReq: (state) => {
      state.loadingTaxGroup = true;
    },
    getTaxGroupRequest: (state, payload) => {
      state.loadingTaxGroup = true;
    },
    getTaxGroupSuccess: (state, { payload: { results } }) => {
      state.loadingTaxGroup = false;
      state.taxGroups = results;
    },
    getTaxGroupFail: (state) => {
      state.loadingTaxGroup = false;
    },
    createOrganizationRuleRequest: (state, payload) => {
      state.loadingCreateOrganizationRule = true;
      state.closeModal = true;
    },
    createOrganizationRuleSuccess: (state) => {
      state.loadingCreateOrganizationRule = false;
    },
    createOrganizationRuleFail: (state) => {
      state.loadingCreateOrganizationRule = false;
    },
    getOrganizationRuleByIdRequest: (state, payload) => {
      state.edit = true;
    },
    organizationRuleEditSuccess: (state, { payload }) => {
      state.organizationRule = payload;
    },
    organizationRuleEditFail: (state) => {
      state.edit = false;
    },
    updateOrganizationRuleRequest: (state, payload) => {
      state.loadingCreateOrganizationRule = true;
    },
    updateOrganizationRuleSuccess: (state) => {
      state.loadingCreateOrganizationRule = false;
    },
    updateOrganizationRuleFail: (state) => {
      state.loadingCreateOrganizationRule = false;
    },
    clearOrganizationRuleData: (state) => {
      state.edit = false;
      state.organizationRule = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingTaxGroupReq,
  getOrganizationRuleRequest,
  getOrganizationRuleSuccess,
  getOrganizationRuleFail,
  getOrganizationRuleNextRequest,
  getOrganizationRulePreviousRequest,
  getTaxGroupRequest,
  getTaxGroupSuccess,
  getTaxGroupFail,
  createOrganizationRuleRequest,
  createOrganizationRuleSuccess,
  createOrganizationRuleFail,
  organizationRuleEditSuccess,
  updateOrganizationRuleRequest,
  updateOrganizationRuleSuccess,
  updateOrganizationRuleFail,
  clearOrganizationRuleData,
  getOrganizationRuleByIdRequest,
  organizationRuleEditFail,
} = OrganizationRule.actions;
export default OrganizationRule.reducer;
