import { createSlice } from "@reduxjs/toolkit";
import { generalFaqsInitialState } from "./types";

const initialState: generalFaqsInitialState = {
  generalFaqss: [],
  edit: false,
  generalFaqs: null,
  loadingGeneralFaqs: false,
  loadingCreateGeneralFaqs: false,
  count: 0,
  previous: "",
  next: "",
};

export const GeneralFaqs = createSlice({
  name: "generalFaqsReducer",
  initialState,
  reducers: {
    getGeneralFaqsRequest: (state, payload) => {
      state.loadingGeneralFaqs = true;
    },
    getGeneralFaqsSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGeneralFaqs = false;
      state.generalFaqss = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getGeneralFaqsFail: (state) => {
      state.loadingGeneralFaqs = false;
    },

    getGeneralFaqsNextRequest: (state, payload) => {
      state.loadingGeneralFaqs = true;
    },
    getGeneralFaqsPreviousRequest: (state, payload) => {
      state.loadingGeneralFaqs = true;
    },
    createGeneralFaqsRequest: (state, payload) => {
      state.loadingCreateGeneralFaqs = true;
    },
    updateGeneralFaqsRequest: (state, payload) => {
      state.loadingCreateGeneralFaqs = true;
    },
    createGeneralFaqsSuccess: (state) => {
      state.loadingCreateGeneralFaqs = false;
    },
    createGeneralFaqsFail: (state) => {
      state.loadingCreateGeneralFaqs = false;
    },
    getGeneralFaqsByIdRequest: (state, payload) => {
      state.edit = true;
    },
    generalFaqsEditSuccess: (state, { payload }) => {
      state.generalFaqs = payload;
    },
    generalFaqsEditFail: (state) => {
      state.edit = false;
    },
    updateGeneralFaqsSuccess: (state) => {
      state.loadingCreateGeneralFaqs = false;
    },
    updateGeneralFaqsFail: (state) => {
      state.loadingCreateGeneralFaqs = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.generalFaqs = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getGeneralFaqsRequest,
  getGeneralFaqsFail,
  getGeneralFaqsSuccess,
  getGeneralFaqsNextRequest,
  getGeneralFaqsPreviousRequest,
  createGeneralFaqsFail,
  createGeneralFaqsRequest,
  createGeneralFaqsSuccess,
  generalFaqsEditSuccess,
  updateGeneralFaqsFail,
  updateGeneralFaqsSuccess,
  updateGeneralFaqsRequest,
  clearAllData,
  getGeneralFaqsByIdRequest,
  generalFaqsEditFail,
} = GeneralFaqs.actions;

export default GeneralFaqs.reducer;
