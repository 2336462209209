import { createAction, createSlice } from "@reduxjs/toolkit";
import { userInitialState } from "./types";

const initialState: userInitialState = {
  users: [],
  loadingUser: false,
  loadingUserGroup: false,
  loadingCreateUser: false,
  loadingUpdatePhoto: false,
  loadingUpdateUserRole: false,
  edit: false,
  user: null,
  count: 0,
  previous: "",
  next: "",
  loginUser: null,
  groups: [],
  permissions: [],
  isSuperuser: false,
};
// export const loginUserRequest = createAction(
//   "loginUserRequest",
//   function prepare() {
//     return {};
//   }
// );
export const User = createSlice({
  name: "UserReducer",
  initialState,
  reducers: {
    getUserRequest: (state, payload) => {
      state.loadingUser = true;
    },
    getUserSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingUser = false;
      state.users = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
      state.loadingUpdateUserRole = false;
    },
    getUserFail: (state) => {
      state.loadingUser = false;
    },
    getUserNextRequest: (state, payload) => {
      state.loadingUser = true;
    },
    getUserPreviousRequest: (state, payload) => {
      state.loadingUser = true;
    },
    getUserGroupRequest: (state, payload) => {
      state.loadingUserGroup = true;
    },
    loadingUserGroupRequest: (state) => {
      state.loadingUserGroup = true;
    },
    getUserByIdRequest: (state, payload) => {
      state.edit = true;
    },
    getUserGroupSuccess: (state, { payload: { results } }) => {
      state.groups = results;
      state.loadingUserGroup = false;
    },
    getUserGroupFail: (state) => {
      state.loadingUserGroup = false;
    },
    createUserRequest: (state, payload) => {
      state.loadingCreateUser = true;
      // state.closeModal = true;
    },
    updateUserRequest: (state, payload) => {
      state.loadingCreateUser = true;
    },
    createUserSuccess: (state) => {
      state.loadingCreateUser = false;
    },
    createUserFail: (state) => {
      state.loadingCreateUser = false;
    },
    userEditSuccess: (state, { payload }) => {
      state.user = payload;
    },
    userEditFail: (state) => {
      state.edit = false;
    },
    updateUserSuccess: (state) => {
      state.loadingCreateUser = false;
      state.loadingUpdateUserRole = true;
      state.loadingUpdatePhoto = false;
    },
    updateUserFail: (state) => {
      state.loadingCreateUser = false;
      state.loadingUpdateUserRole = false;
      state.loadingUpdatePhoto = false;
    },
    clearUserData: (state) => {
      state.edit = false;
    },
    loginUserSuccess: (state, { payload }) => {
      state.loginUser = payload;
      state.isSuperuser = payload?.isSuperuser;
      state.permissions = payload?.userPermissions;
    },
    loginUserFail: (state) => {
      state.loginUser = null;
    },
    loginUserRequest: (state) => {
      state.loadingUser = true;
    },
    updateProfileUserRequest: (state, payload) => {
      state.loadingCreateUser = true;
    },
    updateProfilePhotoRequest: (state, paylad) => {
      state.loadingUpdatePhoto = true;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getUserRequest,
  getUserSuccess,
  getUserFail,
  getUserPreviousRequest,
  getUserNextRequest,
  createUserRequest,
  createUserSuccess,
  createUserFail,
  userEditSuccess,
  updateUserSuccess,
  updateUserFail,
  clearUserData,
  loginUserFail,
  loginUserSuccess,
  // user group
  getUserGroupRequest,
  loadingUserGroupRequest,
  getUserGroupSuccess,
  getUserGroupFail,
  updateUserRequest,
  getUserByIdRequest,
  userEditFail,
  loginUserRequest,
  updateProfileUserRequest,
  //
  updateProfilePhotoRequest,
} = User.actions;
export default User.reducer;
