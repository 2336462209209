import { useEffect } from "react";
import { useAppSelector } from "../../AppUtils/Utils/appHooks";
import gravLogo from "../../../src/Assets/gravlogo.png";
import { commonAppSelector } from "../../Pages/CommonAppRedux/selector";
const Favicon = () => {
  const { globalOrganizations: organizations } =
    useAppSelector(commonAppSelector);
  useEffect(() => {
    const favicon = organizations?.favicon ? organizations?.favicon : gravLogo;
    const fav = document.getElementById("favicon");
    if (fav) {
      fav.href = favicon ? favicon : "";
    } else {
      fav.href = gravLogo;
    }
  }, [organizations]);
  //change the title
  document.title = organizations?.nameDisplay
    ? organizations?.nameDisplay
    : "MIS";
  return null;
};
export default Favicon;
