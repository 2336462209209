import {
  getCustomerOrderRequest,
  getCustomerOrderSuccess,
  getCustomerOrderFail,
  getCustomerOrderNextRequest,
  getCustomerOrderPreviousRequest,
  clearCustomerOrderData,
  // Order Status Update
  updateOrderStatusRequest,
  updateOrderStatusSuccess,
  updateOrderStatusFail,
  // Order Details
  getOrderDetailsRequest,
  getOrderDetailsFail,
  getOrderDetailsSuccess,
  // make bill
  createMakeBillRequest,
  createMakeBillFail,
  createMakeBillSuccess,
  // Get Delivery Partners
  getDeliveryPartnersRequest,
  getDeliveryPartnersFail,
  getDeliveryPartnersSuccess,
  //
  getOrderCountRequest,
  getOrderCountSuccess,
  getOrderCountFail,
  //
  getLastSaleRequest,
  getLastSaleFail,
  getLastSaleSuccess,
} from "./orderSlice";
import {
  getCustomerOrder,
  updateOrderStatus,
  createMakeBill,
  getOrderDetails,
  getDeliveryPatners,
  getOrderCounts,
  getLatestSale,
} from "./api";
import { mergeMap } from "rxjs";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";

export const controller = new AbortController();

// Get Customer Orders
const getCustomerOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerOrderRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCustomerOrder(action?.payload);
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerOrderSuccess(action?.payload)
        : getCustomerOrderFail()
    )
  );

// Get Delivery Partners
const getDeliveryPatnersEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeliveryPartnersRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getDeliveryPatners();
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeliveryPartnersSuccess(action?.payload)
        : getDeliveryPartnersFail()
    )
  );

// Get Order Counts
const getOrderCountEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrderCountRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getOrderCounts();
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrderCountSuccess(action?.payload)
        : getOrderCountFail()
    )
  );

// Get Last Sale
const getLastSaleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getLastSaleRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getLatestSale();
        console.log(response, "resp");
        return { payload: response?.data?.results[0] };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getLastSaleSuccess(action?.payload) : getLastSaleFail()
    )
  );

// GET Next Order
const getCustomerOrderNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerOrderNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action?.payload);
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerOrderSuccess(action?.payload)
        : getCustomerOrderFail()
    )
  );

//GET Previous Order
const getCustomerOrderPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerOrderPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action?.payload);
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerOrderSuccess(action?.payload)
        : getCustomerOrderFail()
    )
  );

// GET Order Details
const getOrderDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrderDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getOrderDetails(action?.payload);
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrderDetailsSuccess(action?.payload)
        : getOrderDetailsFail()
    )
  );

// Update Order Status
const updateOrderStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateOrderStatusRequest.match),
    mergeMap(
      async ({
        payload: { values, id, rowsPerPage, page, setShowUpdateStatus },
      }) => {
        try {
          const body = JSON.stringify(values);
          const response = await updateOrderStatus(body, id);
          if (response) {
            dispatch(getCustomerOrderRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages?.updateMessage));
            dispatch(clearCustomerOrderData());
            dispatch(closeModal());
            setShowUpdateStatus?.(false);
          }
          return { payload: { response, rowsPerPage } };
        } catch (e) {
          dispatch(alertErrorAction(messages?.updateFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload ? updateOrderStatusSuccess() : updateOrderStatusFail()
    )
  );

// make order bill
const createMakeBillEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createMakeBillRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, setShowSaleModal } }) => {
        try {
          const body = JSON.stringify(values);
          const response = await createMakeBill(body);
          if (response) {
            dispatch(getCustomerOrderRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages?.createMessage));
            dispatch(clearCustomerOrderData());
            dispatch(closeModal());
            setShowSaleModal?.(false);
          }
          return { payload: { response, rowsPerPage } };
        } catch (e: any) {
          dispatch(
            alertErrorAction(
              e?.response?.data?.orderNo?.error ?? messages?.createFailMessage
            )
          );
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload ? createMakeBillSuccess() : createMakeBillFail()
    )
  );

export const orderEpics = combineEpics(
  getCustomerOrderEpic,
  getCustomerOrderNext,
  getCustomerOrderPrevious,
  updateOrderStatusEpic,
  getOrderDetailsEpic,
  createMakeBillEpic,
  getDeliveryPatnersEpic,
  getOrderCountEpic,
  getLastSaleEpic
);
