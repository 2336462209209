import {
  getSaleReturnFail,
  getSaleReturnSuccess,
  loadingSaleReturn,
  getSaleReturnRequest,
  getSaleReturnCustomerRequest,
  getSaleReturnCustomerSuccess,
  getSaleReturnCustomerFail,
  createSaleReturnRequest,
  createSaleReturnSuccess,
  createSaleReturnFail,
  getSpecificSaleRequest,
  getSpecificSaleSuccess,
  getSpecificSaleFail,
  getAllSalesRequest,
  getAllSalesSuccess,
  getAllSalesFail,
  getSpecificSaleDetailsRequest,
  getSpecificSaleDetailsSuccess,
  getSpecificSaleDetailsFail,
  getSaleReturnPaymentModeSuccess,
  getSaleReturnPaymentModesFail,
  getSaleReturnPaymentModeRequest,
  getSaleReturnAdditionalChargeSuccess,
  getSaleReturnAdditionalChargeFail,
  getSaleReturnAdditionalChargeRequest,
  getViewSaleReturnDetailsRequest,
  getViewSaleReturnDetailsSuccess,
  getViewSaleReturnDetailsFail,
  getSaleReturnDetailsRequest,
  getSaleReturnDetailsFail,
  getSaleReturnDetailsSuccess,
  clearSaleReturnData,
} from "./saleReturnSlice";
import { mergeMap } from "rxjs";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import * as API from "./api";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

//get sale return epic
const getSaleReturnEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleReturnRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSaleReturn());
      try {
        const response = await API.getSaleReturn(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleReturnSuccess(action?.payload)
        : getSaleReturnFail()
    )
  );

//create sale epic
const createSaleReturnEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createSaleReturnRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, saveAndPrint } }) => {
        try {
          const body = JSON.stringify(values);
          const response = await API.createSaleReturn(body);
          if (response) {
            dispatch(getSaleReturnRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            dispatch(clearSaleReturnData());
            !saveAndPrint && dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createSaleReturnSuccess(action?.payload)
        : createSaleReturnFail();
    })
  );

//get sale customer  epic
const getSaleReturnCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleReturnCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleReturnCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleReturnCustomerSuccess(action?.payload)
        : getSaleReturnCustomerFail()
    )
  );

//get sale return additional charge
const getSaleReturnAdditionalChargeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleReturnAdditionalChargeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleReturnAdditionalCharge();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleReturnAdditionalChargeSuccess(action?.payload)
        : getSaleReturnAdditionalChargeFail()
    )
  );

//get specific sale according to the supplier iid
const getSpecificSaleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSpecificSaleRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSpecificSales(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSpecificSaleSuccess(action?.payload)
        : getSpecificSaleFail()
    )
  );

//get all sale according to the supplier iid
const getAllSalesEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getAllSalesRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getAllSales();
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getAllSalesSuccess(action?.payload) : getAllSalesFail()
    )
  );

//get sale details
const getSaleDetailsEpic = (action$: Observable<Action>, _: stateAction) =>
  action$.pipe(
    filter(getSpecificSaleDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSpecificSaleDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSpecificSaleDetailsSuccess(action?.payload)
        : getSpecificSaleDetailsFail()
    )
  );

//get sale details
const getSaleReturnPaymentModeEpic = (
  action$: Observable<Action>,
  _: stateAction
) =>
  action$.pipe(
    filter(getSaleReturnPaymentModeRequest.match),
    mergeMap(async () => {
      try {
        const response = await API.getSaleReturnPaymentModes();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleReturnPaymentModeSuccess(action?.payload)
        : getSaleReturnPaymentModesFail()
    )
  );

// sale return view details
const getViewSaleReturnDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewSaleReturnDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleReturnViewDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewSaleReturnDetailsSuccess(action?.payload)
        : getViewSaleReturnDetailsFail()
    )
  );

// get sale return details
const getSaleReturnDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleReturnDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleReturnDetails(action?.payload?.id);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleReturnDetailsSuccess(action?.payload)
        : getSaleReturnDetailsFail()
    )
  );

export const saleReturnEpics = combineEpics(
  getSaleReturnEpic,
  getSaleReturnCustomerEpic,
  getSaleReturnAdditionalChargeEpic,
  createSaleReturnEpic,
  getSpecificSaleEpic,
  getSaleDetailsEpic,
  getSaleReturnPaymentModeEpic,
  getViewSaleReturnDetailsEpic,
  getSaleReturnDetailsEpic,
  getAllSalesEpic
);
