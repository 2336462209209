import { createSlice } from "@reduxjs/toolkit";
import { partyPaymentInitialState } from "./types";

const initialState: partyPaymentInitialState = {
  partyPayment: null,
  partyPayments: [],
  loadingPartyPayment: false,
  loadingCreatePartyPayment: false,
  loadingSupplier: false,
  loadingPartyPaymentDetails: false,
  edit: false,
  count: 0,
  next: "",
  previous: "",
  suppliers: [],
  loadingPartyPaymentMode: false,
  partyPaymentModes: [],
};

export const PartyPayment = createSlice({
  name: "PartyPaymentReducer",
  initialState,
  reducers: {
    loadingPartyPayment: (state) => {
      state.loadingPartyPayment = true;
    },
    getPartyPaymentRequest: (state, payload) => {
      state.loadingPartyPayment = true;
    },
    getPartyPaymentSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingPartyPayment = false;
      state.partyPayments = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPartyPaymentFail: (state) => {
      state.loadingPartyPayment = false;
    },
    //
    loadingPartyPaymentSupplierRequest: (state) => {
      state.loadingSupplier = true;
    },
    getPartyPaymentSupplierRequest: (state) => {
      state.loadingSupplier = true;
    },
    getPartyPaymentSupplierSuccess: (state, { payload: { results } }) => {
      state.loadingSupplier = false;
      state.suppliers = results;
    },
    getPartyPaymentSupplierFail: (state) => {
      state.loadingSupplier = false;
    },
    //
    getPartyPaymentNextRequest: (state, payload) => {
      state.loadingPartyPayment = true;
    },
    getPartyPaymentPreviousRequest: (state, payload) => {
      state.loadingPartyPayment = true;
    },
    //
    createPartyPaymentRequest: (state, payload) => {
      state.loadingCreatePartyPayment = true;
    },
    createPartyPaymentSuccess: (state) => {
      state.loadingCreatePartyPayment = false;
    },
    createPartyPaymentFail: (state) => {
      state.loadingCreatePartyPayment = false;
    },
    //
    getPartyPaymentByIdRequest: (state, payload) => {
      state.edit = true;
    },
    partyPaymentEditSuccess: (state, { payload }) => {
      state.partyPayment = payload;
    },
    partyPaymentEditFail: (state) => {
      state.edit = false;
    },
    //
    updatePartyPaymentRequest: (state, payload) => {
      state.loadingCreatePartyPayment = true;
    },
    updatePartyPaymentSuccess: (state) => {
      state.loadingCreatePartyPayment = false;
    },
    updatePartyPaymentFail: (state) => {
      state.loadingCreatePartyPayment = false;
    },
    //payment mode
    getPartyPaymentModeRequest: (state) => {
      state.loadingPartyPaymentMode = true;
    },
    getPartyPaymentModeSuccess: (state, { payload }) => {
      state.loadingPartyPaymentMode = false;
      state.partyPaymentModes = payload.results;
    },
    getPartyPaymentModeFail: (state) => {
      state.loadingPartyPaymentMode = false;
    },
    //
    clearPartyPaymentData: (state) => {
      state.edit = false;
      state.partyPayment = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingPartyPayment,
  getPartyPaymentRequest,
  getPartyPaymentSuccess,
  getPartyPaymentFail,
  //
  getPartyPaymentNextRequest,
  getPartyPaymentPreviousRequest,
  //
  createPartyPaymentRequest,
  createPartyPaymentSuccess,
  createPartyPaymentFail,
  //
  getPartyPaymentByIdRequest,
  partyPaymentEditSuccess,
  partyPaymentEditFail,
  //
  updatePartyPaymentRequest,
  updatePartyPaymentSuccess,
  updatePartyPaymentFail,
  //
  clearPartyPaymentData,
  //
  loadingPartyPaymentSupplierRequest,
  getPartyPaymentSupplierRequest,
  getPartyPaymentSupplierSuccess,
  getPartyPaymentSupplierFail,
  //payment mode
  getPartyPaymentModeRequest,
  getPartyPaymentModeSuccess,
  getPartyPaymentModeFail,
} = PartyPayment.actions;
export default PartyPayment.reducer;
