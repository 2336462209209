import { createSlice } from "@reduxjs/toolkit";
import { saleInitialState } from "../types";

const initialState: saleInitialState = {
  count: 0,
  previous: "",
  next: "",
  loadingSale: false,
  sales: [],
  //
  loadingCreateSale: false,
  createdSalesId: "",
  loadingSaleCustomer: false,
  saleCustomers: [],
  loadingOrderPlatForm: false,
  orderPlatForms: [],
  loadingDeliveryPartners: false,
  deliveryPartners: [],
  //
  loadingSaleItem: false,
  loadingSearchData: false,
  loadingSalePaymentMode: false,
  saleDetails: [],
  saleItems: [],
  saleOrganizationRules: null,
  //payment details sale
  salePaymentModes: [],
  //view details sale
  viewSaleDetails: [],
  loadingViewDetails: false,
  saleId: "",
  loadingSaleDetails: false,
  loadingSaleCustomerDetails: false,
  //additional charge type
  saleAdditionalCharges: [],
  loadingSaleAdditionalCharge: false,
  createdCustomer: null,
  customerId: "",
  loadingSaleDirectOrderList: false,
  saleRowData: null,
  saleDetailCount: 0,
};

export const Sale = createSlice({
  name: "SaleReducer",
  initialState,
  reducers: {
    // Get Sales
    getSaleRequest: (state, payload) => {
      state.loadingSale = true;
    },
    getSaleSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSale = false;
      state.sales = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSaleFail: (state) => {
      state.loadingSale = false;
    },
    getSaleNextRequest: (state, payload) => {
      state.loadingSale = true;
    },
    getSalePreviousRequest: (state, payload) => {
      state.loadingSale = true;
    },
    getSaleSearchDataSuccess: (state, { payload: { results } }) => {
      state.loadingSearchData = true;
    },
    getSaleSearchDataFail: (state) => {
      state.loadingSearchData = false;
    },
    // Create Sale
    createSaleRequest: (state, payload) => {
      state.loadingCreateSale = true;
    },
    createSaleSuccess: (state, { payload }) => {
      state.loadingCreateSale = false;
      state.createdSalesId = payload?.response?.data?.id;
    },
    createSaleFail: (state) => {
      state.loadingCreateSale = false;
    },
    // Order PlatForm
    getOrderPlatFormRequest: (state) => {
      state.loadingOrderPlatForm = true;
    },
    getOrderPlatFormSuccess: (state, { payload: { results } }) => {
      state.loadingOrderPlatForm = false;
      state.orderPlatForms = results;
    },
    getOrderPlatFormFail: (state) => {
      state.loadingOrderPlatForm = false;
    },
    // Delivery Partners
    getDeliveryPartnersRequest: (state) => {
      state.loadingDeliveryPartners = true;
    },
    getDeliveryPartnersSuccess: (state, { payload: { results } }) => {
      state.loadingDeliveryPartners = false;
      state.deliveryPartners = results;
    },
    getDeliveryPartnersFail: (state) => {
      state.loadingDeliveryPartners = false;
    },
    //delete sale details
    deleteSaleDetailsSuccess: (state, { payload }) => {
      state.saleDetails = state.saleDetails.filter(
        (data) => data?.uniqueId !== payload?.uniqueId
      );
    },
    //loading autocomplete && request Loading
    loadingSaleCustomerRequest: (state, { payload }) => {
      state.loadingSaleCustomer = true;
    },
    getSaleCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingSaleCustomer = false;
      state.saleCustomers = results;
      state.createdCustomer = state.customerId !== "" ? results[0] : null;
    },
    getSaleCustomerFail: (state) => {
      state.loadingSaleCustomer = false;
    },
    // item request
    loadingSaleItemRequest: (state) => {
      state.loadingSaleItem = true;
    },
    getSaleItemRequest: (state, payload) => {
      state.loadingSaleItem = true;
    },
    getSaleItemSuccess: (state, { payload: { results } }) => {
      state.loadingSaleItem = false;
      state.saleItems = results;
    },
    getSaleItemFail: (state) => {
      state.loadingSaleItem = false;
    },
    //
    getSaleCustomerRequest: (state, { payload }) => {
      state.loadingSaleCustomer = true;
      state.customerId = payload?.createdCustomerId;
    },
    //add form data to table
    addSaleDetails: (state, { payload }) => {
      state.saleDetails = [...state.saleDetails, payload];
    },
    updateSaleDetails: (state, { payload }) => {
      state.saleDetails = payload;
    },
    //payment mode
    getSalePaymentModeRequest: (state) => {
      state.loadingSalePaymentMode = true;
    },
    getSalePaymentModeSuccess: (state, { payload }) => {
      state.salePaymentModes = payload.results;
      state.loadingSalePaymentMode = false;
    },
    getSalePaymentModeFail: (state) => {
      state.loadingSalePaymentMode = false;
    },
    //view details
    getViewSaleDetailsRequest: (state, { payload }) => {
      state.loadingViewDetails = true;
      state.saleId = payload?.id;
    },
    getViewSaleDetailsSuccess: (state, { payload }) => {
      state.loadingViewDetails = false;
      state.viewSaleDetails = payload;
    },
    getViewSaleDetailsFail: (state) => {
      state.loadingViewDetails = false;
    },
    // get sale details request
    getSaleDetailsRequest: (state, payload) => {
      state.loadingSaleDetails = true;
    },
    getSaleDetailsSuccess: (state, { payload: { results } }) => {
      state.loadingSaleDetails = false;
      state.saleDetails = results?.map((result: any) => ({
        ...result,
        netAmount: 0 * result?.cost,
        grossAmount: 0 * result?.cost,
        discountRate:
          Number(result.discountAmount * 100) /
          (result.cost * result.qty === 0 ? 1 : result.cost * result.qty),
        discountAmount: 0,
        returnQty: 0 * result?.returnQty,
      }));
    },
    getSaleDetailsFail: (state) => {
      state.loadingSaleDetails = false;
    },
    // get customer details
    getSaleCustomerDetailsRequest: (state, payload) => {
      state.loadingSaleCustomerDetails = true;
    },
    getSaleCustomerDetailsSuccess: (state, { payload }) => {
      state.saleRowData = {
        ...payload,
        customerTypeCode: payload?.customerType?.code,
      };
      state.loadingSaleCustomerDetails = false;
    },
    getSaleCustomerDetailsFail: (state) => {
      state.loadingSaleCustomerDetails = false;
    },
    //additional charge
    getSaleAdditionalChargeRequest: (state) => {
      state.loadingSaleAdditionalCharge = true;
    },
    getSaleAdditionalChargeSuccess: (state, { payload }) => {
      state.saleAdditionalCharges = payload.results;
      state.loadingSaleAdditionalCharge = false;
    },
    getSaleAdditionalChargeFail: (state) => {
      state.loadingSaleAdditionalCharge = false;
    },
    // clear sale data
    clearSaleData: (state) => {
      state.saleDetails = [];
      state.viewSaleDetails = [];
      state.saleRowData = null;
      state.customerId = "";
      state.createdCustomer = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getSaleRequest,
  getSaleSuccess,
  getSaleFail,
  getSaleSearchDataSuccess,
  getSaleSearchDataFail,
  getSaleNextRequest,
  getSalePreviousRequest,
  //
  createSaleRequest,
  createSaleSuccess,
  createSaleFail,
  clearSaleData,
  // delivery partners
  getDeliveryPartnersRequest,
  getDeliveryPartnersSuccess,
  getDeliveryPartnersFail,
  // order platforms
  getOrderPlatFormRequest,
  getOrderPlatFormSuccess,
  getOrderPlatFormFail,
  // get organization rule
  getSaleCustomerDetailsRequest,
  getSaleCustomerDetailsSuccess,
  getSaleCustomerDetailsFail,
  //customer
  getSaleCustomerFail,
  getSaleCustomerSuccess,
  loadingSaleCustomerRequest,
  getSaleCustomerRequest,
  //sale test
  getSaleItemFail,
  getSaleItemSuccess,
  loadingSaleItemRequest,
  getSaleItemRequest,
  //add
  addSaleDetails,
  //delete
  deleteSaleDetailsSuccess,
  updateSaleDetails,
  //payment
  getSalePaymentModeRequest,
  getSalePaymentModeSuccess,
  getSalePaymentModeFail,
  //view details
  getViewSaleDetailsRequest,
  getViewSaleDetailsSuccess,
  getViewSaleDetailsFail,
  //
  getSaleDetailsRequest,
  getSaleDetailsSuccess,
  getSaleDetailsFail,
  //additional charge
  getSaleAdditionalChargeRequest,
  getSaleAdditionalChargeSuccess,
  getSaleAdditionalChargeFail,
} = Sale.actions;

export default Sale.reducer;
