import {
  getFeaturedImageRequest,
  getFeaturedImageFail,
  getFeaturedImageSuccess,
  createFeaturedImageFail,
  createFeaturedImageRequest,
  createFeaturedImageSuccess,
  updateFeaturedImageFail,
  updateFeaturedImageSuccess,
  clearAllData,
  getFeaturedImageNextRequest,
  getFeaturedImagePreviousRequest,
  updateFeaturedImageRequest,
  getFeaturedImageByIdRequest,
  featuredImageEditSuccess,
  featuredImageEditFail,
} from "./featuredImagesSlice";
import { mergeMap } from "rxjs";
import {
  getFeaturedImage,
  createFeaturedImage,
  updateFeaturedImage,
  getFeaturedImageById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get FeaturedImage
const getFeaturedImageEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedImageRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getFeaturedImage(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFeaturedImageSuccess(action?.payload)
        : getFeaturedImageFail()
    )
  );

//get featuredImage by id epic
const getFeaturedImageByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedImageByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getFeaturedImageById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? featuredImageEditSuccess(action.payload)
        : featuredImageEditFail()
    )
  );

//create
const createFeaturedImageEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createFeaturedImageRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { image, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof image !== "string") {
          body.append("image", image);
        }
        const response = await createFeaturedImage(body);
        if (response) {
          dispatch(getFeaturedImageRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createFeaturedImageSuccess()
        : createFeaturedImageFail();
    })
  );

//update
const updateFeaturedImageEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateFeaturedImageRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      const { image, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof image !== "string") {
          body.append("image", image);
        }
        const response = await updateFeaturedImage(body, id);
        if (response) {
          dispatch(getFeaturedImageRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateFeaturedImageSuccess()
        : updateFeaturedImageFail();
    })
  );

//get next
const getFeaturedImageNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedImageNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getFeaturedImageSuccess(action?.payload)
        : getFeaturedImageFail();
    })
  );

//get previous
const getFeaturedImagePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedImagePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFeaturedImageSuccess(action?.payload)
        : getFeaturedImageFail()
    )
  );

export const featuredImageEpic = combineEpics(
  getFeaturedImageEpic,
  createFeaturedImageEpic,
  updateFeaturedImageEpic,
  getFeaturedImageNext,
  getFeaturedImagePrevious,
  getFeaturedImageByIdEpic
);
