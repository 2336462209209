import React, { Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { useAppSelector } from "../AppUtils/Utils/appHooks";
import { commonAppSelector } from "../Pages/CommonAppRedux/selector";
import CoreSetupRoutes from "./CoreSetupRoutes";
import InventoryRoutes from "./InventoryRoutes";
import ProductSetupRoutes from "./ProductSetupRoutes";
import WebsiteSetupRoutes from "./WebsiteSetupRoutes";
import { lazyWithReload } from "./Utils";

const PageNotFound = lazyWithReload(
  () => import("../Component/ErrorPages/PageNotFound")
);

const PrivateRoutes = () => {
  const { globalOrganizationRules, globalOrganizations } =
    useAppSelector(commonAppSelector);

  return (
    <>
      <Suspense fallback={<></>}>
        <>
          <CoreSetupRoutes />
          <InventoryRoutes />
          <ProductSetupRoutes />
          <WebsiteSetupRoutes />
          <Switch>
            <Redirect from="/" to="/profile" exact />
          </Switch>
        </>
      </Suspense>
    </>
  );
};

export default PrivateRoutes;
