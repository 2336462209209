import { makeStyles } from "@mui/styles";

export const headerStyles = makeStyles(() => ({
  fixedHeader: {
    backgroundColor: "#131620 !important",
    justifyContent: "center !important",
    zIndex: "200 !important",
    boxShadow: "none !important",
    height: "40px",
  },

  badgeIcons: {
    position: "relative",
    marginTop: 12,
    padding: "0 !important",
  },
  fixedHeaderIconsButton: {
    color: "#fff",
    fontSize: "1.5rem !important",
  },
  menuLink: {
    textDecoration: "none",
    color: "#305bb3",
  },
  // secondary header
  toolbar: {
    paddingRight: 24,
    minHeight: "40px !important",
  },
  menuButton: {
    // margin: "0 0 0 18px",
  },
  menuButtonHidden: {
    margin: "0 0 0 28px",
  },
  title: {
    flexGrow: 1,
  },
  searchAppbar: {
    position: "relative",
    backgroundColor: "#f5fcff",
    borderRadius: "4px",
    "&:hover": {
      borderColor: "#a4adb2",
    },
    padding: "2px 4px",
    width: "30% !important",
    height: 30,
    marginRight: 450,
    margin: 5,
  },
}));
