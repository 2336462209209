import {
  getOurPartnerRequest,
  getOurPartnerFail,
  getOurPartnerSuccess,
  createOurPartnerFail,
  createOurPartnerRequest,
  createOurPartnerSuccess,
  updateOurPartnerFail,
  updateOurPartnerSuccess,
  clearAllData,
  getOurPartnerNextRequest,
  getOurPartnerPreviousRequest,
  updateOurPartnerRequest,
  getOurPartnerByIdRequest,
  ourPartnerEditSuccess,
  ourPartnerEditFail,
} from "./ourPartnerSlice";
import { mergeMap } from "rxjs";
import {
  getOurPartner,
  createOurPartner,
  updateOurPartner,
  getOurPartnerById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get OurPartner
const getOurPartnerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOurPartnerRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getOurPartner(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOurPartnerSuccess(action?.payload)
        : getOurPartnerFail()
    )
  );

//get ourPartner by id epic
const getOurPartnerByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOurPartnerByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getOurPartnerById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? ourPartnerEditSuccess(action.payload)
        : ourPartnerEditFail()
    )
  );

//create
const createOurPartnerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createOurPartnerRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { image, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof image !== "string") {
          body.append("image", image);
        }
        const response = await createOurPartner(body);
        if (response) {
          dispatch(getOurPartnerRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createOurPartnerSuccess()
        : createOurPartnerFail();
    })
  );

//update
const updateOurPartnerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateOurPartnerRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      const { image, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof image !== "string") {
          body.append("image", image);
        }
        const response = await updateOurPartner(body, id);
        if (response) {
          dispatch(getOurPartnerRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateOurPartnerSuccess()
        : updateOurPartnerFail();
    })
  );

//get next
const getOurPartnerNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOurPartnerNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getOurPartnerSuccess(action?.payload)
        : getOurPartnerFail();
    })
  );

//get previous
const getOurPartnerPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOurPartnerPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOurPartnerSuccess(action?.payload)
        : getOurPartnerFail()
    )
  );

export const ourPartnerEpic = combineEpics(
  getOurPartnerEpic,
  createOurPartnerEpic,
  updateOurPartnerEpic,
  getOurPartnerNext,
  getOurPartnerPrevious,
  getOurPartnerByIdEpic
);
