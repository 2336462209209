import {
  createProvinceFail,
  createProvinceRequest,
  createProvinceSuccess,
  getProvinceFail,
  getProvinceSuccess,
  loadingProvince,
  updateProvinceFail,
  updateProvinceSuccess,
  getProvinceRequest,
  updateProvinceRequest,
  getProvinceNextRequest,
  getProvincePreviousRequest,
  clearProvinceData,
  getProvinceByIdRequest,
  provinceEditSuccess,
  provinceEditFail,
} from "./provinceSlice";
import { mergeMap } from "rxjs";
import { createProvince, getProvince, getProvinceById, updateProvince } from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";

//get Province epic
const getProvinceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProvinceRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingProvince());
      try {
        const response = await getProvince(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getProvinceSuccess(action?.payload) : getProvinceFail()
    )
  );

//get Province By Id epic
const getProvinceByIdEpic = (
  action$: Observable<Action>,
_: stateAction,
{ dispatch } : dispatchAction
) => action$.pipe(
  filter(getProvinceByIdRequest.match),
  mergeMap(async (action) => {
    try {
      const response = await getProvinceById(action.payload);
      return { payload: response.data};
    } catch (e) {
      return { errors : e};
    }
  }),
  map((action) => action?.payload ? provinceEditSuccess(action.payload) : provinceEditFail())
);

//get next
const getProvinceNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProvinceNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingProvince());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getProvinceSuccess(action?.payload) : getProvinceFail()
    )
  );
//get previous
const getProvincePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProvincePreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingProvince());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getProvinceSuccess(action?.payload) : getProvinceFail()
    )
  );
//create Province epic
const createProvinceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createProvinceRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { name, active } = values;
      try {
        const body = JSON.stringify({ name, active });
        const response = await createProvince(body);
        if (response) {
          dispatch(getProvinceRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createProvinceSuccess() : createProvinceFail();
    })
  );
//update Province epic
const updateProvinceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateProvinceRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, active, remarks } = values;
      try {
        const body = JSON.stringify({ name, active, remarks });
        const response = await updateProvince(body, id);
        if (response) {
          dispatch(getProvinceRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearProvinceData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateProvinceSuccess() : updateProvinceFail()
    )
  );

//

export const provinceEpics = combineEpics(
  getProvinceEpic,
  createProvinceEpic,
  updateProvinceEpic,
  getProvinceNext,
  getProvincePrevious,
  getProvinceByIdEpic,
);
