import {
  createTitleFail,
  createTitleRequest,
  createTitleSuccess,
  getTitleFail,
  getTitleSuccess,
  loadingTitle,
  updateTitleFail,
  updateTitleSuccess,
  getTitleRequest,
  getTitleNextRequest,
  getTitlePreviousRequest,
  updateTitleRequest,
  clearTitleData,
  getTitleByIdRequest,
  titleEditSuccess,
  titleEditFail,
} from "./titleSlice";
import { mergeMap } from "rxjs";
import { createTitle, getTitle, getTitleById, updateTitle } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get Title epic
const getTitleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTitleRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTitle());
      try {
        const response = await getTitle(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTitleSuccess(action?.payload) : getTitleFail()
    )
  );

//get Title by id
const getTitleByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTitleByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTitleById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? titleEditSuccess(action.payload) : titleEditFail()
    )
  );

//get next
const getTitleNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTitleNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTitle());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTitleSuccess(action?.payload) : getTitleFail()
    )
  );
//get previous
const getTitlePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTitlePreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingTitle());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTitleSuccess(action?.payload) : getTitleFail()
    )
  );
//create Title epic
const createTitleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createTitleRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        try {
          let body = new FormData();
          body.append("name", values?.name);
          body.append("shortName", values?.shortName);
          body.append("active", JSON.stringify(values?.active));
          if (values?.remarks) {
            body.append("remarks", values?.remarks);
          }

          const response = await createTitle(body);
          if (response) {
            dispatch(getTitleRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload ? createTitleSuccess() : createTitleFail();
    })
  );
//update title epic
const updateTitleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateTitleRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        let body = new FormData();
        body.append("name", values?.name);
        body.append("shortName", values?.shortName);
        body.append("active", JSON.stringify(values?.active));
        if (values?.remarks) {
          body.append("remarks", values?.remarks);
        }
        const response = await updateTitle(body, id);
        if (response) {
          dispatch(getTitleRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearTitleData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateTitleSuccess() : updateTitleFail()
    )
  );

export const titleEpics = combineEpics(
  getTitleEpic,
  createTitleEpic,
  updateTitleEpic,
  getTitleNext,
  getTitlePrevious,
  getTitleByIdEpic
);
