import { mergeMap } from "rxjs";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";

//
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
import {
  clearProductData,
  createProductFail,
  createProductRequest,
  createProductSuccess,
  getProductAttributeFail,
  getProductAttributeRequest,
  getProductAttributeSuccess,
  getProductByIdRequest,
  getProductCategoryFail,
  getProductCategoryRequest,
  getProductCategorySuccess,
  getProductFail,
  getProductNextRequest,
  getProductPreviousRequest,
  getProductRequest,
  getProductSuccess,
  getProductVariantAttributeFail,
  getProductVariantAttributeRequest,
  getProductVariantAttributeSuccess,
  loadingProduct,
  productEditFail,
  productEditSuccess,
  updateProductFail,
  updateProductRequest,
  updateProductSuccess,
} from "./productSlice";
import {
  createProduct,
  getProduct,
  getProductAtt,
  getProductById,
  getProductCategory,
  getProductVariantAtt,
  updateProduct,
} from "./api";
import {
  productAttributes,
  productContents,
  productDescriptionImages,
  productFaqs,
  productSpecialFeatures,
  productVariantAttributes,
  productVariantImages,
  productVariants,
} from "./types";

export const controller = new AbortController();

//get product epic
const getProductEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProductRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingProduct());
      try {
        const response = await getProduct(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getProductSuccess(action?.payload) : getProductFail()
    )
  );

//get product by Id epic
const getProductByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProductByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getProductById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? productEditSuccess(action.payload) : productEditFail()
    )
  );

//get next
const getProductNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProductNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingProduct());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getProductSuccess(action?.payload) : getProductFail()
    )
  );

//get previous
const getProductPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProductPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingProduct());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getProductSuccess(action?.payload) : getProductFail()
    )
  );

//create user epic
const createProductEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createProductRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const {
        deliveryInfo,
        warrantyInfo,
        productFaqs,
        productAttributes,
        productContents,
        productSpecialFeatures,
        variantAttributes,
        productVariants,
        productDescriptionImages,
        videoFile,

        ...restValues
      } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        //need to modify here-----------------------------------------------------------------------------------------------------------

        for (let [key, value] of Object.entries(warrantyInfo)) {
          //@ts-ignore
          body.append(`warrantyInfo[${key}]`, value);
        }

        for (let [key, value] of Object.entries(deliveryInfo)) {
          //@ts-ignore
          body.append(`deliveryInfo[${key}]`, value);
        }

        if (typeof videoFile !== "string") {
          body.append(`videoFile`, videoFile);
        }

        productFaqs?.forEach((prod: productFaqs, index: number) => {
          if (prod?.id) {
            //@ts-ignore
            body.append(`productFaqs[${index}][id]`, prod?.id);
          }
          body.append(`productFaqs[${index}][title]`, prod?.title);
          body.append(`productFaqs[${index}][description]`, prod?.description);
        });

        productAttributes?.forEach(
          (attrib: productAttributes, index: number) => {
            if (attrib?.id) {
              //@ts-ignore
              body.append(`productAttributes[${index}][id]`, attrib?.id);
            }
            body.append(`productAttributes[${index}][value]`, attrib?.value);
            //sending the id of the attribute of the perticular category
            body.append(
              `productAttributes[${index}][attribute]`,
              attrib?.attribute
            );
          }
        );

        productContents?.forEach((content: productContents, index: number) => {
          if (content?.id) {
            //@ts-ignore
            body.append(`productContents[${index}][id]`, content?.id);
          }
          body.append(`productContents[${index}][title]`, content?.title);
          body.append(
            `productContents[${index}][description]`,
            content?.description
          );
        });

        productSpecialFeatures?.forEach(
          (specFeat: productSpecialFeatures, index: number) => {
            if (specFeat?.id) {
              //@ts-ignore
              body.append(`productSpecialFeatures[${index}][id]`, specFeat?.id);
            }
            body.append(
              `productSpecialFeatures[${index}][title]`,
              specFeat?.title
            );
            body.append(
              `productSpecialFeatures[${index}][description]`,
              specFeat?.description
            );
          }
        );

        productVariants?.forEach((variant: productVariants, index: number) => {
          if (variant?.id) {
            //@ts-ignore
            body.append(`productVariants[${index}][id]`, variant?.id);
          }
          if (variant?.featuredImage) {
            body.append(
              `productVariants[${index}][featuredImage]`,
              variant?.featuredImage
            );
          }
          if (variant?.hoverImage) {
            body.append(
              `productVariants[${index}][hoverImage]`,
              variant?.hoverImage
            );
          }
          body.append(`productVariants[${index}][sku]`, variant?.sku);
          body.append(`productVariants[${index}][name]`, variant?.name);
          body.append(
            `productVariants[${index}][maxOrderableQty]`,
            variant?.maxOrderableQty
          );
          body.append(`productVariants[${index}][price]`, variant?.price);
          //@ts-ignore
          body.append(`productVariants[${index}][hasOffer]`, variant?.hasOffer);
          body.append(
            `productVariants[${index}][offerPrice]`,
            variant?.offerPrice
          );
          body.append(
            `productVariants[${index}][isDefault]`,
            //@ts-ignore
            variant?.isDefault
          );

          variant?.productVariantAttributes.forEach(
            (attribute: productVariantAttributes, childIndex: number) => {
              if (attribute?.id) {
                body.append(
                  `productVariants[${index}][productVariantAttributes][${childIndex}][id]`,
                  //@ts-ignore
                  attribute?.id
                );
              }
              body.append(
                `productVariants[${index}][productVariantAttributes][${childIndex}][attribute]`,
                //@ts-ignore
                attribute?.attribute
              );
              body.append(
                `productVariants[${index}][productVariantAttributes][${childIndex}][value]`,
                //@ts-ignore
                attribute?.value
              );
            }
          );
          variant?.productVariantImages?.forEach(
            (variantImage: productVariantImages, childIndex: number) => {
              if (variantImage?.id) {
                body.append(
                  `productVariants[${index}][productVariantImages][${childIndex}][id]`,
                  //@ts-ignore
                  variantImage?.id
                );
              }
              body.append(
                `productVariants[${index}][productVariantImages][${childIndex}][displayOrder]`,
                //@ts-ignore
                variantImage?.displayOrder
              );
              body.append(
                `productVariants[${index}][productVariantImages][${childIndex}][image]`,
                //@ts-ignore
                variantImage?.image
              );
              body.append(
                `productVariants[${index}][productVariantImages][${childIndex}][altText]`,
                variantImage?.altText
              );
            }
          );
        });

        productDescriptionImages?.forEach(
          (descImg: productDescriptionImages, index: number) => {
            if (typeof descImg?.image !== "string") {

            if (descImg?.id) {
              //@ts-ignore
              body.append(`productDescriptionImages[${index}][id]`, descImg?.id);
            }
            //@ts-ignore
            body.append(`productDescriptionImages[${index}][image]`, descImg?.image);
            body.append(
              `productDescriptionImages[${index}][displayOrder]`,
              //@ts-ignore
              descImg?.displayOrder
            );
            }
          }
        );

        const response = await createProduct(body);
        if (response) {
          dispatch(getProductRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createProductSuccess() : createProductFail();
    })
  );

//update product epic
const updateProductEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateProductRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const {
        deliveryInfo,
        warrantyInfo,
        productFaqs,
        productAttributes,
        productContents,
        productSpecialFeatures,
        variantAttributes,
        productVariants,
        productDescriptionImages,
        videoFile,
        ...restValues
      } = values;
      const body = new FormData();
      for (let [key, value] of Object.entries(restValues)) {
        // @ts-ignore
        body.append(`${key}`, value);
      }

      for (let [key, value] of Object.entries(warrantyInfo)) {
        //@ts-ignore
        body.append(`warrantyInfo[${key}]`, value);
      }

      for (let [key, value] of Object.entries(deliveryInfo)) {
        //@ts-ignore
        body.append(`deliveryInfo[${key}]`, value);
      }

      if (typeof videoFile !== "string") {
        body.append(`videoFile`, videoFile);
      }

      productFaqs?.forEach((prod: productFaqs, index: number) => {
        if (prod?.id) {
          //@ts-ignore
          body.append(`productFaqs[${index}][id]`, prod?.id);
        }
        body.append(`productFaqs[${index}][title]`, prod?.title);
        body.append(`productFaqs[${index}][description]`, prod?.description);
      });

      productAttributes?.forEach((attrib: productAttributes, index: number) => {
        if (attrib?.id) {
          //@ts-ignore
          body.append(`productAttributes[${index}][id]`, attrib?.id);
        }
        body.append(`productAttributes[${index}][value]`, attrib?.value);
        //sending the id of the attribute of the perticular category
        body.append(
          `productAttributes[${index}][attribute]`,
          attrib?.attribute
        );
      });

      productContents?.forEach((content: productContents, index: number) => {
        if (content?.id) {
          //@ts-ignore
          body.append(`productContents[${index}][id]`, content?.id);
        }
        body.append(`productContents[${index}][title]`, content?.title);
        body.append(
          `productContents[${index}][description]`,
          content?.description
        );
      });

      productSpecialFeatures?.forEach(
        (specFeat: productSpecialFeatures, index: number) => {
          if (specFeat?.id) {
            //@ts-ignore
            body.append(`productSpecialFeatures[${index}][id]`, specFeat?.id);
          }
          body.append(
            `productSpecialFeatures[${index}][title]`,
            specFeat?.title
          );
          body.append(
            `productSpecialFeatures[${index}][description]`,
            specFeat?.description
          );
        }
      );

      productVariants?.forEach((variant: productVariants, index: number) => {
        if (variant?.id) {
          //@ts-ignore
          body.append(`productVariants[${index}][id]`, variant?.id);
        }
        if (variant?.featuredImage) {
          if (typeof variant?.featuredImage !== "string") {
            body.append(
              `productVariants[${index}][featuredImage]`,
              variant?.featuredImage
            );
          }
        }
        if (variant?.hoverImage) {
          if (typeof variant?.hoverImage !== "string") {
            body.append(
              `productVariants[${index}][hoverImage]`,
              variant?.hoverImage
            );
          }
        }
        body.append(`productVariants[${index}][sku]`, variant?.sku);
        body.append(`productVariants[${index}][name]`, variant?.name);
        body.append(
          `productVariants[${index}][maxOrderableQty]`,
          variant?.maxOrderableQty
        );
        body.append(`productVariants[${index}][price]`, variant?.price);
        //@ts-ignore
        body.append(`productVariants[${index}][hasOffer]`, variant?.hasOffer);
        body.append(
          `productVariants[${index}][offerPrice]`,
          variant?.offerPrice
        );
        body.append(
          `productVariants[${index}][isDefault]`,
          //@ts-ignore
          variant?.isDefault
        );

        variant?.productVariantAttributes.forEach(
          (attribute: productVariantAttributes, childIndex: number) => {
            //only send productVariantAttribute id if it is being edited else send nothing i.e when adding new variant
            if (attribute?.id) {
              body.append(
                `productVariants[${index}][productVariantAttributes][${childIndex}][id]`,
                //@ts-ignore
                attribute?.id
              );
            }
            body.append(
              `productVariants[${index}][productVariantAttributes][${childIndex}][attribute]`,
              //@ts-ignore
              attribute?.attribute
            );
            body.append(
              `productVariants[${index}][productVariantAttributes][${childIndex}][value]`,
              //@ts-ignore
              attribute?.value
            );
          }
        );
        variant?.productVariantImages?.forEach(
          (variantImage: productVariantImages, childIndex: number) => {
            // if (typeof variantImage?.image !== "string") {
              if (variantImage?.id) {
                body.append(
                  `productVariants[${index}][productVariantImages][${childIndex}][id]`,
                  //@ts-ignore
                  variantImage?.id
                );
              }
              body.append(
                `productVariants[${index}][productVariantImages][${childIndex}][displayOrder]`,
                //@ts-ignore
                variantImage?.displayOrder
              );
              if (typeof variantImage?.image !== "string") {
                body.append(
                  `productVariants[${index}][productVariantImages][${childIndex}][image]`,
                  //@ts-ignore
                  variantImage?.image
                );
              }
              body.append(
                `productVariants[${index}][productVariantImages][${childIndex}][altText]`,
                variantImage?.altText
              );
            // }
          }
        );
      });

      productDescriptionImages?.forEach(
        (descImg: productDescriptionImages, index: number) => {
            // if (typeof descImg?.image !== "string") {

          if (descImg?.id) {
            //@ts-ignore
            body.append(`productDescriptionImages[${index}][id]`, descImg?.id);
          }
          if (typeof descImg?.image !== "string") {
            //@ts-ignore
            body.append(`productDescriptionImages[${index}][image]`, descImg?.image);
          }
          body.append(
            `productDescriptionImages[${index}][displayOrder]`,
            //@ts-ignore
            descImg?.displayOrder
          );
            // }
        }
      );

      try {
        const response = await updateProduct(body, id);
        if (response) {
          dispatch(getProductRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearProductData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateProductSuccess() : updateProductFail()
    )
  );

//get product category
const getProductCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProductCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getProductCategory(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getProductCategorySuccess(action?.payload)
        : getProductCategoryFail()
    )
  );

//get product attribute
const getProductAttributeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProductAttributeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getProductAtt(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getProductAttributeSuccess(action?.payload)
        : getProductAttributeFail()
    )
  );

//get product variant attribute
const getProductVariantAttributeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProductVariantAttributeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getProductVariantAtt(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getProductVariantAttributeSuccess(action?.payload)
        : getProductVariantAttributeFail()
    )
  );

export const productEpics = combineEpics(
  getProductEpic,
  getProductByIdEpic,
  createProductEpic,
  updateProductEpic,
  getProductPrevious,
  getProductNext,
  getProductCategoryEpic,
  getProductAttributeEpic,
  getProductVariantAttributeEpic
);
