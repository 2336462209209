import { mergeMap } from "rxjs";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";

//
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
import {
  clearProductCategoryData,
  createProductCategoryFail,
  createProductCategoryRequest,
  createProductCategorySuccess,
  getProductCategoryByIdRequest,
  getProductCategoryFail,
  getProductCategoryNextRequest,
  getProductCategoryPreviousRequest,
  getProductCategoryRequest,
  getProductCategorySuccess,
  loadingProductCategory,
  productCategoryEditFail,
  productCategoryEditSuccess,
  updateProductCategoryFail,
  updateProductCategoryRequest,
  updateProductCategorySuccess,
} from "./productCategorySlice";
import {
  createProductCategory,
  getProductCategory,
  getProductCategoryById,
  updateProductCategory,
} from "./api";

export const controller = new AbortController();

//get product Category epic
const getProductCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProductCategoryRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingProductCategory());
      try {
        const response = await getProductCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getProductCategorySuccess(action?.payload)
        : getProductCategoryFail()
    )
  );

//get product category by Id epic
const getProductCategoryByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProductCategoryByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getProductCategoryById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? productCategoryEditSuccess(action.payload)
        : productCategoryEditFail()
    )
  );

//get next
const getProductCategoryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProductCategoryNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingProductCategory());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getProductCategorySuccess(action?.payload)
        : getProductCategoryFail()
    )
  );

//get previous
const getProductCategoryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProductCategoryPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingProductCategory());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getProductCategorySuccess(action?.payload)
        : getProductCategoryFail()
    )
  );

//create user epic
const createProductCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createProductCategoryRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const {
        categoryAttributeGroups,
        featuredImage,
        bannerImageSm,
        bannerImageMd,
        ...restValues
      } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }

        if (typeof featuredImage !== "string") {
          body.append("featuredImage", featuredImage);
        }
        if (typeof bannerImageSm !== "string") {
          body.append("bannerImageSm", bannerImageSm);
        }
        if (typeof bannerImageMd !== "string") {
          body.append("bannerImageMd", bannerImageMd);
        }
        categoryAttributeGroups?.forEach((groups: any, index: number) => {
          body.append(
            `categoryAttributeGroups[${index}][title]`,
            groups?.title
          );
          body.append(
            `categoryAttributeGroups[${index}][isActive]`,
            groups?.isActive
          );
          body.append(
            `categoryAttributeGroups[${index}][isCommingSoon]`,
            groups?.isCommingSoon
          );
          groups?.attributes?.forEach((attribute: any, childIndex: number) => {
            body.append(
              `categoryAttributeGroups[${index}][attributes][${childIndex}][title]`,
              attribute?.title
            );
            body.append(
              `categoryAttributeGroups[${index}][attributes][${childIndex}][isActive]`,
              attribute?.isActive
            );
            body.append(
              `categoryAttributeGroups[${index}][attributes][${childIndex}][isRequired]`,
              attribute?.isRequired
            );
            body.append(
              `categoryAttributeGroups[${index}][attributes][${childIndex}][isVariant]`,
              attribute?.isVariant
            );
          });
        });

        const response = await createProductCategory(body);
        if (response) {
          dispatch(getProductCategoryRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createProductCategorySuccess()
        : createProductCategoryFail();
    })
  );

//update product category epic
const updateProductCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateProductCategoryRequest.match),
    mergeMap(
      async ({
        payload: {
          values: {
            categoryAttributeGroups,
            featuredImage,
            bannerImageMd,
            bannerImageSm,
            ...restValues
          },
          id,
          rowsPerPage,
          page,
        },
      }) => {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        if (typeof featuredImage !== "string") {
          body.append("featuredImage", featuredImage);
        }
        if (typeof bannerImageSm !== "string") {
          body.append("bannerImageSm", bannerImageSm);
        }
        if (typeof bannerImageMd !== "string") {
          body.append("bannerImageMd", bannerImageMd);
        }

        categoryAttributeGroups?.forEach((groups: any, index: number) => {
          if (groups?.id) {
            body.append(`categoryAttributeGroups[${index}][id]`, groups?.id);
          }
          body.append(
            `categoryAttributeGroups[${index}][title]`,
            groups?.title
          );
          body.append(
            `categoryAttributeGroups[${index}][isActive]`,
            groups?.isActive
          );
          body.append(
            `categoryAttributeGroups[${index}][isCommingSoon]`,
            groups?.isCommingSoon
          );
          groups?.attributes?.forEach((attribute: any, childIndex: number) => {
            if (attribute?.id) {
              body.append(
                `categoryAttributeGroups[${index}][attributes][${childIndex}][id]`,
                attribute?.id
              );
            }
            body.append(
              `categoryAttributeGroups[${index}][attributes][${childIndex}][title]`,
              attribute?.title
            );
            body.append(
              `categoryAttributeGroups[${index}][attributes][${childIndex}][isActive]`,
              attribute?.isActive
            );
            body.append(
              `categoryAttributeGroups[${index}][attributes][${childIndex}][isRequired]`,
              attribute?.isRequired
            );
            body.append(
              `categoryAttributeGroups[${index}][attributes][${childIndex}][isVariant]`,
              attribute?.isVariant
            );
          });
        });

        try {
          const response = await updateProductCategory(body, id);
          if (response) {
            dispatch(getProductCategoryRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.updateMessage));
            dispatch(clearProductCategoryData());
            dispatch(closeModal());
          }
          return { payload: { response, rowsPerPage } };
        } catch (e) {
          dispatch(alertErrorAction(messages.updateFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateProductCategorySuccess()
        : updateProductCategoryFail()
    )
  );

export const productCategoryEpics = combineEpics(
  getProductCategoryEpic,
  getProductCategoryByIdEpic,
  createProductCategoryEpic,
  updateProductCategoryEpic,
  getProductCategoryPrevious,
  getProductCategoryNext
);
