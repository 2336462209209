import {
  getBannerRequest,
  getBannerFail,
  getBannerSuccess,
  createBannerFail,
  createBannerRequest,
  createBannerSuccess,
  updateBannerFail,
  updateBannerSuccess,
  clearAllData,
  getBannerNextRequest,
  getBannerPreviousRequest,
  updateBannerRequest,
  getBannerByIdRequest,
  bannerEditSuccess,
  bannerEditFail,
} from "./bannerSlice";
import { mergeMap } from "rxjs";
import { getBanner, createBanner, updateBanner, getBannerById } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get Banner
const getBannerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBannerRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getBanner(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBannerSuccess(action?.payload) : getBannerFail()
    )
  );

//get banner by id epic
const getBannerByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBannerByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getBannerById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? bannerEditSuccess(action.payload) : bannerEditFail()
    )
  );

//create
const createBannerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createBannerRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { mobileImage, desktopImage,...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof mobileImage !== "string") {
          body.append("mobileImage", mobileImage);
        }
        if (typeof desktopImage !== "string") {
          body.append("desktopImage", desktopImage);
        }
        const response = await createBanner(body);
        if (response) {
          dispatch(getBannerRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createBannerSuccess() : createBannerFail();
    })
  );

//update
const updateBannerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateBannerRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      const { mobileImage, desktopImage, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof mobileImage !== "string") {
          body.append("mobileImage", mobileImage);
        }
        if (typeof desktopImage !== "string") {
          body.append("desktopImage", desktopImage);
        }
        const response = await updateBanner(body, id);
        if (response) {
          dispatch(getBannerRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updateBannerSuccess() : updateBannerFail();
    })
  );

//get next
const getBannerNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBannerNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getBannerSuccess(action?.payload)
        : getBannerFail();
    })
  );

//get previous
const getBannerPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBannerPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBannerSuccess(action?.payload) : getBannerFail()
    )
  );

export const bannerEpic = combineEpics(
  getBannerEpic,
  createBannerEpic,
  updateBannerEpic,
  getBannerNext,
  getBannerPrevious,
  getBannerByIdEpic
);
