import { Switch } from "react-router-dom";
import {
  invCreditClearancePermission,
  invCustomerPermission,
  invCustomerWiseReportPermission,
  invIrdSyncPermission,
  invItemUnitPermission,
  invManufacturerPermission,
  invMaterializedReportPermission,
  invPackingTypePermission,
  invPartyPaymentPermission,
  invPurchasePermission,
  invPurchaseReportPermission,
  invPurchaseReturnPermission,
  invSalePermission,
  invSaleReportPermission,
  invSaleReturnPermission,
  invStockAnalysisPermission,
  invStockAnalysisReportPermission,
  invSupplierPermission,
  invSupplierWiseReportPermission,
  invUserActivityLogReportPermission,
  invUserWiseReportPermission,
  itemCategoryPermission,
  itemPermission,
} from "../Component/Layout/Sidebar/Inventory/InvPermissionConstants";

import ProtectedRoutes from "./ProtectedRoutes";
import { lazyWithReload } from "./Utils";

const POS = lazyWithReload(() => import("../Pages/POSPages"));

const StockAnalysis = lazyWithReload(
  () => import("../Pages/POSPages/StockAnalysis/StockAnalysisPages")
);
const Purchase = lazyWithReload(
  () => import("../Pages/POSPages/Purchase/PurchasePages")
);
const PurchaseReturn = lazyWithReload(
  () => import("../Pages/POSPages/PurchaseReturnPages/PurchaseReturnPages")
);
const Supplier = lazyWithReload(
  () => import("../Pages/POSPages/Supplier/SupplierPages")
);
const PurchaseReport = lazyWithReload(
  () =>
    import(
      "../Pages/POSPages/Purchase/PurchaseReport/PurchaseReportPages/Index"
    )
);

const SaleReport = lazyWithReload(
  () => import("../Pages/POSPages/Purchase/SaleReport/SaleReportPages/Index")
);

const Sale = lazyWithReload(() => import("../Pages/POSPages/Sale/SalePages"));

const SaleReturn = lazyWithReload(
  () => import("../Pages/POSPages/SaleReturn/SaleReturnPages")
);

const Customer = lazyWithReload(
  () => import("../Pages/POSPages/Customer/CustomerPages")
);
const CustomerWiseReport = lazyWithReload(
  () =>
    import(
      "../Pages/POSPages/InventoryReport/CustomerWiseReport/CustomerWiseReportPages/Index"
    )
);
const StockAnalysisReport = lazyWithReload(
  () =>
    import(
      "../Pages/POSPages/InventoryReport/StockAnalysis/StockAnalysisReportPages/Index"
    )
);
const UserWiseReport = lazyWithReload(
  () =>
    import(
      "../Pages/POSPages/InventoryReport/UserWiseReport/UserWiseReportPages/Index"
    )
);
const SupplierWiseReport = lazyWithReload(
  () =>
    import(
      "../Pages/POSPages/InventoryReport/SupplierWiseSaleReport/SupplierWiseSaleReportPages/Index"
    )
);
const PartyPayment = lazyWithReload(
  () => import("../Pages/POSPages/PartyPayment/PartyPaymentPages")
);
const CreditClearance = lazyWithReload(
  () => import("../Pages/POSPages/CreditClearance/CreditClearancePages")
);
const CustomerOrder = lazyWithReload(
  () => import("../Pages/POSPages/Order/CustomerOrderPages")
);

// inventory components imports
const InventoryRoutes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoutes
          exact
          path="/inventory"
          component={POS}
          permission={[{ mainModule: true }]}
        />
        <ProtectedRoutes
          exact
          path="/inventory/purchase"
          component={Purchase}
          permission={invPurchasePermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/purchase-return"
          component={PurchaseReturn}
          permission={invPurchaseReturnPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/supplier"
          component={Supplier}
          permission={invSupplierPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/order"
          component={CustomerOrder}
          permission={invSupplierPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/purchase-report"
          component={PurchaseReport}
          permission={invPurchaseReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/sale-report"
          component={SaleReport}
          permission={invSaleReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/sale"
          component={Sale}
          permission={invSalePermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/sale-return"
          component={SaleReturn}
          permission={invSaleReturnPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/customer"
          component={Customer}
          permission={invCustomerPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/customer-wise-report"
          component={CustomerWiseReport}
          permission={invCustomerWiseReportPermission}
        />
        {/* <ProtectedRoutes
          exact
          path="/inventory/user-wise-report"
          component={UserWiseReport}
          permission={invUserWiseReportPermission}
        /> */}
        <ProtectedRoutes
          exact
          path="/inventory/supplier-wise-report"
          component={SupplierWiseReport}
          permission={invSupplierWiseReportPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/party-payment"
          component={PartyPayment}
          permission={invPartyPaymentPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/credit-clearance"
          component={CreditClearance}
          permission={invCreditClearancePermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/stock-analysis"
          component={StockAnalysis}
          permission={invStockAnalysisPermission}
        />
        <ProtectedRoutes
          exact
          path="/inventory/stock-analysis-report"
          component={StockAnalysisReport}
          permission={invStockAnalysisReportPermission}
        />
      </Switch>
    </>
  );
};

export default InventoryRoutes;
