import { Switch } from "react-router-dom";

import {
  productCategoryPermission,
  productPermission,
} from "../Component/Layout/Sidebar/Product/ProductPermissionConstants";
import ProtectedRoutes from "./ProtectedRoutes";
import { lazyWithReload } from "./Utils";

const ProductSetup = lazyWithReload(() => import("../Pages/ProductSetupPages"));
const ProductCategory = lazyWithReload(
  () => import("../Pages/ProductSetupPages/ProductCategory/ProductCategoryPages")
);
const Product = lazyWithReload(
  () => import("../Pages/ProductSetupPages/Product/ProductPages")
);

const ProductSetupRoutes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoutes
          exact
          path="/product-setup"
          component={ProductSetup}
          permission={[{ mainModule: true }]}
        />
        <ProtectedRoutes
          exact
          path="/product-setup/product-category"
          component={ProductCategory}
          permission={productCategoryPermission}
        />
        <ProtectedRoutes
          exact
          path="/product-setup/product"
          component={Product}
          permission={productPermission}
        />
      </Switch>
    </>
  );
};

export default ProductSetupRoutes;
