import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { SupplierWiseReport } from "./types";

const SupplierWiseReportModURL = '/api/v1/inventory-financial-report-app/supplier-wise-purchase-report';


export const getSupplierWiseReportSupplier = () =>
axiosInstance.get(`${SupplierWiseReportModURL}/supplier?offset=0&limit=${0}`);
export const getSupplierWiseReportUser = () =>
axiosInstance.get(`${SupplierWiseReportModURL}/user?offset=0&limit=${0}`);
export const getSupplierWiseReportItem = () =>
axiosInstance.get(`${SupplierWiseReportModURL}/item?offset=0&limit=${0}`);
export const getSupplierWiseReportAllReport = (data:any) =>{
  const {updatedValue} = data;

  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${SupplierWiseReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  )};
export const getSupplierWiseReportQuickReport = (data: any) => {
  const { rowsPerPage, page ,updatedValue} = data;
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${SupplierWiseReportModURL}/${summary?"summary":"detail"}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};

export const searchSupplierWiseReport = ({ rowsPerPage, search ,updatedValue}: any) => {
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;

  return `${SupplierWiseReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${rowsPerPage}&search=${search}&date_after=${startDateAd}&date_before=${endDateAd}`;
};