import {
  websiteAlbumPermission,
  websiteBannerPermission,
  websiteBlogCategoryPermission,
  websiteBlogPermission,
  websiteBlogTagPermission,
  websiteFeaturedImagePermission,
  websiteFeaturedProductPermission,
  websiteFeaturedVideoPermission,
  websiteGeneralFaqsPermission,
  websiteOurPartnerPermission,
  websiteOurTeamPermission,
  websitePromoCodePermission,
} from "./WebsitePermissionConstants";
import userIcon from "../../../../Assets/icons/CoreSetUpIcons/user_setup.svg";
import user from "../../../../Assets/icons/CoreSetupMidIcons/user.svg";

export const WebsiteSidebarData = [
  {
    name: "Website",
    customIcon: userIcon,
    items: [
      {
        name: "Album",
        image: user,
        link: "/website-setup/album",
        permission: websiteAlbumPermission,
      },
      {
        name: "Home Banner Slider",
        image: user,
        link: "/website-setup/home-banner-slider",
        permission: websiteBannerPermission,
      },
      {
        name: "Featured Image",
        image: user,
        link: "/website-setup/featured-image",
        permission: websiteFeaturedImagePermission,
      },
      {
        name: "Featured Product",
        image: user,
        link: "/website-setup/featured-product",
        permission: websiteFeaturedProductPermission,
      },
      {
        name: "Featured Video",
        image: user,
        link: "/website-setup/featured-video",
        permission: websiteFeaturedVideoPermission,
      },
      {
        name: "Our Partner",
        image: user,
        link: "/website-setup/our-partner",
        permission: websiteOurPartnerPermission,
      },
      {
        name: "General Faqs",
        image: user,
        link: "/website-setup/general-faqs",
        permission: websiteGeneralFaqsPermission,
      },
      {
        name: "Our Team",
        image: user,
        link: "/website-setup/our-team",
        permission: websiteOurTeamPermission,
      },
    ],
  },
  {
    name: "Blog",
    customIcon: userIcon,
    items: [
      {
        name: "Blog Tag",
        image: user,
        link: "/website-setup/blog-tag",
        permission: websiteBlogTagPermission,
      },
      {
        name: "Blog Category",
        image: user,
        link: "/website-setup/blog-category",
        permission: websiteBlogCategoryPermission,
      },
      {
        name: "Blog",
        image: user,
        link: "/website-setup/blog",
        permission: websiteBlogPermission,
      },
    ],
  },
  {
    name: "Promo Code",
    customIcon: userIcon,
    items: [
      {
        name: "Promo Code",
        image: user,
        link: "/website-setup/promo-code",
        permission: websitePromoCodePermission,
      },
    ],
  },
];
