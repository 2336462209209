import { createSlice } from "@reduxjs/toolkit";
import { albumInitialState } from "./types";

const initialState: albumInitialState = {
  albums: [],
  edit: false,
  album: null,
  loadingAlbum: false,
  loadingCreateAlbum: false,
  count: 0,
  previous: "",
  next: "",
};

export const Album = createSlice({
  name: "albumReducer",
  initialState,
  reducers: {
    getAlbumRequest: (state, payload) => {
      state.loadingAlbum = true;
    },
    getAlbumSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingAlbum = false;
      state.albums = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getAlbumFail: (state) => {
      state.loadingAlbum = false;
    },

    getAlbumNextRequest: (state, payload) => {
      state.loadingAlbum = true;
    },
    getAlbumPreviousRequest: (state, payload) => {
      state.loadingAlbum = true;
    },
    createAlbumRequest: (state, payload) => {
      state.loadingCreateAlbum = true;
    },
    updateAlbumRequest: (state, payload) => {
      state.loadingCreateAlbum = true;
    },
    createAlbumSuccess: (state) => {
      state.loadingCreateAlbum = false;
    },
    createAlbumFail: (state) => {
      state.loadingCreateAlbum = false;
    },
    getAlbumByIdRequest: (state, payload) => {
      state.edit = true;
    },
    albumEditSuccess: (state, { payload }) => {
      state.album = payload;
    },
    albumEditFail: (state) => {
      state.edit = false;
    },
    updateAlbumSuccess: (state) => {
      state.loadingCreateAlbum = false;
    },
    updateAlbumFail: (state) => {
      state.loadingCreateAlbum = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.album = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getAlbumRequest,
  getAlbumFail,
  getAlbumSuccess,
  getAlbumNextRequest,
  getAlbumPreviousRequest,
  createAlbumFail,
  createAlbumRequest,
  createAlbumSuccess,
  albumEditSuccess,
  updateAlbumFail,
  updateAlbumSuccess,
  updateAlbumRequest,
  clearAllData,
  getAlbumByIdRequest,
  albumEditFail,
} = Album.actions;

export default Album.reducer;
