import { createSlice } from "@reduxjs/toolkit";
import { generalFaqsInitialState } from "./types";

const initialState: generalFaqsInitialState = {
  promoCodes: [],
  edit: false,
  promoCode: null,
  loadingPromoCode: false,
  loadingCreatePromoCode: false,
  promoProducts: [],
  loadingPromoProduct: false,
  customers: [],
  loadingPromoCustomer: false,
  count: 0,
  previous: "",
  next: "",
};

export const PromoCode = createSlice({
  name: "promoCodeReducer",
  initialState,
  reducers: {
    getPromoCodeRequest: (state, payload) => {
      state.loadingPromoCode = true;
    },
    getPromoCodeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingPromoCode = false;
      state.promoCodes = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPromoCodeFail: (state) => {
      state.loadingPromoCode = false;
    },

    getPromoCodeNextRequest: (state, payload) => {
      state.loadingPromoCode = true;
    },
    getPromoCodePreviousRequest: (state, payload) => {
      state.loadingPromoCode = true;
    },
    createPromoCodeRequest: (state, payload) => {
      state.loadingCreatePromoCode = true;
    },
    updatePromoCodeRequest: (state, payload) => {
      state.loadingCreatePromoCode = true;
    },
    createPromoCodeSuccess: (state) => {
      state.loadingCreatePromoCode = false;
    },
    createPromoCodeFail: (state) => {
      state.loadingCreatePromoCode = false;
    },
    getPromoCodeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    promoCodeEditSuccess: (state, { payload }) => {
      state.promoCode = payload;
    },
    promoCodeEditFail: (state) => {
      state.edit = false;
    },
    updatePromoCodeSuccess: (state) => {
      state.loadingCreatePromoCode = false;
    },
    updatePromoCodeFail: (state) => {
      state.loadingCreatePromoCode = false;
    },
    //product
    loadingPromoProductRequest: (state) => {
      state.loadingPromoProduct = true;
    },
    getPromoProductRequest: (state, payload) => {
      state.loadingPromoProduct = true;
    },
    getPromoProductSuccess: (state, { payload: { results } }) => {
      state.loadingPromoProduct = false;
      state.promoProducts = results;
    },
    getPromoProductFail: (state) => {
      state.loadingPromoProduct = false;
    },
    //customer
    loadingPromoCustomerRequest: (state) => {
      state.loadingPromoCustomer = true;
    },
    getPromoCustomerRequest: (state, payload) => {
      state.loadingPromoCustomer = true;
    },
    getPromoCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingPromoCustomer = false;
      state.customers = results;
    },
    getPromoCustomerFail: (state) => {
      state.loadingPromoCustomer = false;
    },
    //
    clearAllData: (state) => {
      state.edit = false;
      state.promoCode = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getPromoCodeRequest,
  getPromoCodeFail,
  getPromoCodeSuccess,
  getPromoCodeNextRequest,
  getPromoCodePreviousRequest,
  createPromoCodeFail,
  createPromoCodeRequest,
  createPromoCodeSuccess,
  updatePromoCodeFail,
  updatePromoCodeSuccess,
  updatePromoCodeRequest,
  clearAllData,
  getPromoCodeByIdRequest,
  promoCodeEditSuccess,
  promoCodeEditFail,
  //product
  loadingPromoProductRequest,
  getPromoProductRequest,
  getPromoProductSuccess,
  getPromoProductFail,
  //customer
  loadingPromoCustomerRequest,
  getPromoCustomerRequest,
  getPromoCustomerSuccess,
  getPromoCustomerFail,
} = PromoCode.actions;

export default PromoCode.reducer;
