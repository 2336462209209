import { createSlice } from "@reduxjs/toolkit";
import { productInitialState } from "./types";

const initialState: productInitialState = {
  edit: false,
  loadingProduct: false,
  loadingCreateProduct: false,
  products: [],
  product: null,
  loadingProductCategories: false,
  categories: [],
  loadingProductAttributes: false,
  productAttributesList: [],
  loadingProductVariantAttributes: false,
  productVariantAttributesList: [],
  //table props for product variants
  productVariants: [],
  count: 0,
  previous: "",
  next: "",
};

export const Product = createSlice({
  name: "ProductReducer",
  initialState,
  reducers: {
    //
    loadingProduct: (state) => {
      state.loadingProduct = true;
    },
    getProductRequest: (state, payload) => {
      state.loadingProduct = true;
    },
    getProductSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingProduct = false;
      state.products = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getProductFail: (state) => {
      state.loadingProduct = false;
    },
    //
    getProductNextRequest: (state, payload) => {
      state.loadingProduct = true;
    },
    getProductPreviousRequest: (state, payload) => {
      state.loadingProduct = true;
    },
    //
    createProductRequest: (state, payload) => {
      state.loadingCreateProduct = true;
    },
    createProductSuccess: (state) => {
      state.loadingCreateProduct = false;
    },
    createProductFail: (state) => {
      state.loadingCreateProduct = false;
    },
    //
    getProductByIdRequest: (state, payload) => {
      state.edit = true;
    },
    productEditSuccess: (state, { payload }) => {
      state.product = payload;
      // state.productAttributesList = payload?.productAttributes;
    },
    productEditFail: (state) => {
      state.edit = false;
    },
    //
    updateProductRequest: (state, payload) => {
      state.loadingCreateProduct = true;
    },
    updateProductSuccess: (state) => {
      state.loadingCreateProduct = false;
    },
    updateProductFail: (state) => {
      state.loadingCreateProduct = false;
    },
    // product category
    loadingProductCategory: (state) => {
      state.loadingProductCategories = true;
    },
    getProductCategoryRequest: (state, payload) => {
      state.loadingProductCategories = true;
    },
    getProductCategorySuccess: (state, { payload: { results } }) => {
      state.loadingProductCategories = false;
      state.categories = results;
    },
    getProductCategoryFail: (state) => {
      state.loadingProductCategories = false;
    },
    //
    // product attributes
    loadingProductAttribute: (state) => {
      state.loadingProductAttributes = true;
    },
    getProductAttributeRequest: (state, payload) => {
      state.loadingProductAttributes = true;
    },
    getProductAttributeSuccess: (state, { payload: { results } }) => {
      state.loadingProductAttributes = false;
      state.productAttributesList = results;
    },
    getProductAttributeFail: (state) => {
      state.loadingProductAttributes = false;
    },
    // product variant attributes
    loadingProductVariantAttribute: (state) => {
      state.loadingProductVariantAttributes = true;
    },
    getProductVariantAttributeRequest: (state, payload) => {
      state.loadingProductVariantAttributes = true;
    },
    getProductVariantAttributeSuccess: (state, { payload: { results } }) => {
      state.loadingProductVariantAttributes = false;
      state.productVariantAttributesList = results;
    },
    getProductVariantAttributeFail: (state) => {
      state.loadingProductVariantAttributes = false;
    },
    //add delete and update product variant table properties
    handleDeleteProductVariants: (state, { payload }) => {
      //return variants except the id that is passed in payload
      console.log("running here", state?.product)
      console.log(payload,"what is payload");
      // state.prod
      // if (state?.product) {
      //   state.product.productVariants = state?.product?.productVariants?.filter(
      //     (variants: any) => variants?.id !== payload
      //   );
      //   console.log("the new product variant state", state.productVariants);
      // }
    },
    //
    clearProductData: (state) => {
      state.edit = false;
      state.product = null;
      state.productAttributesList = [];
      state.productVariants = [];
      state.productVariantAttributesList = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadingProduct,
  getProductRequest,
  getProductSuccess,
  getProductFail,
  //
  createProductRequest,
  createProductSuccess,
  createProductFail,
  //
  getProductByIdRequest,
  productEditSuccess,
  productEditFail,
  //
  getProductNextRequest,
  getProductPreviousRequest,
  //
  updateProductRequest,
  updateProductSuccess,
  updateProductFail,
  //product category
  loadingProductCategory,
  getProductCategoryRequest,
  getProductCategorySuccess,
  getProductCategoryFail,
  //product attribute
  loadingProductAttribute,
  getProductAttributeRequest,
  getProductAttributeSuccess,
  getProductAttributeFail,
  // product variant attributes
  loadingProductVariantAttribute,
  getProductVariantAttributeRequest,
  getProductVariantAttributeSuccess,
  getProductVariantAttributeFail,
  //product variant table slices
  handleDeleteProductVariants,
  //
  clearProductData,
} = Product.actions;

export default Product.reducer;
