import { createSlice } from "@reduxjs/toolkit";
import { customerInitialState } from "./types";

const initialState: customerInitialState = {
  count: 0,
  previous: "",
  next: "",
  customer: null,
  loadingCustomer: false,
  loadingCustomerDetails: false,
  loadingUpdateCustomerStatus: false,
  customers: [],
  customerDetails: null,
  loadingCreateCustomer: false,
  createdCustomerId: "",
  edit: false,
};

export const Customer = createSlice({
  name: "Customer Reducer",
  initialState,
  reducers: {
    getCustomerRequest: (state, payload) => {
      state.loadingCustomer = true;
    },
    getCustomerSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCustomer = false;
      state.customers = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCustomerFail: (state) => {
      state.loadingCustomer = false;
    },
    getCustomerNextRequest: (state, payload) => {
      state.loadingCustomer = true;
    },
    getCustomerPreviousRequest: (state, payload) => {
      state.loadingCustomer = true;
    },
    clearCustomerData: (state) => {
      state.customer = null;
      state.customerDetails = null;
    },
    // get order details
    getCustomerDetailsRequest: (state, payload) => {
      state.loadingCustomerDetails = true;
    },
    getCustomerDetailsSuccess: (state, { payload }) => {
      state.loadingCustomerDetails = false;
      state.customerDetails = payload;
    },
    getCustomerDetailsFail: (state) => {
      state.loadingCustomerDetails = false;
      state.customerDetails = null;
    },
    //
    updatecustomerStatusRequest: (state, payload) => {
      state.loadingUpdateCustomerStatus = true;
    },
    updatecustomerStatusSuccess: (state) => {
      state.loadingUpdateCustomerStatus = false;
    },
    updatecustomerStatusFail: (state) => {
      state.loadingUpdateCustomerStatus = false;
    },
    //
    createCustomerRequest: (state, payload) => {
      state.loadingCreateCustomer = true;
    },
    createCustomerSuccess: (state, { payload }) => {
      state.loadingCreateCustomer = false;
      //@ts-ignore
      state.createdCustomerId = payload?.response?.data?.id;
    },
    createCustomerFail: (state) => {
      state.loadingCreateCustomer = false;
    },
    //
    getCustomerByIdRequest: (state, { payload }) => {
      state.loadingCreateCustomer = false;
    },
    customerEditFail: (state) => {
      state.edit = false;
      state.customer = null;
    },
    customerEditSuccess: (state, { payload }) => {
      state.edit = true;
      state.customer = payload;
    },
    updateCustomerRequest: (state, payload) => {
      state.loadingCreateCustomer = true;
    },
    updateCustomerSuccess: (state) => {
      state.loadingCreateCustomer = false;
    },
    updateCustomerFail: (state) => {
      state.loadingCreateCustomer = false;
    },
  },
});

// Action creators generated for each case reducer function
export const {
  //get customer
  getCustomerRequest,
  getCustomerSuccess,
  getCustomerFail,
  //get customer next, previous
  getCustomerNextRequest,
  getCustomerPreviousRequest,
  //
  getCustomerDetailsRequest,
  getCustomerDetailsSuccess,
  getCustomerDetailsFail,
  //
  clearCustomerData,
  //
  updatecustomerStatusRequest,
  updatecustomerStatusFail,
  updatecustomerStatusSuccess,
  //
  createCustomerFail,
  createCustomerRequest,
  createCustomerSuccess,
  //edit customer
  getCustomerByIdRequest,
  customerEditFail,
  customerEditSuccess,
  //update customer
  updateCustomerRequest,
  updateCustomerSuccess,
  updateCustomerFail,
} = Customer.actions;

export default Customer.reducer;
