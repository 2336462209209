import {
  getGeneralFaqsRequest,
  getGeneralFaqsFail,
  getGeneralFaqsSuccess,
  createGeneralFaqsFail,
  createGeneralFaqsRequest,
  createGeneralFaqsSuccess,
  updateGeneralFaqsFail,
  updateGeneralFaqsSuccess,
  clearAllData,
  getGeneralFaqsNextRequest,
  getGeneralFaqsPreviousRequest,
  updateGeneralFaqsRequest,
  getGeneralFaqsByIdRequest,
  generalFaqsEditSuccess,
  generalFaqsEditFail,
} from "./generalFaqsSlice";
import { mergeMap } from "rxjs";
import { getGeneralFaqs, createGeneralFaqs, updateGeneralFaqs, getGeneralFaqsById } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get GeneralFaqs
const getGeneralFaqsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getGeneralFaqsRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getGeneralFaqs(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getGeneralFaqsSuccess(action?.payload) : getGeneralFaqsFail()
    )
  );

//get generalFaqs by id epic
const getGeneralFaqsByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getGeneralFaqsByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getGeneralFaqsById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? generalFaqsEditSuccess(action.payload) : generalFaqsEditFail()
    )
  );

//create
const createGeneralFaqsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createGeneralFaqsRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }

        const response = await createGeneralFaqs(body);
        if (response) {
          dispatch(getGeneralFaqsRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createGeneralFaqsSuccess() : createGeneralFaqsFail();
    })
  );

//update
const updateGeneralFaqsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateGeneralFaqsRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          body.append(`${key}`, String(value));
        }

        const response = await updateGeneralFaqs(body, id);
        if (response) {
          dispatch(getGeneralFaqsRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updateGeneralFaqsSuccess() : updateGeneralFaqsFail();
    })
  );

//get next
const getGeneralFaqsNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getGeneralFaqsNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getGeneralFaqsSuccess(action?.payload)
        : getGeneralFaqsFail();
    })
  );

//get previous
const getGeneralFaqsPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getGeneralFaqsPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getGeneralFaqsSuccess(action?.payload) : getGeneralFaqsFail()
    )
  );

export const generalFaqsEpic = combineEpics(
  getGeneralFaqsEpic,
  createGeneralFaqsEpic,
  updateGeneralFaqsEpic,
  getGeneralFaqsNext,
  getGeneralFaqsPrevious,
  getGeneralFaqsByIdEpic
);
