import { createSlice} from "@reduxjs/toolkit";
import { bankInitialState } from "./types";



const initialState: bankInitialState = {
    banks: [],
    edit: false,
    bank: null,
    loadingBank: false,
    loadingCreateBank: false,
    count: 0,
    previous: "",
    next: "",
}



export const Bank = createSlice({
    name: "bankReducer",
    initialState,
    reducers: {
        getBankRequest : (state, payload) => {
            state.loadingBank = true;
        },
        getBankSuccess: (state, { payload: {
            results,
            count,
            next,
            previous
         }}) => {
            state.loadingBank= false;
            state.banks = results;
            state.count = count;
            state.next = next;
            state.previous = previous;
        },
        getBankFail: (state) => {
            state.loadingBank = false;
        },
        
    getBankNextRequest: (state, payload) => {
      state.loadingBank = true;
    },
    getBankPreviousRequest: (state, payload) => {
      state.loadingBank = true;
    },
    createBankRequest: (state, payload) => {
        state.loadingCreateBank = true;
    },
    updateBankRequest: (state, payload) => {
        state.loadingCreateBank = true;
    },
    createBankSuccess: (state) => {
        state.loadingCreateBank = false;
    },
    createBankFail: (state) => {
        state.loadingCreateBank = false;
    },
    getBankByIdRequest: (state, payload) => {
        state.edit = true;
    },
    bankEditSuccess: (state, { payload}) => {
        state.bank = payload;
    },
    bankEditFail: (state) => {
        state.edit = false;
    },
    updateBankSuccess: (state) => {
        state.loadingCreateBank = false;
    },
    updateBankFail: (state) => {
        state.loadingCreateBank = false;
    },
    clearAllData: (state) => {
        state.edit = false;
        state.bank = null;
    }
    }
})

//action creaters generated for each case reducer
export const {
    getBankRequest,
    getBankFail,
    getBankSuccess,
    getBankNextRequest,
    getBankPreviousRequest,
    createBankFail,
    createBankRequest,
    createBankSuccess,
    bankEditSuccess,
    updateBankFail,
    updateBankSuccess,
    updateBankRequest,
    clearAllData,
    getBankByIdRequest,
    bankEditFail,
} = Bank.actions;

export default Bank.reducer;