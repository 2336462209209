// core setup permission constants
//----done
export const userGroupPermission = [
  "view_user_group",
  "add_user_group",
  "edit_user_group",
];
export const userPermission = ["view_user", "add_user", "edit_user"];
export const organizationPermission = [
  "view_organization_setup",
  "add_organization_setup",
  "edit_organization_setup",
];
/////----
export const organizationRulePermission = [
  "view_organization_rule",
  "add_organization_rule",
  "edit_organization_rule",
  "delete_organization_rule",
];
export const discountSchemePermission = [
  "view_discount_scheme",
  "add_discount_scheme",
  "edit_discount_scheme",
  "delete_discount_scheme",
];
export const additionalChargeTypePermission = [
  "view_additional_charge_type",
  "add_additional_charge_type",
  "edit_additional_charge_type",
  "delete_additional_charge_type",
];
export const bankPermission = [
  "view_bank",
  "add_bank",
  "edit_bank",
  "delete_bank",
];
export const paymentModePermission = [
  "view_payment_mode",
  "add_payment_mode",
  "edit_payment_mode",
  "delete_payment_mode",
];
export const taxGroupPermission = [
  "view_tax_group",
  "add_tax_group",
  "edit_tax_group",
  "delete_tax_group",
];
export const countryPermission = [
  "view_country",
  "add_country",
  "edit_country",
  "delete_country",
];
export const provincePermission = [
  "view_province",
  "add_province",
  "edit_province",
  "delete_province",
];
export const districtPermission = [
  "view_district",
  "add_district",
  "edit_district",
  "delete_district",
];
export const palikaPermission = [
  "view_palika",
  "add_palika",
  "edit_palika",
  "delete_palika",
];

export const customerTypePermission = [
  "view_customer_type",
  "add_customer_type",
  "edit_customer_type",
  "delete_customer_type",
];

export const customerEditPermission = [
  "view_customer",
  "edit_customer",
  "update_customer",
];
export const titlePermission = [
  "view_title",
  "add_title",
  "edit_title",
  "delete_title",
];
export const storePermission = [
  "view_store",
  "add_store",
  "edit_store",
  "delete_store",
];

export const wardPermission = [
  "view_ward",
  "add_ward",
  "edit_ward",
  "delete_ward",
];

export const purchaseOrderAcceptPermission = [
  "view_ward",
  "delete_ward",
];


