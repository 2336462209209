import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const countryModURL = 'api/v1/core-app/country';

//get Country data api
export const getCountry = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${countryModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get country by Id
export const getCountryById = (id: number) => {
  return axiosInstance.get(`${countryModURL}/${id}`);
};

//handle search
export const searchCountry = ({ rowsPerPage, search }: searchProps) => {
  return `${countryModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Country
export const createCountry = (body: string | FormData) =>
  axiosInstance.post(`${countryModURL}`, body);
//update Country
export const updateCountry = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${countryModURL}/${id}`, body);
