import { Card, CardContent, Divider, Grid, Chip, Avatar } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AppButton from "../AppElements/Button/AppButton";
import { useAppSelector } from "../../AppUtils/Utils/appHooks";
import {
  showModal,
  showReferenceRangeModal,
  updateInventorySidebar,
} from "../../Pages/CommonAppRedux/CommonAppSlice";
import { commonAppSelector } from "../../Pages/CommonAppRedux/selector";
import FixedAppBar from "./Header/FixedHeader";
import Header from "./Header/Header";
import SidebarMenu from "./Sidebar/SidebarMenu";
import { useStyles, Main } from "./Sidebar/SidebarStyles";
import AppTabs from "../AppTabs/Tabs";
import { masterSidebarData } from "./Sidebar/Master/MasterSidebarData";
import { productSidebarData } from "./Sidebar/Product/ProductSidebarData";
import { invSidebarData } from "./Sidebar/Inventory/InventorySidebarData";
import { WebsiteSidebarData } from "./Sidebar/Website/WebsiteSidebarData";
import { useImmer } from "use-immer";
import { handlePermission } from "../../AppUtils/Utils/permission";
import { loginSelector } from "../../Pages/Auth/Redux/selector";
interface layoutProps {
  children: React.ReactNode;
}
const Layout = ({ children }: layoutProps) => {
  const [openSidebar, setOpenSidebar] = useImmer({
    openInvSidebar: false,
    openMasterSidebar: false,
    openProductSidebar: false,
    openWebsiteSidebar: false,
  });

  const addRef = useRef<HTMLInputElement>(null);
  const {
    openMasterSidebar,
    openInvSidebar,
    openProductSidebar,
    openWebsiteSidebar,
  } = openSidebar;

  const {
    hideAddButton,
    hideTabAndAction,
    passPermission,
    AddInventoryIRDButton,
    hideHeader,
    globalOrganizationRules: rule,
  } = useAppSelector(commonAppSelector);
  const { permissions, isSuperuser } = useAppSelector(loginSelector);
  const [dataToBeLoad, setDataToBeLoad] = useImmer<any>([]);
  const dispatch = useDispatch();
  //function to check the active sidebar
  const isActiveSidebar = (name: string) => {
    return localStorage?.getItem("activeSidebar") === name;
  };

  useEffect(() => {
    // set active sidebar
    isActiveSidebar("inventory")
      ? setOpenSidebar((draft) => {
          draft.openInvSidebar = true;
        })
      : isActiveSidebar("product-setup")
      ? setOpenSidebar((draft) => {
          draft.openProductSidebar = true;
        })
      : isActiveSidebar("website-setup")
      ? setOpenSidebar((draft) => {
          draft.openWebsiteSidebar = true;
        })
      : setOpenSidebar((draft) => {
          draft.openMasterSidebar = true;
        });
  }, []);

  // update data to be load
  useEffect(() => {
    setDataToBeLoad(
      openMasterSidebar
        ? masterSidebarData
        : openProductSidebar
        ? productSidebarData
        : openWebsiteSidebar
        ? WebsiteSidebarData
        : invSidebarData
    );
  }, [
    openInvSidebar,
    openMasterSidebar,
    invSidebarData,
    productSidebarData,
    WebsiteSidebarData,
    setDataToBeLoad,
  ]);

  const [open, setOpen] = useState(true);

  const classes = useStyles();
  const handleClickOpen = () => {
    dispatch(showModal(true));
    dispatch(showReferenceRangeModal());
  };
  const handleUploadIRDSync = () => {
    // dispatch(postIRDSyncInventoryRequest());
  };
  //event stop default event
  // document.addEventListener("keydown", (e) => {
  //   if (
  //     e.key === "s" &&
  //     (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
  //   ) {
  //     e.preventDefault();
  //   }
  // });
  // document.addEventListener("keydown", (e) => {
  //   if (
  //     e.key === "p" &&
  //     (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
  //   ) {
  //     e.preventDefault();
  //   }
  // });
  //event
  useEffect(() => {
    const handleAddButton = (event: KeyboardEvent) => {
      if (event.ctrlKey && (event.key === "o" || event.key === "O")) {
        event.preventDefault(); // prev ent default behavior of saving the page
        addRef?.current?.click(); // click the button
      }
    };
    document.addEventListener("keydown", handleAddButton);
    return () => {
      document.removeEventListener("keydown", handleAddButton);
    };
  }, []);
  if (open === true) {
    const element = document?.getElementById("myMainMenu");
    element?.addEventListener("click", () => {
      setOpen(!rule?.collapseSideBar);
    });
  }
  return (
    <>
      <FixedAppBar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      <Header open={open} setOpen={setOpen} />
      <SidebarMenu open={open} dataToBeLoad={dataToBeLoad} setOpen={setOpen} />
      <Main open={open} id="myMainMenu">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ overflow: "inherit" }}>
              {!hideTabAndAction && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.cardHeader}
                >
                  <Grid item className={classes.tabs}>
                    <AppTabs dataToBeLoad={dataToBeLoad as any} />
                  </Grid>
                  {isSuperuser ||
                  handlePermission(
                    permissions,
                    passPermission?.add_permission
                  ) ? (
                    <>
                      {!hideAddButton && (
                        <>
                          <Grid item xs={1.2} lg={0.9}>
                            <AppButton
                              tooltipTitle="Add"
                              submit={true}
                              onClick={handleClickOpen}
                              autoFocus
                              reference={addRef}
                            >
                              Add
                            </AppButton>
                          </Grid>
                        </>
                      )}
                      {AddInventoryIRDButton && (
                        <>
                          <Grid item>
                            <AppButton
                              tooltipTitle="Upload IRD Sync"
                              submit={true}
                              onClick={handleUploadIRDSync}
                            >
                              Upload IRD Sync
                            </AppButton>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : null}
                </Grid>
              )}
              <Divider />
              <CardContent className={classes.cardContent}>
                {children}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Main>
    </>
  );
};

export default memo(Layout);
