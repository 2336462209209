import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const promoCodeURL = "api/v1/admin/promo-vouchers-app/promo-code";

//get general faqs
export const getPromoCode = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${promoCodeURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};

//get PromoCode by id
export const getPromoCodeById = (id: number) => {
  return axiosInstance.get(`${promoCodeURL}/${id}`);
};
//search PromoCode
export const searchPromoCode = ({ rowsPerPage, search }: searchProps) => {
  return `${promoCodeURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};
//update PromoCode
export const updatePromoCode = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${promoCodeURL}/${id}`, body);
//create PromoCode
export const createPromoCode = (body: string | FormData) =>
  axiosInstance.post(`${promoCodeURL}`, body);

//promo code archive
export const updatePromoCodeArchive = (body: FormData, id: number) => {
  return axiosInstance?.patch(`${promoCodeURL}/${id}`, body);
};

//get promo product
export const getPromoProduct = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${promoCodeURL}/products?offset=0&limit=${rowsPerPage}`
  );
};

//get promo customer
export const getPromoCustomer = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${promoCodeURL}/customers?offset=0&limit=${rowsPerPage}`
  );
};
