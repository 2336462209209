import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const provinceModURL = 'api/v1/core-app/province';

//get Province data api
export const getProvince = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${provinceModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get Province by Id
export const getProvinceById = (id: number) => {
  return axiosInstance.get(`${provinceModURL}/${id}`);
}


//handle search
export const searchProvince = ({ rowsPerPage, search }: searchProps) => {
  return `${provinceModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Province
export const createProvince = (body: string | FormData) =>
  axiosInstance.post(`${provinceModURL}`, body);
//update Province
export const updateProvince = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${provinceModURL}/${id}`, body);
