import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const albumURL = "api/v1/admin/website-setup-app/album";

//get ablum
export const getAlbum = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${albumURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};

//get album by id
export const getAlbumById = (id: number) => {
  return axiosInstance.get(`${albumURL}/${id}`);
};
//search album
export const searchAlbum = ({ rowsPerPage, search }: searchProps) => {
  return `${albumURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};
//update album
export const updateAlbum = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${albumURL}/${id}`, body);
//create album
export const createAlbum = (body: string | FormData) =>
  axiosInstance.post(`${albumURL}`, body);
