import { createAction, createSlice } from "@reduxjs/toolkit";
import {
  defaultPage,
  defaultRowsPerPage,
} from "../../AppUtils/Utils/globalConstant";
import { commonAppProps } from "./types";
const initialState: commonAppProps = {
  open: false,
  error: false,
  info: false,
  success: false,
  message: "",
  appType: "",
  rowsPerPage: defaultRowsPerPage,
  page: defaultPage,
  detailsRowsPerPage: defaultRowsPerPage,
  changeInDetailsRowsPerPage: false,
  detailsPage: defaultPage,
  showModal: false,
  showReferenceModal: false,
  search: "",
  selectedModuleIndex: 0,
  formikData: false,
  hideAddButton: false,
  AddClinicIRDButton: false,
  AddInventoryIRDButton: false,
  hideTabAndAction: false,
  hideSidebarInitially: false,
  showPurchaseModuleNote: false,
  passPermission: null,
  loadingGlobalOrganization: false,
  globalOrganizations: null,
  loadingGlobalOrganizationRule: false,
  globalOrganizationRules: null,
  buyerCaption: "",
  updatedInventorySideBar: [],
  hideHeader: false,
  showCloseIcon: false,
  hideSelectionType: false,
  isAddressTable: true,
};

export const getGlobalOrganizationRequest = createAction(
  "getGlobalOrganizationRequest",
  function prepare() {
    return {
      payload: "",
    };
  }
);
export const getGlobalOrganizationRuleRequest = createAction(
  "getGlobalOrganizationRuleRequest",
  function prepare() {
    return {
      payload: "",
    };
  }
);
//create request action
export const commonAppSlice = createSlice({
  name: "commonAppReducer",
  initialState,
  reducers: {
    alertSuccessAction: (state, { payload }) => {
      state.open = true;
      state.success = true;
      state.message = payload;
    },
    alertErrorAction: (state, { payload }) => {
      state.open = true;
      state.error = true;
      state.message = payload;
    },
    alertInfoAction: (state, { payload }) => {
      state.open = true;
      state.info = true;
      state.message = payload;
    },
    closeAlertAction: (state) => {
      state.open = false;
      state.info = false;
      state.success = false;
      state.error = false;
      state.message = "";
    },
    commonAppTypes: (
      state,
      {
        payload: {
          types,
          hideAddButton,
          hideTabAndAction,
          hideSidebarInitially,
          showPurchaseModuleNote,
          passPermission,
          AddClinicIRDButton,
          AddInventoryIRDButton,
          hideHeader,
          showCloseIcon,
          hideSelectionType,
          isAddressTable,
        },
      }
    ) => {
      state.passPermission = passPermission;
      state.appType = types;
      state.hideAddButton = hideAddButton;
      state.AddClinicIRDButton = AddClinicIRDButton;
      state.AddInventoryIRDButton = AddInventoryIRDButton;
      state.hideTabAndAction = hideTabAndAction;
      state.hideSidebarInitially = hideSidebarInitially;
      state.showPurchaseModuleNote = showPurchaseModuleNote;
      state.hideHeader = hideHeader;
      state.showCloseIcon = showCloseIcon;
      state.hideSelectionType = hideSelectionType;
      state.showCloseIcon = showCloseIcon;
      state.isAddressTable = isAddressTable;
    },
    commonAppFormikData: (state, { payload }) => {
      state.formikData = payload;
    },
    commonAppRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload;
    },
    commonAppPage: (state, { payload }) => {
      state.page = payload;
      state.changeInDetailsRowsPerPage = false;
    },
    clearSearchedData: (state) => {
      state.search = "";
      state.rowsPerPage = defaultRowsPerPage;
    },
    commonAppDetailsRowsPerPage: (state, { payload }) => {
      state.detailsRowsPerPage = payload;
      state.changeInDetailsRowsPerPage = true;
    },
    commonAppDetailsPage: (state, { payload }) => {
      state.detailsPage = payload;
    },
    clearDetailPage: (state) => {
      state.detailsPage = defaultPage;
      state.changeInDetailsRowsPerPage = false;
      state.detailsRowsPerPage = defaultRowsPerPage;
    },
    commonAppIndex: (state, { payload }) => {
      state.selectedModuleIndex = payload;
      // state.appType = "";
    },
    closeModal: (state) => {
      // state.appType.splice(-1, 1);
      state.showModal = false;
      // current(state.appType);
    },
    showModal: (state, { payload }) => {
      state.showModal = payload;
    },
    showReferenceRangeModal: (state) => {
      state.showReferenceModal = !state.showReferenceModal;
    },
    searchData: (state, { payload }) => {
      state.search = payload;
    },
    commonAppTypesClear: (state) => {
      state.appType = "";
    },
    //get global organization
    getGlobalOrganizationSuccess: (state, { payload: { results } }) => {
      state.loadingGlobalOrganization = false;
      state.globalOrganizations = results ? results[0] : null;
    },
    getGlobalOrganizationFail: (state) => {
      state.loadingGlobalOrganization = false;
    },
    getGlobalOrganizationRuleSuccess: (state, { payload: { results } }) => {
      state.loadingGlobalOrganizationRule = false;
      state.globalOrganizationRules = results ? results[0] : null;
      state.buyerCaption = results[0]?.buyerCaption ?? "Customer";
    },
    getGlobalOrganizationRuleFail: (state) => {
      state.loadingGlobalOrganizationRule = false;
    },
    // update the value of inventory side bar data
    updateInventorySidebar: (state, { payload }) => {
      state.updatedInventorySideBar = payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  commonAppTypes,
  closeModal,
  commonAppRowsPerPage,
  commonAppPage,
  commonAppDetailsRowsPerPage,
  commonAppDetailsPage,
  showModal,
  showReferenceRangeModal,
  alertSuccessAction,
  alertErrorAction,
  alertInfoAction,
  closeAlertAction,
  searchData,
  commonAppIndex,
  commonAppTypesClear,
  commonAppFormikData,
  clearSearchedData,
  //get global
  getGlobalOrganizationSuccess,
  getGlobalOrganizationFail,
  getGlobalOrganizationRuleSuccess,
  getGlobalOrganizationRuleFail,
  // clearCommonPagination,
  clearDetailPage,
  updateInventorySidebar,
} = commonAppSlice.actions;
//export reducer
export default commonAppSlice.reducer;
