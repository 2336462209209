import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    loadingUpdatePassword: false,
}

export const Password = createSlice({
    name: "PasswordReducer",
    initialState,
    reducers: {
        changePasswordRequest: (state, payload) => {
            state.loadingUpdatePassword = true;
        },
        changePasswordSuccess: (state) => {
            state.loadingUpdatePassword = false;
        },
        changePasswordFail: (state) => {
            state.loadingUpdatePassword = false;
        },
    },
});

export const {
    changePasswordRequest,
    changePasswordSuccess,
    changePasswordFail,
} = Password.actions;
export default Password.reducer;