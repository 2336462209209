import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const taxGroupModURL = 'api/v1/core-app/tax-group';

//get TaxGroup data api
export const getTaxGroup = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${taxGroupModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//get tax group by id
export const getTaxGroupById = (id: number) => {
  return axiosInstance.get(`${taxGroupModURL}/${id}`);
}
//handle search
export const searchTaxGroup = ({ rowsPerPage, search }: searchProps) => {
  return `${taxGroupModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create TaxGroup
export const createTaxGroup = (body: string | FormData) =>
  axiosInstance.post(`${taxGroupModURL}`, body);
//update TaxGroup
export const updateTaxGroup = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${taxGroupModURL}/${id}`, body);
