import {
  getFeaturedProductRequest,
  getFeaturedProductFail,
  getFeaturedProductSuccess,
  createFeaturedProductFail,
  createFeaturedProductRequest,
  createFeaturedProductSuccess,
  updateFeaturedProductFail,
  updateFeaturedProductSuccess,
  clearAllData,
  getFeaturedProductNextRequest,
  getFeaturedProductPreviousRequest,
  updateFeaturedProductRequest,
  getFeaturedProductByIdRequest,
  featuredProductEditSuccess,
  featuredProductEditFail,
} from "./featuredProductSlice";
import { mergeMap } from "rxjs";
import {
  getFeaturedProduct,
  createFeaturedProduct,
  updateFeaturedProduct,
  getFeaturedProductById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get FeaturedProduct
const getFeaturedProductEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedProductRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getFeaturedProduct(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFeaturedProductSuccess(action?.payload)
        : getFeaturedProductFail()
    )
  );

//get featuredProduct by id epic
const getFeaturedProductByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedProductByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getFeaturedProductById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? featuredProductEditSuccess(action.payload)
        : featuredProductEditFail()
    )
  );

//create
const createFeaturedProductEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createFeaturedProductRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { mainImage, image1, image2, image3, image4, ...restValues } =
        values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof mainImage !== "string") {
          body.append("mainImage", mainImage);
        }
        if (typeof image1 !== "string") {
          body.append("image1", image1);
        }
        if (typeof image2 !== "string") {
          body.append("image2", image2);
        }
        if (typeof image3 !== "string") {
          body.append("image3", image3);
        }
        if (typeof image4 !== "string") {
          body.append("image4", image4);
        }
        const response = await createFeaturedProduct(body);
        if (response) {
          dispatch(getFeaturedProductRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createFeaturedProductSuccess()
        : createFeaturedProductFail();
    })
  );

//update
const updateFeaturedProductEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateFeaturedProductRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      const { mainImage, image1, image2, image3, image4, ...restValues } =
        values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof mainImage !== "string") {
          body.append("mainImage", mainImage);
        }
        if (typeof image1 !== "string") {
          body.append("image1", image1);
        }
        if (typeof image2 !== "string") {
          body.append("image2", image2);
        }
        if (typeof image3 !== "string") {
          body.append("image3", image3);
        }
        if (typeof image4 !== "string") {
          body.append("image4", image4);
        }
        const response = await updateFeaturedProduct(body, id);
        if (response) {
          dispatch(getFeaturedProductRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateFeaturedProductSuccess()
        : updateFeaturedProductFail();
    })
  );

//get next
const getFeaturedProductNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedProductNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getFeaturedProductSuccess(action?.payload)
        : getFeaturedProductFail();
    })
  );

//get previous
const getFeaturedProductPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedProductPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getFeaturedProductSuccess(action?.payload)
        : getFeaturedProductFail()
    )
  );

export const featuredProductEpic = combineEpics(
  getFeaturedProductEpic,
  createFeaturedProductEpic,
  updateFeaturedProductEpic,
  getFeaturedProductNext,
  getFeaturedProductPrevious,
  getFeaturedProductByIdEpic
);
