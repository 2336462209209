import {
  getPromoCodeRequest,
  getPromoCodeFail,
  getPromoCodeSuccess,
  createPromoCodeFail,
  createPromoCodeRequest,
  createPromoCodeSuccess,
  updatePromoCodeFail,
  updatePromoCodeSuccess,
  clearAllData,
  getPromoCodeNextRequest,
  getPromoCodePreviousRequest,
  updatePromoCodeRequest,
  getPromoCodeByIdRequest,
  promoCodeEditSuccess,
  promoCodeEditFail,
  getPromoProductRequest,
  getPromoProductSuccess,
  getPromoProductFail,
  getPromoCustomerRequest,
  getPromoCustomerSuccess,
  getPromoCustomerFail,
} from "./promoCodeSlice";
import { mergeMap } from "rxjs";
import {
  getPromoCode,
  createPromoCode,
  updatePromoCode,
  getPromoCodeById,
  getPromoProduct,
  getPromoCustomer,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get Promo Code
const getPromoCodeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPromoCodeRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getPromoCode(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPromoCodeSuccess(action?.payload)
        : getPromoCodeFail()
    )
  );

//get PromoCode by id epic
const getPromoCodeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPromoCodeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPromoCodeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? promoCodeEditSuccess(action.payload)
        : promoCodeEditFail()
    )
  );

//create
const createPromoCodeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createPromoCodeRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await createPromoCode(body);
        if (response) {
          dispatch(getPromoCodeRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createPromoCodeSuccess() : createPromoCodeFail();
    })
  );

//update
const updatePromoCodeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updatePromoCodeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updatePromoCode(body, id);
        if (response) {
          dispatch(getPromoCodeRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updatePromoCodeSuccess() : updatePromoCodeFail();
    })
  );

//get next
const getPromoCodeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPromoCodeNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getPromoCodeSuccess(action?.payload)
        : getPromoCodeFail();
    })
  );

//get previous
const getPromoCodePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPromoCodePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPromoCodeSuccess(action?.payload)
        : getPromoCodeFail()
    )
  );

//get promo product
const getPromoProductEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPromoProductRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPromoProduct(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPromoProductSuccess(action?.payload)
        : getPromoProductFail()
    )
  );

//get promo customers
const getPromoCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPromoCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPromoCustomer(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPromoCustomerSuccess(action?.payload)
        : getPromoCustomerFail()
    )
  );

export const PromoCodeEpic = combineEpics(
  getPromoCodeEpic,
  createPromoCodeEpic,
  updatePromoCodeEpic,
  getPromoCodeNext,
  getPromoCodePrevious,
  getPromoCodeByIdEpic,
  getPromoProductEpic,
  getPromoCustomerEpic
);
