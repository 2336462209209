import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { defaultRowsPerPage } from "../../../../AppUtils/Utils/globalConstant";

export const saleReturnURL = "api/v1/admin/inventory-app/inv-sale/sale-return";

//get sale return data api
export const getSaleReturn = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${saleReturnURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//handle search
export const searchSaleReturn = ({ rowsPerPage, search }: searchProps) => {
  return `${saleReturnURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};

//create sale return
export const createSaleReturn = (body: string | FormData) =>
  axiosInstance.post(`${saleReturnURL}`, body);

//get sale customer
export const getSaleReturnCustomer = (customerId: number) =>
  axiosInstance.get(`${saleReturnURL}/customers?id=${customerId}`);

//get specific sales
export const getSpecificSales = (id: number) =>
  axiosInstance.get(`${saleReturnURL}/sale?customer=${id}&ordering=-id`);

//get all sales
export const getAllSales = () =>
  axiosInstance.get(`${saleReturnURL}/sale?ordering=-id`);

// get specific sale details
export const getSpecificSaleDetails = (id: number) =>
  axiosInstance.get(
    `${saleReturnURL}/sale-detail-for-return?sale_main=${id}&offset=0&limit=0`
  );

//get sale return payment modes
export const getSaleReturnPaymentModes = () =>
  axiosInstance.get(
    `${saleReturnURL}/payment-methods?offset=0&limit=${defaultRowsPerPage}`
  );

//get sale return additional charges
export const getSaleReturnAdditionalCharge = () =>
  axiosInstance.get(
    `${saleReturnURL}/additional-charges?offset=0&limit=${defaultRowsPerPage}`
  );

// get view details
export const getSaleReturnViewDetails = ({ id, rowsPerPage, page }: any) =>
  axiosInstance.get(
    `${saleReturnURL}/sale-details?sale_main=${id}&offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}`
  );

// get customer details
export const getSaleReturnDetails = (id: number) =>
  axiosInstance.get(`${saleReturnURL}/${id}`);
