import { createSlice, createAction } from "@reduxjs/toolkit";
import { UserWiseReportInitialState } from "./types";
const initialState: UserWiseReportInitialState = {
  users: [],
  userWiseDetails: [],
  //loading 
  loadingGenerateReport: false,
  loadingUserWiseReportUser: false,
  loadingUserWiseReportDetail: false,
  count: 0,
  previous: "",
  next: "",
};

// export const getUserWiseReportDetailRequest = createAction(
//   "getUserWiseReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const UserWiseReport = createSlice({
  name: "UserWiseReportReducer",
  initialState,
  reducers: {
    //loading
    loadingUserWiseReportDetail: (state) => {
      state.loadingUserWiseReportDetail = true;
    },
    //external reducer request
    getUserWiseReportDetailRequest: (state, { payload }) => {
      state.loadingUserWiseReportDetail = true;
    },
    getUserWiseReportUserRequest: (state) => {
      state.loadingUserWiseReportUser = true;
    },
    getUserWiseReportAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getUserWiseReportAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getUserWiseReportQuickSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getUserWiseReportQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getUserWiseReportNextRequest: (state, payload) => {
      state.loadingUserWiseReportDetail = true;
    },
    getUserWiseReportPreviousRequest: (state, payload) => {
      state.loadingUserWiseReportDetail = true;
    },
    //external get reducer  
    getUserWiseReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.userWiseDetails = results;
      state.loadingUserWiseReportDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUserWiseReportDetailFail: (state) => {
      state.loadingUserWiseReportDetail = false;
    },
    getUserWiseReportUserSuccess: (state, { payload: { results } }) => {
      state.loadingUserWiseReportUser = false;
      state.users = results;
    },
    getUserWiseReportUserFail: (state) => {
      state.loadingUserWiseReportUser = false;
    },
    getUserWiseReportAllSummarySuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.userWiseDetails = results;
    },
    getUserWiseReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getUserWiseReportQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.userWiseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUserWiseReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getUserWiseReportAllDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.userWiseDetails = results;
    },
    getUserWiseReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getUserWiseReportQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.userWiseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUserWiseReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearUserWiseReportData: (state) => {
      state.users = [];
    },
    clearPrintUserActivityLogData: (state) => {
      state.userWiseDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getUserWiseReportDetailRequest,
  getUserWiseReportUserRequest,
  getUserWiseReportAllSummaryRequest,
  getUserWiseReportAllDetailRequest,
  getUserWiseReportQuickSummaryRequest,
  getUserWiseReportQuickDetailRequest,
  getUserWiseReportPreviousRequest,
  getUserWiseReportNextRequest,
  //reducer get data
  getUserWiseReportDetailSuccess,
  getUserWiseReportDetailFail,
  getUserWiseReportUserSuccess,
  getUserWiseReportUserFail,
  getUserWiseReportQuickDetailSuccess,
  getUserWiseReportQuickDetailFail,
  getUserWiseReportAllDetailSuccess,
  getUserWiseReportAllDetailFail,
  getUserWiseReportQuickSummarySuccess,
  getUserWiseReportQuickSummaryFail,
  getUserWiseReportAllSummarySuccess,
  getUserWiseReportAllSummaryFail,
  //loading
  loadingUserWiseReportDetail,
  //clear
  clearUserWiseReportData,
  clearPrintUserActivityLogData,
} = UserWiseReport.actions;
export default UserWiseReport.reducer;
