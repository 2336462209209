import { createSlice } from "@reduxjs/toolkit";
import { additionalChargeDetails, paymentDetails } from "../types";
const initialState: any = {
  paymentDetails: [],
  loadingPurchasePaymentMode: false,
  edit: false,
  paymentDetail: null,
  //addiotnal charge type
  additionalChargeTypeDetails: [],
  editAdditionalChargeType: false,
  additionalChargeType: null,
};

// purchase slice
export const PaymentDetails = createSlice({
  name: "paymentDetailsReducer",
  initialState,
  reducers: {
    addPaymentDetails: (state, { payload }) => {
      state.paymentDetails = [...state.paymentDetails, payload];
      state.purchaseMasterData = payload;
      state.edit = state.edit && !state.edit;
    },
    updatePaymentDetails: (state, { payload }) => {
      state.paymentDetails = [payload];
    },
    deletePaymentDetails: (state, { payload }) => {
      state.paymentDetails = state.paymentDetails?.filter(
        (details: paymentDetails) => details?.uniqueId !== payload
      );
    },
    handleEditSuccess: (state, { payload }) => {
      state.edit = true;
      state.paymentDetail = payload;
      state.paymentDetails = state.paymentDetails?.filter(
        (details: paymentDetails) => details?.uniqueId !== payload?.uniqueId
      );
    },
    clearPaymentDetails: (state) => {
      state.paymentDetails = [];
    },
    //additonal charge type
    addAdditionalChargeTypeDetails: (state, { payload }) => {
      state.additionalChargeTypeDetails = [
        ...state.additionalChargeTypeDetails,
        payload,
      ];
      state.purchaseMasterData = payload;
      state.editAdditionalChargeType =
        state.editAdditionalChargeType && !state.editAdditionalChargeType;
    },
    deleteAdditionalChargeTypeDetails: (state, { payload }) => {
      state.additionalChargeTypeDetails =
        state.additionalChargeTypeDetails?.filter(
          (details: additionalChargeDetails) => details?.uniqueId !== payload
        );
    },
    handleAdditionalChargeTypeEditSuccess: (state, { payload }) => {
      state.editAdditionalChargeType = true;
      state.additionalChargeType = payload;
      state.additionalChargeTypeDetails =
        state.additionalChargeTypeDetails?.filter(
          (details: additionalChargeDetails) =>
            details?.uniqueId !== payload?.uniqueId
        );
    },
    clearAdditionalChargeTypeDetails: (state) => {
      state.additionalChargeTypeDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  addPaymentDetails,
  deletePaymentDetails,
  handleEditSuccess,
  clearPaymentDetails,
  //additional charge type
  addAdditionalChargeTypeDetails,
  deleteAdditionalChargeTypeDetails,
  handleAdditionalChargeTypeEditSuccess,
  clearAdditionalChargeTypeDetails,
  // update payment details
  updatePaymentDetails,
} = PaymentDetails.actions;
export default PaymentDetails.reducer;
