import { Fragment, memo, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import Sidebar from "./";
import logo from "../../../../src/Assets/logo.png";
import "./Sidebar.css";
import {
  Drawer,
  Search,
  SearchIconWrapper,
  StyledInputBase,
  useStyles,
} from "./SidebarStyles";
import { commonAppSelector } from "../../../Pages/CommonAppRedux/selector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../AppUtils/Utils/appHooks";
import { loginSelector } from "../../../Pages/Auth/Redux/selector";
import {
  getGlobalOrganizationRequest,
  getGlobalOrganizationRuleRequest,
} from "../../../Pages/CommonAppRedux/CommonAppSlice";

const SidebarMenu = ({ open, dataToBeLoad, setOpen }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { hideSidebarInitially } = useAppSelector(commonAppSelector);
  const { globalOrganizations } = useAppSelector(commonAppSelector);
  const { permissions, isSuperuser } = useAppSelector(loginSelector);
  // let organizations: any = [];
  const [search, setSearch] = useState("");
  useEffect(() => {
    // dispatch(getGlobalOrganizationRequest());
    dispatch(getGlobalOrganizationRuleRequest());
    if (hideSidebarInitially) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [hideSidebarInitially]);
  const { globalOrganizationRules: rule } = useAppSelector(commonAppSelector);
  useEffect(() => {
    setOpen(!rule?.collapseSideBar);
  }, []);
  useEffect(() => {
    return () => {
      setSearch("");
    };
  }, [open]);

  return (
    <Drawer variant="permanent" open={open} className="sidebarDrawer">
      <div className={classes.toolbarIcon}>
        <div className="sidebar_logo">
          <img
            src={
              globalOrganizations?.logoMain
                ? String(globalOrganizations?.logoMain)
                : logo
            }
            alt=""
            className={open ? "fullLogo" : "shrinkLogo"}
          />
          {open && (
            <Typography variant="h6">
              {globalOrganizations?.displayName
                ? globalOrganizations?.displayName
                : "GIMS"}
            </Typography>
          )}
        </div>
        <Divider />
        <div>
          {open && (
            <div className={classes.searchSidebar}>
              <Search className="search-wrapper-sidebar">
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <SearchIconWrapper className="sidebar-search-icon-wrapper">
                  <SearchIcon style={{ color: "#f2f2f2" }} />
                </SearchIconWrapper>
              </Search>
            </div>
          )}
        </div>
        <Divider />
      </div>

      <div className="sidebarListWrapper">
        <List component="nav" className={classes.sidebarList} disablePadding>
          {dataToBeLoad?.map((item: any, index: number) => {
            let menu = item?.items
              ?.reduce((acc: any, arr: any) => acc?.concat(arr), [])
              ?.reduce(
                (acc: any, arr: any) => acc?.concat(arr?.permission),
                []
              );
            let displayMenu = permissions?.some(
              (element: any) => menu?.indexOf(element.codeName) !== -1
            );
            return isSuperuser || displayMenu ? (
              <Fragment key={index}>
                <Sidebar
                  {...item}
                  key={index}
                  search={search}
                  setSearch={setSearch}
                  className={classes.ListItemhover}
                />
              </Fragment>
            ) : (
              <Fragment key={index}></Fragment>
            );
          })}
        </List>
      </div>
      <Divider />
    </Drawer>
  );
};

export default memo(SidebarMenu);
