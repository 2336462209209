export const productCategoryPermission = [
  "view_category",
  "add_category",
  "edit_category",
];

export const productPermission = [
  "view_product",
  "add_product",
  "edit_product",
];
