import { createAction, createSlice } from "@reduxjs/toolkit";
import { branch } from "./types";
import setCookie from "../../../AppUtils/Utils/Cookies/setCookie";
import deleteCookie from "../../../AppUtils/Utils/Cookies/deleteCookie";

export interface AuthState {
  isAuthenticated: boolean;
  loadingAuth: boolean;
  branches: branch[];
  userName: string;
  userId: number | null;
  authError: boolean;
  isSuperuser: boolean;
  img: string | null;
  open: boolean;
  message: string;
  permissions: any;
}
const initialState: AuthState = {
  isAuthenticated: false,
  loadingAuth: false,
  branches: [],
  permissions: [],
  userName: "",
  userId: null,
  authError: false,
  isSuperuser: false,
  img: null,
  open: false,
  message: "",
};

export const loginRequest = createAction(
  "loginRequest",
  function prepare(body) {
    return {
      payload: body,
    };
  }
);
export const getBranchRequest = createAction(
  "getBranchRequest",
  function prepare() {
    return {
      payload: {},
    };
  }
);

export const getLoginRequest = createAction("loginRequest");
// export const getLoginRequestFail = createAction("loginRequestFail");
const AuthReferral = createSlice({
  name: "authReferralSlice",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      setCookie("accessTokenReferral", action.payload.tokens.access, {
        secure: true,
        "max-age": 300,
      });
      setCookie("refreshTokenReferral", action.payload.tokens.refresh, {
        secure: true,
        "max-age": 3600000,
      });
      setCookie("isAuthenticatedReferral", true, { secure: true, "max-age": 3600000 });
      state.loadingAuth = true;
      state.userId = action?.payload?.userId;
      state.isAuthenticated = true;
      state.userName = action.payload.userName;
      state.isSuperuser = action?.payload?.isSuperuser;
      state.authError = false;
      state.permissions = action?.payload?.group?.permissions;
      state.img = action.payload.photo;
    },
    loginFail: (state) => {
      deleteCookie("refreshTokenReferral");
      deleteCookie("accessTokenReferral");
      deleteCookie("isAuthenticatedReferral");
      state.loadingAuth = false;
      state.userId = null;
      state.isAuthenticated = false;
      state.userName = "";
      state.isSuperuser = false;
      state.authError = true;
      state.permissions = [];
      state.img = null;
    },
    getReferralBranchesSuccess: (state, { payload }) => {
      state.branches = payload;
    },
    getReferralBranchesFail: (state) => {},
  },
});
export const { loginSuccess, loginFail,getReferralBranchesSuccess,getReferralBranchesFail } =
  AuthReferral.actions;
export default AuthReferral.reducer;
