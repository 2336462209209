import { createSlice } from "@reduxjs/toolkit";
import { featuredProductInitialState } from "./types";

const initialState: featuredProductInitialState = {
  featuredProducts: [],
  edit: false,
  featuredProduct: null,
  loadingFeaturedProduct: false,
  loadingCreateFeaturedProduct: false,
  count: 0,
  previous: "",
  next: "",
};

export const FeaturedProduct = createSlice({
  name: "featuredProductReducer",
  initialState,
  reducers: {
    getFeaturedProductRequest: (state, payload) => {
      state.loadingFeaturedProduct = true;
    },
    getFeaturedProductSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingFeaturedProduct = false;
      state.featuredProducts = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getFeaturedProductFail: (state) => {
      state.loadingFeaturedProduct = false;
    },

    getFeaturedProductNextRequest: (state, payload) => {
      state.loadingFeaturedProduct = true;
    },
    getFeaturedProductPreviousRequest: (state, payload) => {
      state.loadingFeaturedProduct = true;
    },
    createFeaturedProductRequest: (state, payload) => {
      state.loadingCreateFeaturedProduct = true;
    },
    updateFeaturedProductRequest: (state, payload) => {
      state.loadingCreateFeaturedProduct = true;
    },
    createFeaturedProductSuccess: (state) => {
      state.loadingCreateFeaturedProduct = false;
    },
    createFeaturedProductFail: (state) => {
      state.loadingCreateFeaturedProduct = false;
    },
    getFeaturedProductByIdRequest: (state, payload) => {
      state.edit = true;
    },
    featuredProductEditSuccess: (state, { payload }) => {
      state.featuredProduct = payload;
    },
    featuredProductEditFail: (state) => {
      state.edit = false;
    },
    updateFeaturedProductSuccess: (state) => {
      state.loadingCreateFeaturedProduct = false;
    },
    updateFeaturedProductFail: (state) => {
      state.loadingCreateFeaturedProduct = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.featuredProduct = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getFeaturedProductRequest,
  getFeaturedProductFail,
  getFeaturedProductSuccess,
  getFeaturedProductNextRequest,
  getFeaturedProductPreviousRequest,
  createFeaturedProductFail,
  createFeaturedProductRequest,
  createFeaturedProductSuccess,
  featuredProductEditSuccess,
  updateFeaturedProductFail,
  updateFeaturedProductSuccess,
  updateFeaturedProductRequest,
  clearAllData,
  getFeaturedProductByIdRequest,
  featuredProductEditFail,
} = FeaturedProduct.actions;

export default FeaturedProduct.reducer;
