import {
  getOurTeamRequest,
  getOurTeamFail,
  getOurTeamSuccess,
  createOurTeamFail,
  createOurTeamRequest,
  createOurTeamSuccess,
  updateOurTeamFail,
  updateOurTeamSuccess,
  clearAllData,
  getOurTeamNextRequest,
  getOurTeamPreviousRequest,
  updateOurTeamRequest,
  getOurTeamByIdRequest,
  ourTeamEditSuccess,
  ourTeamEditFail,
} from "./ourTeamSlice";
import { mergeMap } from "rxjs";
import {
  getOurTeam,
  createOurTeam,
  updateOurTeam,
  getOurTeamById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get OurTeam
const getOurTeamEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOurTeamRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getOurTeam(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOurTeamSuccess(action?.payload)
        : getOurTeamFail()
    )
  );

//get ourTeam by id epic
const getOurTeamByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOurTeamByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getOurTeamById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? ourTeamEditSuccess(action.payload)
        : ourTeamEditFail()
    )
  );

//create
const createOurTeamEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createOurTeamRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { profileImage, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof profileImage !== "string") {
          body.append("profileImage", profileImage);
        }
        const response = await createOurTeam(body);
        if (response) {
          dispatch(getOurTeamRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createOurTeamSuccess()
        : createOurTeamFail();
    })
  );

//update
const updateOurTeamEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateOurTeamRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      const { profileImage, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof profileImage !== "string") {
          body.append("profileImage", profileImage);
        }
        const response = await updateOurTeam(body, id);
        if (response) {
          dispatch(getOurTeamRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? updateOurTeamSuccess()
        : updateOurTeamFail();
    })
  );

//get next
const getOurTeamNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOurTeamNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getOurTeamSuccess(action?.payload)
        : getOurTeamFail();
    })
  );

//get previous
const getOurTeamPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOurTeamPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOurTeamSuccess(action?.payload)
        : getOurTeamFail()
    )
  );

export const ourTeamEpic = combineEpics(
  getOurTeamEpic,
  createOurTeamEpic,
  updateOurTeamEpic,
  getOurTeamNext,
  getOurTeamPrevious,
  getOurTeamByIdEpic
);
