import { createSlice } from "@reduxjs/toolkit";
import { ourTeamInitialState } from "./types";

const initialState: ourTeamInitialState = {
  ourTeams: [],
  edit: false,
  ourTeam: null,
  loadingOurTeam: false,
  loadingCreateOurTeam: false,
  count: 0,
  previous: "",
  next: "",
};

export const OurTeam = createSlice({
  name: "ourTeamReducer",
  initialState,
  reducers: {
    getOurTeamRequest: (state, payload) => {
      state.loadingOurTeam = true;
    },
    getOurTeamSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingOurTeam = false;
      state.ourTeams = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getOurTeamFail: (state) => {
      state.loadingOurTeam = false;
    },

    getOurTeamNextRequest: (state, payload) => {
      state.loadingOurTeam = true;
    },
    getOurTeamPreviousRequest: (state, payload) => {
      state.loadingOurTeam = true;
    },
    createOurTeamRequest: (state, payload) => {
      state.loadingCreateOurTeam = true;
    },
    updateOurTeamRequest: (state, payload) => {
      state.loadingCreateOurTeam = true;
    },
    createOurTeamSuccess: (state) => {
      state.loadingCreateOurTeam = false;
    },
    createOurTeamFail: (state) => {
      state.loadingCreateOurTeam = false;
    },
    getOurTeamByIdRequest: (state, payload) => {
      state.edit = true;
    },
    ourTeamEditSuccess: (state, { payload }) => {
      state.ourTeam = payload;
    },
    ourTeamEditFail: (state) => {
      state.edit = false;
    },
    updateOurTeamSuccess: (state) => {
      state.loadingCreateOurTeam = false;
    },
    updateOurTeamFail: (state) => {
      state.loadingCreateOurTeam = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.ourTeam = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getOurTeamRequest,
  getOurTeamFail,
  getOurTeamSuccess,
  getOurTeamNextRequest,
  getOurTeamPreviousRequest,
  createOurTeamFail,
  createOurTeamRequest,
  createOurTeamSuccess,
  ourTeamEditSuccess,
  updateOurTeamFail,
  updateOurTeamSuccess,
  updateOurTeamRequest,
  clearAllData,
  getOurTeamByIdRequest,
  ourTeamEditFail,
} = OurTeam.actions;

export default OurTeam.reducer;
