//package imports
import { combineEpics } from "redux-observable";
import { Action } from "@reduxjs/toolkit";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";
import { mergeMap } from "rxjs";

import {
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFail,
} from "./passwordSlice";
import { changePassword } from "./api";
import {
  alertErrorAction,
  alertSuccessAction,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import { loginFail } from "../../Redux/authSlice";

//change password epic
const changePasswordEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(changePasswordRequest.match),
    mergeMap(async ({ payload: { id, values, history } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await changePassword(body, id);
        dispatch(alertSuccessAction(response?.data?.message));
        response && dispatch(loginFail());
        history.push("/");
        return { payload: response.data };
      } catch (e: any) {
        dispatch(alertErrorAction(e?.response?.data?.message[0]));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? changePasswordSuccess() : changePasswordFail()
    )
  );

export const passwordEpics = combineEpics(changePasswordEpic);
