import { createSlice } from "@reduxjs/toolkit";
import { bannerInitialState } from "./types";

const initialState: bannerInitialState = {
  banners: [],
  edit: false,
  banner: null,
  loadingBanner: false,
  loadingCreateBanner: false,
  count: 0,
  previous: "",
  next: "",
};

export const Banner = createSlice({
  name: "bannerReducer",
  initialState,
  reducers: {
    getBannerRequest: (state, payload) => {
      state.loadingBanner = true;
    },
    getBannerSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingBanner = false;
      state.banners = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getBannerFail: (state) => {
      state.loadingBanner = false;
    },

    getBannerNextRequest: (state, payload) => {
      state.loadingBanner = true;
    },
    getBannerPreviousRequest: (state, payload) => {
      state.loadingBanner = true;
    },
    createBannerRequest: (state, payload) => {
      state.loadingCreateBanner = true;
    },
    updateBannerRequest: (state, payload) => {
      state.loadingCreateBanner = true;
    },
    createBannerSuccess: (state) => {
      state.loadingCreateBanner = false;
    },
    createBannerFail: (state) => {
      state.loadingCreateBanner = false;
    },
    getBannerByIdRequest: (state, payload) => {
      state.edit = true;
    },
    bannerEditSuccess: (state, { payload }) => {
      state.banner = payload;
    },
    bannerEditFail: (state) => {
      state.edit = false;
    },
    updateBannerSuccess: (state) => {
      state.loadingCreateBanner = false;
    },
    updateBannerFail: (state) => {
      state.loadingCreateBanner = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.banner = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getBannerRequest,
  getBannerFail,
  getBannerSuccess,
  getBannerNextRequest,
  getBannerPreviousRequest,
  createBannerFail,
  createBannerRequest,
  createBannerSuccess,
  bannerEditSuccess,
  updateBannerFail,
  updateBannerSuccess,
  updateBannerRequest,
  clearAllData,
  getBannerByIdRequest,
  bannerEditFail,
} = Banner.actions;

export default Banner.reducer;
