import {
  createDistrictFail,
  createDistrictRequest,
  createDistrictSuccess,
  getDistrictFail,
  getDistrictSuccess,
  loadingDistrict,
  updateDistrictFail,
  updateDistrictSuccess,
  getDistrictRequest,
  updateDistrictRequest,
  getDistrictNextRequest,
  getDistrictPreviousRequest,
  clearDistrictData,
  getDistrictByIdRequest,
  districtEditSuccess,
  districtEditFail,
  getDistrictProvinceRequest,
  getDistrictProvinceSuccess,
  getDistrictProvinceFail,
} from "./districtSlice";
import { mergeMap } from "rxjs";
import { createDistrict, getDistrict, getDistrictById, getDistrictProvince, updateDistrict } from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get District epic
const getDistrictEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDistrictRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingDistrict());
      try {
        const response = await getDistrict(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getDistrictSuccess(action?.payload) : getDistrictFail()
    )
  );

//get District by Id Epic
const getDistrictByIdEpic = (
  action$: Observable<Action>,
  _:stateAction,
  {dispatch } : dispatchAction
) => action$.pipe(
  filter(getDistrictByIdRequest.match),
  mergeMap(async (action) => {
    try {
      const response = await getDistrictById(action.payload);
      return { payload: response.data};
    } catch (e) {
      return { error: e};
    }
  }),
  map((action) => action?.payload ? districtEditSuccess(action?.payload) : districtEditFail())
)

//get district province
const getDistrictProvinceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) => action$.pipe(
  filter(getDistrictProvinceRequest.match),
  mergeMap(async (action) => {
    try {
      const response = await getDistrictProvince();
      return { payload: response.data};
    } catch (e) {
      return { error: e};
    }
  }),
  map((action) => action?.payload ? getDistrictProvinceSuccess(action?.payload) : getDistrictProvinceFail())
);
 
//get next
const getDistrictNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDistrictNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingDistrict());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getDistrictSuccess(action?.payload) : getDistrictFail()
    )
  );
//get previous
const getDistrictPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDistrictPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingDistrict());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getDistrictSuccess(action?.payload) : getDistrictFail()
    )
  );
//create District epic
const createDistrictEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createDistrictRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { name, province, active } = values;
      try {
        const body = JSON.stringify({ name, province, active });
        const response = await createDistrict(body);
        if (response) {
          dispatch(getDistrictRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createDistrictSuccess() : createDistrictFail();
    })
  );
//update District epic
const updateDistrictEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateDistrictRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, province, active, remarks } = values;
      try {
        const body = JSON.stringify({ name, province, active, remarks });
        const response = await updateDistrict(body, id);
        if (response) {
          dispatch(getDistrictRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearDistrictData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateDistrictSuccess() : updateDistrictFail()
    )
  );

//

export const districtEpics = combineEpics(
  getDistrictEpic,
  createDistrictEpic,
  updateDistrictEpic,
  getDistrictNext,
  getDistrictPrevious,
  getDistrictByIdEpic,
  getDistrictProvinceEpic,
);
