import {
  productCategoryPermission,
  productPermission,
} from "./ProductPermissionConstants";
import userIcon from "../../../../Assets/icons/CoreSetUpIcons/user_setup.svg";
import user from "../../../../Assets/icons/CoreSetupMidIcons/user.svg";

export const productSidebarData = [
  {
    name: "Products",
    customIcon: userIcon,
    items: [
      {
        name: "Category",
        image: user,
        link: "/product-setup/product-category",
        permission: productCategoryPermission,
      },
      {
        name: "Product",
        image: user,
        link: "/product-setup/product",
        permission: productPermission,
      },
    ],
  },
];
