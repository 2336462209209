import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { purchaseReport } from "./types";

const purchaseReportModURL = '/api/v1/inventory-financial-report-app/purchase-report';


export const getPurchaseReportSupplier = () =>
axiosInstance.get(`${purchaseReportModURL}/supplier?offset=0&limit=${0}`);
export const getPurchaseReportUser = () =>
axiosInstance.get(`${purchaseReportModURL}/user?offset=0&limit=${0}`);
export const getPurchaseReportItem = () =>
axiosInstance.get(`${purchaseReportModURL}/item?offset=0&limit=${0}`);
export const getPurchaseReportAllReport = (data:any) =>{
  const {updatedValue} = data;

  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${purchaseReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&created_by=${user}&purchase_type=${purchaseType}&supplier=${supplier}&item=${item}&date_after=${startDateAd}&date_before=${endDateAd}`
  )};
export const getPurchaseReportQuickReport = (data: any) => {
  const { rowsPerPage, page ,updatedValue} = data;
  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${purchaseReportModURL}/${summary?"summary":"detail"}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&created_by=${user}&purchase_type=${purchaseType}&supplier=${supplier}&item=${item}&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};

export const searchPurchaseReport = ({ rowsPerPage, search ,updatedValue}: any) => {
  const {
    item,
    summary,
    detail,
    supplier,
    user,
    purchaseType,
    startDateAd,
    endDateAd,
  } = updatedValue;

  return `${purchaseReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${rowsPerPage}&search=${search}&created_by=${user}&purchase_type=${purchaseType}&supplier=${supplier}&item=${item}&date_after=${startDateAd}&date_before=${endDateAd}`;
};