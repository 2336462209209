import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { defaultRowsPerPage } from "../../../../AppUtils/Utils/globalConstant";
export const purchaseReturnURL =
  "api/v1/inventory-purchase-app/purchase-return";
//get purchase return data api
export const getPurchaseReturn = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${purchaseReturnURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchPurchaseReturn = ({ rowsPerPage, search }: searchProps) => {
  return `api/v1/purchase-return-app/purchase return?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create purchase return
export const createPurchaseReturn = (body: string | FormData) =>
  axiosInstance.post(`${purchaseReturnURL}`, body);
//get purchase supplier
export const getPurchaseReturnSupplier = (rowsPerPage: number) =>
  axiosInstance.get(
    `${purchaseReturnURL}/supplier?offset=0&limit=${rowsPerPage}`
  );
//get purchase item
export const getPurchaseReturnItem = (rowsPerPage: number) =>
  axiosInstance.get(`${purchaseReturnURL}/item?offset=0&limit=${rowsPerPage}`);
//get specific purchases
export const getSpecificPurchases = (id: number) =>
  axiosInstance.get(
    `${purchaseReturnURL}/purchase?supplier=${id}&ordering=-id`
  );
// get specific purchase details
export const getSpecificPurchaseDetails = (id: number) =>
  axiosInstance.get(
    `${purchaseReturnURL}/purchase-detail-for-return?purchase_main=${id}&offset=0&limit=0`
  );
// get selected packing type detail
export const getSelectedPackingTypeDetail = (id: number) =>
  axiosInstance.get(`${purchaseReturnURL}/packing-type-detail?item=${id}`);
//get purchase return payment modes
export const getPurchaseReturnPaymentModes = () =>
  axiosInstance.get(
    `${purchaseReturnURL}/payment-mode?offset=0&limit=${defaultRowsPerPage}`
  );
// get purchase return details
export const getPurchaseReturnViewDetails = (data: getApiProps) => {
  const { rowsPerPage, page, id } = data;
  return axiosInstance.get(
    `${purchaseReturnURL}/purchase-detail?purchase_main=${id}&offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}`
  );
};
//additional charge
export const getPurchaseReturnAdditionalCharge = () =>
  axiosInstance.get(
    `${purchaseReturnURL}/additional-charge-type?offset=0&limit=0`
  );
