import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const customerModURL = "api/v1/admin/website-customer-app";

//GET Website Customers
export const getCustomer = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${customerModURL}/customers?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//GET Website Customer Detail
export const getcustomerDetails = (id: number) => {
  return axiosInstance.get(`${customerModURL}/customer/${id}`);
};

//handle search
export const searchCustomer = ({ rowsPerPage, search }: searchProps) => {
  return `${customerModURL}/customers?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};

// Update Customer Status
export const updateCustomerStatus = (body: string, id: number) => {
  return axiosInstance.patch(
    `${customerModURL}/customer/update-account-status/${id}`,
    body
  );
};

// Update Customer Status
export const verifyCustomer = (body: string, id: number) => {
  return axiosInstance.patch(
    `${customerModURL}/customer/verify-account/${id}`,
    body
  );
};

//get customer by id
export const getCustomerById = (id: number) => {
  return axiosInstance.get(`${customerModURL}/${id}`);
};

// create customer
export const createCustomer = (body: string | FormData) => {
  return axiosInstance.post(`api/v1/admin/inventory-app/inv-sale/sale/customer-register`, body);
};

//update customer
export const updateCustomer = (body: string | FormData, id: number) => {
  return axiosInstance.patch(`${customerModURL}/${id}`, body);
};
