import { Switch } from "react-router-dom";
import {
  additionalChargeTypePermission,
  bankPermission,
  countryPermission,
  customerEditPermission,
  customerTypePermission,
  discountSchemePermission,
  districtPermission,
  organizationPermission,
  organizationRulePermission,
  palikaPermission,
  paymentModePermission,
  provincePermission,
  storePermission,
  taxGroupPermission,
  titlePermission,
  userGroupPermission,
  userPermission,
  wardPermission,
} from "../Component/Layout/Sidebar/Master/MasterPermissionConstants";
import ProtectedRoutes from "./ProtectedRoutes";
import { lazyWithReload } from "./Utils";
const User = lazyWithReload(
  () => import("../Pages/CoreSetupPages/User/UserPages")
);
const UserRole = lazyWithReload(
  () => import("../Pages/CoreSetupPages/UserRole/UserRolePages")
);
const Title = lazyWithReload(
  () => import("../Pages/CoreSetupPages/Title/TitlePages")
);

const Organization = lazyWithReload(
  () =>
    import("../Pages/CoreSetupPages/OrganizationSetup/OrganizationSetupPages")
);
const PaymentMode = lazyWithReload(
  () => import("../Pages/CoreSetupPages/PaymentMode/PaymentModePage")
);
const CoreSetup = lazyWithReload(() => import("../Pages/CoreSetupPages"));
const Profile = lazyWithReload(() => import("../Pages/Profile"));
// const ReferrerProfile = lazyWithReload(() => import("../Pages/ReferrerProfile"));

const Country = lazyWithReload(
  () => import("../Pages/CoreSetupPages/Country/CountryPages")
);
const Province = lazyWithReload(
  () => import("../Pages/CoreSetupPages/Province/ProvincePages")
);
const District = lazyWithReload(
  () => import("../Pages/CoreSetupPages/District/DistrictPages")
);
const Palika = lazyWithReload(
  () => import("../Pages/CoreSetupPages/Palika/PalikaPages")
);
const DiscountSchema = lazyWithReload(
  () => import("../Pages/CoreSetupPages/DiscountScheme/DiscountSchemePages")
);
const AdditionalCharge = lazyWithReload(
  () =>
    import("../Pages/CoreSetupPages/AdditionalChargeType/AdditionalChargePages")
);
const Bank = lazyWithReload(
  () => import("../Pages/CoreSetupPages/Bank/BankPages")
);

const TaxGroup = lazyWithReload(
  () => import("../Pages/CoreSetupPages/TaxGroup/TaxGroupPages")
);

const OrganizationRule = lazyWithReload(
  () => import("../Pages/CoreSetupPages/OrganizationRule/OrganizationRulePages")
);
const CustomerEdit = lazyWithReload(
  () => import("../Pages/CoreSetupPages/CustomerEdit/CustomerEditPages")
);
// const Ward = lazyWithReload(
//   () => import("../Pages/CoreSetupPages/Ward/WardPages")
// );
const changePassword = lazyWithReload(
  () => import("../Pages/Auth/Password/PasswordPages")
);
// lab components imports
const verifyEmail = lazyWithReload(
  () => import("../Pages/Auth/VerifyEmail/index")
);
const VerifyEmailSuccessFully = lazyWithReload(
  () => import("../Pages/Auth/VerifyEmail/VerifyEmailSuccessFully")
);

const CoreSetupRoutes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoutes
          exact
          path="/verify-email"
          component={verifyEmail}
          permission={[{ mainModule: true }]}
        />
        <ProtectedRoutes
          exact
          path="/user/email-verify/"
          component={VerifyEmailSuccessFully}
          permission={[{ mainModule: true }]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup"
          component={CoreSetup}
          permission={[{ mainModule: true }]}
        />
        <ProtectedRoutes
          exact
          path="/profile"
          component={Profile}
          permission={[{ mainModule: true }]}
        />
        <ProtectedRoutes
          exact
          path="/change-password"
          component={changePassword}
          permission={[{ mainModule: true }]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/user"
          component={User}
          permission={userPermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/user-group"
          component={UserRole}
          permission={userGroupPermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/title"
          component={Title}
          permission={titlePermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/organization-setup"
          component={Organization}
          permission={organizationPermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/organization-rule"
          component={OrganizationRule}
          permission={organizationRulePermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/payment-mode"
          component={PaymentMode}
          permission={paymentModePermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/country"
          component={Country}
          permission={countryPermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/province"
          component={Province}
          permission={provincePermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/district"
          component={District}
          permission={districtPermission}
        />

        <ProtectedRoutes
          exact
          path="/core-setup/palika"
          component={Palika}
          permission={palikaPermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/discount-scheme"
          component={DiscountSchema}
          permission={discountSchemePermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/additional-charge-type"
          component={AdditionalCharge}
          permission={additionalChargeTypePermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/bank"
          component={Bank}
          permission={bankPermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/tax-group"
          component={TaxGroup}
          permission={taxGroupPermission}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/customer-edit"
          component={CustomerEdit}
          permission={customerEditPermission}
        />
      </Switch>
    </>
  );
};

export default CoreSetupRoutes;
