import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const ourTeamURL = "api/v1/admin/website-setup-app/our-team";

//get ourTeam
export const getOurTeam = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${ourTeamURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};

//get ourTeam by id
export const getOurTeamById = (id: number) => {
  return axiosInstance.get(`${ourTeamURL}/${id}`);
};
//search ourTeam
export const searchOurTeam = ({ rowsPerPage, search }: searchProps) => {
  return `${ourTeamURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};
//update ourTeam
export const updateOurTeam = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${ourTeamURL}/${id}`, body);
//create ourTeam
export const createOurTeam = (body: string | FormData) =>
  axiosInstance.post(`${ourTeamURL}`, body);
