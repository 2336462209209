import {
  getUserWiseReportUserRequest,
  getUserWiseReportAllSummaryRequest,
  getUserWiseReportAllDetailRequest,
  getUserWiseReportQuickSummaryRequest,
  getUserWiseReportQuickDetailRequest,
  //reducer get data
  getUserWiseReportUserSuccess,
  getUserWiseReportUserFail,
  getUserWiseReportQuickDetailSuccess,
  getUserWiseReportQuickDetailFail,
  getUserWiseReportAllDetailSuccess,
  getUserWiseReportAllDetailFail,
  getUserWiseReportQuickSummarySuccess,
  getUserWiseReportQuickSummaryFail,
  getUserWiseReportAllSummarySuccess,
  getUserWiseReportAllSummaryFail,
  getUserWiseReportDetailSuccess,
  getUserWiseReportDetailFail,
  loadingUserWiseReportDetail,
  getUserWiseReportPreviousRequest,
  getUserWiseReportNextRequest,
  getUserWiseReportDetailRequest,
} from "./userWiseReportSlice";
import { mergeMap } from "rxjs";
import *  as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";

export const controller = new AbortController();

//user
const getUserWiseReportUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserWiseReportUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserWiseReportUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserWiseReportUserSuccess(action?.payload)
        : getUserWiseReportUserFail()
    )
  );
//item

//get all purchase summary detail
const getUserWiseReportAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserWiseReportAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserWiseReportAllReport(action?.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserWiseReportAllSummarySuccess(action?.payload)
        : getUserWiseReportAllSummaryFail()
    )
  );
//quick summary
const getUserWiseReportQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserWiseReportQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserWiseReportQuickReport(action.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserWiseReportQuickSummarySuccess(action?.payload)
        : getUserWiseReportQuickSummaryFail()
    )
  );

//get all purchase summary detail
const getUserWiseReportAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserWiseReportAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserWiseReportAllReport(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserWiseReportAllDetailSuccess(action?.payload)
        : getUserWiseReportAllDetailFail()
    )
  );
//quick Detail
const getUserWiseReportQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserWiseReportQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserWiseReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserWiseReportQuickDetailSuccess(action?.payload)
        : getUserWiseReportQuickDetailFail()
    )
  );

//next and previous
//get next
const getUserWiseReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserWiseReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingUserWiseReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserWiseReportDetailSuccess(action?.payload)
        : getUserWiseReportDetailFail()
    )
  );
//get previous
const getUserWiseReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserWiseReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingUserWiseReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserWiseReportDetailSuccess(action?.payload)
        : getUserWiseReportDetailFail()
    )
  );
//het purchase report details
const getUserWiseReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getUserWiseReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getUserWiseReportQuickReport(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getUserWiseReportDetailSuccess(action?.payload)
        : getUserWiseReportDetailFail()
    )
  );
export const UserWiseReportEpics = combineEpics(
  getUserWiseReportUserEpic,
  getUserWiseReportAllSummaryEpic,
  getUserWiseReportQuickSummaryEpic,
  getUserWiseReportQuickDetailEpic,
  getUserWiseReportAllDetailEpic,
  getUserWiseReportPrevious,
  getUserWiseReportNext,
  getUserWiseReportEpic,
);
