import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const featuredVideoURL = "api/v1/admin/website-setup-app/featured-videos";

//get ablum
export const getFeaturedVideo = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${featuredVideoURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};

//get featuredVideo by id
export const getFeaturedVideoById = (id: number) => {
  return axiosInstance.get(`${featuredVideoURL}/${id}`);
};
//search featuredVideo
export const searchFeaturedVideo = ({ rowsPerPage, search }: searchProps) => {
  return `${featuredVideoURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};
//update featuredVideo
export const updateFeaturedVideo = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${featuredVideoURL}/${id}`, body);
//create featuredVideo
export const createFeaturedVideo = (body: string | FormData) =>
  axiosInstance.post(`${featuredVideoURL}`, body);
