import { axiosInstance } from "../../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../../AppUtils/Utils/globalTypes";
import { CustomerWiseReport } from "./types";

const CustomerWiseReportModURL = '/api/v1/inventory-financial-report-app/customer-wise-sales-report';


export const getCustomerWiseReportSupplier = () =>
axiosInstance.get(`${CustomerWiseReportModURL}/supplier?offset=0&limit=${0}`);
export const getCustomerWiseReportUser = () =>
axiosInstance.get(`${CustomerWiseReportModURL}/user?offset=0&limit=${0}`);
export const getCustomerWiseReportItem = () =>
axiosInstance.get(`${CustomerWiseReportModURL}/item?offset=0&limit=${0}`);
export const getCustomerWiseReportAllReport = (data:any) =>{
  const {updatedValue} = data;
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${CustomerWiseReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${0}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  )};
export const getCustomerWiseReportQuickReport = (data: any) => {
  const { rowsPerPage, page ,updatedValue} = data;
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;
  return axiosInstance.get(
    `${CustomerWiseReportModURL}/${summary?"summary":"detail"}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }&date_after=${startDateAd}&date_before=${endDateAd}`
  );
};

export const searchCustomerWiseReport = ({ rowsPerPage, search ,updatedValue}: any) => {
  const {
    summary,
    detail,
    user,
    startDateAd,
    endDateAd,
  } = updatedValue;

  return `${CustomerWiseReportModURL}/${summary?"summary":"detail"}?offset=0&limit=${rowsPerPage}&search=${search}&date_after=${startDateAd}&date_before=${endDateAd}`;
};