import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const featuredProductURL = "api/v1/admin/website-setup-app/featured-products";

//get ablum
export const getFeaturedProduct = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${featuredProductURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};

//get featuredProduct by id
export const getFeaturedProductById = (id: number) => {
  return axiosInstance.get(`${featuredProductURL}/${id}`);
};
//search featuredProduct
export const searchFeaturedProduct = ({ rowsPerPage, search }: searchProps) => {
  return `${featuredProductURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};
//update featuredProduct
export const updateFeaturedProduct = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${featuredProductURL}/${id}`, body);
//create featuredProduct
export const createFeaturedProduct = (body: string | FormData) =>
  axiosInstance.post(`${featuredProductURL}`, body);
