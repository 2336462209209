import { createSlice, createAction } from "@reduxjs/toolkit";
import { CustomerWiseReportInitialState } from "./types";
const initialState: CustomerWiseReportInitialState = {
  users: [],
  customerWiseDetails: [],
  //loading 
  loadingGenerateReport: false,
  loadingCustomerWiseReportUser: false,
  loadingCustomerWiseReportDetail: false,
  count: 0,
  previous: "",
  next: "",
};

// export const getCustomerWiseReportDetailRequest = createAction(
//   "getCustomerWiseReportDetailRequest",
//   function prepare({ detailsRowsPerPage, page }) {
//     return {
//       payload: {
//         detailsRowsPerPage,
//         page,
//       },
//     };
//   }
// );
export const CustomerWiseReport = createSlice({
  name: "CustomerWiseReportReducer",
  initialState,
  reducers: {
    //loading
    loadingCustomerWiseReportDetail: (state) => {
      state.loadingCustomerWiseReportDetail = true;
    },
    //external reducer request
    getCustomerWiseReportDetailRequest: (state, { payload }) => {
      state.loadingCustomerWiseReportDetail = true;
    },
    getCustomerWiseReportUserRequest: (state) => {
      state.loadingCustomerWiseReportUser = true;
    },
    getCustomerWiseReportAllSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getCustomerWiseReportAllDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getCustomerWiseReportQuickSummaryRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getCustomerWiseReportQuickDetailRequest: (state, { payload }) => {
      state.loadingGenerateReport = true;
    },
    getCustomerWiseReportNextRequest: (state, payload) => {
      state.loadingCustomerWiseReportDetail = true;
    },
    getCustomerWiseReportPreviousRequest: (state, payload) => {
      state.loadingCustomerWiseReportDetail = true;
    },
    //external get reducer  
    getCustomerWiseReportDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.customerWiseDetails = results;
      state.loadingCustomerWiseReportDetail = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCustomerWiseReportDetailFail: (state) => {
      state.loadingCustomerWiseReportDetail = false;
    },
    getCustomerWiseReportUserSuccess: (state, { payload: { results } }) => {
      state.loadingCustomerWiseReportUser = false;
      state.users = results;
    },
    getCustomerWiseReportUserFail: (state) => {
      state.loadingCustomerWiseReportUser = false;
    },
    getCustomerWiseReportAllSummarySuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.customerWiseDetails = results;
    },
    getCustomerWiseReportAllSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getCustomerWiseReportQuickSummarySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.customerWiseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCustomerWiseReportQuickSummaryFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getCustomerWiseReportAllDetailSuccess: (state, { payload: { results } }) => {
      state.loadingGenerateReport = false;
      state.customerWiseDetails = results;
    },
    getCustomerWiseReportAllDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },
    getCustomerWiseReportQuickDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingGenerateReport = false;
      state.customerWiseDetails = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCustomerWiseReportQuickDetailFail: (state) => {
      state.loadingGenerateReport = false;
    },

    clearCustomerWiseReportData: (state) => {
      state.users = [];
    },
    clearPrintUserActivityLogData: (state) => {
      state.customerWiseDetails = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  //reducerReport
  getCustomerWiseReportDetailRequest,
  getCustomerWiseReportUserRequest,
  getCustomerWiseReportAllSummaryRequest,
  getCustomerWiseReportAllDetailRequest,
  getCustomerWiseReportQuickSummaryRequest,
  getCustomerWiseReportQuickDetailRequest,
  getCustomerWiseReportPreviousRequest,
  getCustomerWiseReportNextRequest,
  //reducer get data
  getCustomerWiseReportDetailSuccess,
  getCustomerWiseReportDetailFail,
  getCustomerWiseReportUserSuccess,
  getCustomerWiseReportUserFail,
  getCustomerWiseReportQuickDetailSuccess,
  getCustomerWiseReportQuickDetailFail,
  getCustomerWiseReportAllDetailSuccess,
  getCustomerWiseReportAllDetailFail,
  getCustomerWiseReportQuickSummarySuccess,
  getCustomerWiseReportQuickSummaryFail,
  getCustomerWiseReportAllSummarySuccess,
  getCustomerWiseReportAllSummaryFail,
  //loading
  loadingCustomerWiseReportDetail,
  //clear
  clearCustomerWiseReportData,
  clearPrintUserActivityLogData,
} = CustomerWiseReport.actions;
export default CustomerWiseReport.reducer;
