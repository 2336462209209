import { createAction, createSlice } from "@reduxjs/toolkit";
import { branch } from "./types";
import setCookie from "../../../AppUtils/Utils/Cookies/setCookie";
import deleteCookie from "../../../AppUtils/Utils/Cookies/deleteCookie";
export interface AuthState {
  isAuthenticated: boolean;
  loadingAuth: boolean;
  userName: string;
  userId: number | null;
  authError: boolean;
  isSuperuser: boolean;
  img: string | null;
  open: boolean;
  permissions: any;
  // for reset
  message: string;
  loadingReset: boolean;
  resetError: boolean;
  // for confirm
  loadingConfirm: boolean;
  isEmailVerified: boolean;
  verifyMessage: string;
  loadingVerifyEmail: boolean;
  verifyEmailError: boolean;
  otpCode: string;
  loadingResendOTP: boolean;
}
const initialState: AuthState = {
  isAuthenticated: false,
  loadingAuth: false,
  permissions: [],
  userName: "",
  userId: null,
  authError: false,
  isSuperuser: false,
  img: null,
  open: false,
  message: "",
  loadingReset: false,
  resetError: false,
  loadingConfirm: false,
  isEmailVerified: false,
  verifyMessage: "",
  loadingVerifyEmail: false,
  verifyEmailError: false,
  otpCode: "",
  loadingResendOTP: false,
};

export const loginRequest = createAction(
  "loginRequest",
  function prepare(body) {
    return {
      payload: body,
    };
  }
);

// export const getLoginRequestFail = createAction("loginRequestFail");
const Auth = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    loadingLogin: (state) => {
      state.loadingAuth = true;
    },
    loginSuccess: (state, action) => {
      setCookie("accessToken", action.payload.tokens.access, {
        //if https secure url header then set secure true
        secure: true,
        // httpOnly: true,
        "max-age": 360000,
        sameSite: "Lax",
        domain: "192.168.0.100",
      });
      setCookie("refreshToken", action.payload.tokens.refresh, {
        secure: true,
        // httpOnly: true,
        "max-age": 3600000,
        sameSite: "Lax",
      });
      setCookie("isAuthenticated", true, {
        secure: true,
        //  httpOnly: false,
        "max-age": 3600000,
        sameSite: "Lax",
      });
      state.loadingAuth = false;
      state.userId = action?.payload?.id;
      state.isAuthenticated = true;
      state.userName = action.payload.userName;
      state.isSuperuser = action?.payload?.isSuperuser;
      state.authError = false;
      state.permissions = action?.payload?.groups?.flatMap(
        (permission: any) => permission.permissions
      );
      state.img = action.payload.photo;
      state.isEmailVerified = action?.payload?.isEmailVerified;
    },
    loginFail: (state) => {
      deleteCookie("refreshToken");
      deleteCookie("accessToken");
      deleteCookie("isAuthenticated");
      state.loadingAuth = false;
      state.userId = null;
      state.isAuthenticated = false;
      state.userName = "";
      state.isSuperuser = false;
      state.authError = true;
      state.permissions = [];
      state.img = null;
      state.isEmailVerified = false;
    },
    //reset password
    resetPasswordReq: (state, payload) => {
      state.loadingReset = true;
      state.message = "";
      state.resetError = false;
    },
    resetPasswordSuccess: (state, { payload }) => {
      state.message = payload;
      state.loadingReset = false;
      state.resetError = false;
    },
    resetPasswordFail: (state) => {
      state.loadingReset = false;
      state.resetError = true;
    },
    // confirm password
    confirmPasswordReq: (state, payload) => {
      state.loadingConfirm = true;
    },
    confirmPasswordSuccess: (state, { payload }) => {
      state.loadingConfirm = false;
    },
    confirmPasswordFail: (state) => {
      state.loadingConfirm = false;
    },
    clearResetData: (state) => {
      state.message = "";
      state.verifyMessage = "";
    },
    // verify email
    verifyEmailLinkReq: (state, payload) => {
      state.loadingVerifyEmail = true;
      state.verifyMessage = "";
      state.verifyEmailError = false;
    },
    verifyEmailReq: (state, payload) => {
      state.loadingVerifyEmail = true;
      state.verifyMessage = "";
      state.verifyEmailError = false;
    },
    verifyEmailSuccess: (state, { payload }) => {
      state.verifyMessage = payload?.detail;
      state.loadingReset = false;
      state.verifyEmailError = false;
    },
    verifyEmailFail: (state) => {
      state.loadingVerifyEmail = false;
      state.verifyEmailError = true;
    },
    //resend otp
    resendOTPRequest: (state, payload) => {
      state.loadingResendOTP = true;
    },
    resendOTPSuccess: (state, { payload }) => {
      state.loadingResendOTP = false;
      state.otpCode = payload;
    },
    resendOTPFail: (state) => {
      state.loadingResendOTP = false;
    },
  },
});
export const {
  loginSuccess,
  loadingLogin,
  loginFail,
  //
  resetPasswordReq,
  resetPasswordSuccess,
  resetPasswordFail,
  //
  confirmPasswordReq,
  confirmPasswordSuccess,
  confirmPasswordFail,
  //
  clearResetData,
  //
  verifyEmailReq,
  verifyEmailSuccess,
  verifyEmailFail,
  verifyEmailLinkReq,
  //
  resendOTPRequest,
  resendOTPSuccess,
  resendOTPFail,
} = Auth.actions;
export default Auth.reducer;
