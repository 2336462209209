import { createAction, createSlice } from "@reduxjs/toolkit";
import { itemListDetailInitialState } from "./types";
const initialState: itemListDetailInitialState = {
  itemListDetails: [],
  loadingItemlistDetails: false,
  count: 0,
  previous: "",
  next: "",
};
export const getItemListDetailRequest = createAction(
  "getItemListDetailRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
export const ItemListDetail = createSlice({
  name: "itemListDetailReducer",
  initialState,
  reducers: {
    loadingItemListDetail: (state, payload) => {
      state.loadingItemlistDetails = true;
    },
    getItemListDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.itemListDetails = results;
      state.loadingItemlistDetails = false;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getItemListDetailFail: (state) => {
      state.loadingItemlistDetails = false;
    },
    getItemListDetailNextRequest: (state, payload) => {
      state.loadingItemlistDetails = true;
    },
    getItemListDetailPreviousRequest: (state, payload) => {
      state.loadingItemlistDetails = true;
    },
    clearAllData: (state) => {
      state.itemListDetails = null;
    },
  },
});
//action creaters generated for each case reducer
export const {
  loadingItemListDetail,
  getItemListDetailFail,
  getItemListDetailSuccess,
  getItemListDetailNextRequest,
  getItemListDetailPreviousRequest,
  clearAllData,
} = ItemListDetail.actions;
export default ItemListDetail.reducer;
