/** @format */
import { combineEpics } from "redux-observable";
import { paymentModeEpics } from "../Pages/CoreSetupPages/PaymentMode/Redux/PaymentModeEpic";
import { userEpics } from "../Pages/CoreSetupPages/User/Redux/userEpic";
import { authEpics } from "../Pages/Auth/Redux/authEpic";
import { countryEpics } from "../Pages/CoreSetupPages/Country/Redux/countryEpic";
import { provinceEpics } from "../Pages/CoreSetupPages/Province/Redux/provinceEpic";
import { districtEpics } from "../Pages/CoreSetupPages/District/Redux/districtEpic";
import { palikaEpics } from "../Pages/CoreSetupPages/Palika/Redux/palikaEpic";
import { taxGroupEpics } from "../Pages/CoreSetupPages/TaxGroup/Redux/taxGroupEpic";
import { organizationEpics } from "../Pages/CoreSetupPages/OrganizationSetup/Redux/organizationEpic";
import { titleEpics } from "../Pages/CoreSetupPages/Title/Redux/titleEpic";
import { discountSchemaEpics } from "../Pages/CoreSetupPages/DiscountScheme/Redux/DiscountSchemeEpic";
import { userRoleEpics } from "../Pages/CoreSetupPages/UserRole/Redux/userRoleEpic";
import { additionalChargeEpic } from "../Pages/CoreSetupPages/AdditionalChargeType/Redux/AdditionalChargeEpic";
import { bankEpic } from "../Pages/CoreSetupPages/Bank/Redux/BankEpic";
import { organizationRuleEpics } from "../Pages/CoreSetupPages/OrganizationRule/Redux/organizationRuleEpic";
import { supplierEpics } from "../Pages/POSPages/Supplier/Redux/supplierEpic";
import { purchaseEpics } from "../Pages/POSPages/Purchase/Redux/purchaseEpic";

import { partyPaymentEpics } from "../Pages/POSPages/PartyPayment/Redux/partyPaymentEpic";

//common viewDetails
import { itemListDetailEpics } from "../Pages/ViewDetails/ItemList/Redux/ItemListDetailEpic";
import { purchaseReportEpics } from "../Pages/POSPages/Purchase/PurchaseReport/Redux/purchaseReportEpic";
import { saleReportEpics } from "../Pages/POSPages/Purchase/SaleReport/Redux/saleReportEpic";
import { customerEditEpics } from "../Pages/CoreSetupPages/CustomerEdit/Redux/customerEditEpic";
import { purchaseReturnEpics } from "../Pages/POSPages/PurchaseReturnPages/Redux/purchaseReturnEpic";
import { saleEpics } from "../Pages/POSPages/Sale/Redux/saleEpic";
import { commonAppEpics } from "../Pages/CommonAppRedux/commonAppEpic";
import { saleReturnEpics } from "../Pages/POSPages/SaleReturn/Redux/saleReturnEpic";
import { customerEpics } from "../Pages/POSPages/Customer/Redux/customerEpic";
import { CustomerWiseReportEpics } from "../Pages/POSPages/InventoryReport/CustomerWiseReport/Redux/customerWiseReportEpic";
import { SupplierWiseReportEpics } from "../Pages/POSPages/InventoryReport/SupplierWiseSaleReport/Redux/supplierWiseReportEpic";
import { creditClearanceEpics } from "../Pages/POSPages/CreditClearance/Redux/creditClearanceEpic";
import { passwordEpics } from "../Pages/Auth/Password/Redux/passwordEpic";
import { UserWiseReportEpics } from "../Pages/POSPages/InventoryReport/UserWiseReport/Redux/userWiseReportEpic";
import { stockAnalysisReportInventoryEpics } from "../Pages/POSPages/InventoryReport/StockAnalysis/Redux/stockAnalysisReportInventoryEpic";
import { stockAnalysisEpics } from "../Pages/POSPages/StockAnalysis/Redux/stockAnalysisEpic";
//product setup
import { productCategoryEpics } from "../Pages/ProductSetupPages/ProductCategory/Redux/productCategoryEpic";
import { productEpics } from "../Pages/ProductSetupPages/Product/Redux/productEpic";
//website setup
import { albumEpic } from "../Pages/WebsiteSetupPages/Album/Redux/albumEpic";
import { bannerEpic } from "../Pages/WebsiteSetupPages/HomeBanner/Redux/bannerEpic";
import { featuredImageEpic } from "../Pages/WebsiteSetupPages/FeaturedImages/Redux/featuredImagesEpic";
import { featuredProductEpic } from "../Pages/WebsiteSetupPages/FeaturedProduct/Redux/featuredProductEpic";
import { featuredVideoEpic } from "../Pages/WebsiteSetupPages/FeaturedVideo/Redux/featuredVideoEpic";
import { ourPartnerEpic } from "../Pages/WebsiteSetupPages/OurPartner/Redux/ourPartnerEpic";
import { generalFaqsEpic } from "../Pages/WebsiteSetupPages/GeneralFaqs/Redux/generalFaqsEpic";
import { ourTeamEpic } from "../Pages/WebsiteSetupPages/OurTeam/Redux/ourTeamEpic";
import { blogTagEpics } from "../Pages/WebsiteSetupPages/BlogTag/Redux/tagEpic";
import { blogCategoryEpics } from "../Pages/WebsiteSetupPages/BlogCategory/Redux/categoryEpic";
import { blogEpics } from "../Pages/WebsiteSetupPages/Blog/Redux/blogEpic";
import { orderEpics } from "../Pages/POSPages/Order/Redux/orderEpic";
import { PromoCodeEpic } from "../Pages/WebsiteSetupPages/PromoCode/Redux/promoCodeEpic";

export const RootEpic = combineEpics(
  //common app
  commonAppEpics,
  userEpics,
  paymentModeEpics,
  authEpics,
  countryEpics,
  provinceEpics,
  districtEpics,
  palikaEpics,
  taxGroupEpics,
  supplierEpics,
  userRoleEpics,
  organizationEpics,
  organizationRuleEpics,
  titleEpics,
  discountSchemaEpics,
  purchaseEpics,
  purchaseReturnEpics,
  additionalChargeEpic,
  bankEpic,
  // inventory
  saleEpics,
  saleReturnEpics,
  customerEpics,
  orderEpics,

  partyPaymentEpics,
  //viewDetails
  itemListDetailEpics,
  //purchase report
  purchaseReportEpics,
  saleReportEpics,
  //foreign worker registration
  //customer edit
  customerEditEpics,
  //referral login
  // authReferralEpics,
  //ird
  //inventory report
  CustomerWiseReportEpics,
  SupplierWiseReportEpics,
  //
  creditClearanceEpics,
  passwordEpics,
  UserWiseReportEpics,

  stockAnalysisReportInventoryEpics,
  stockAnalysisEpics,
  // product setups
  productCategoryEpics,
  productEpics,
  //website setup
  albumEpic,
  bannerEpic,
  featuredImageEpic,
  featuredProductEpic,
  featuredVideoEpic,
  ourPartnerEpic,
  generalFaqsEpic,
  ourTeamEpic,
  blogTagEpics,
  blogCategoryEpics,
  blogEpics,
  PromoCodeEpic
);
