import {
  createOrganizationRuleFail,
  createOrganizationRuleRequest,
  createOrganizationRuleSuccess,
  getOrganizationRuleFail,
  getOrganizationRuleSuccess,
  getTaxGroupRequest,
  getTaxGroupSuccess,
  getTaxGroupFail,
  updateOrganizationRuleFail,
  updateOrganizationRuleSuccess,
  getOrganizationRuleRequest,
  updateOrganizationRuleRequest,
  getOrganizationRuleNextRequest,
  getOrganizationRulePreviousRequest,
  clearOrganizationRuleData,
  getOrganizationRuleByIdRequest,
  organizationRuleEditFail,
  organizationRuleEditSuccess,
} from "./organizationRuleSlice";
import { mergeMap } from "rxjs";
import {
  createOrganizationRule,
  getOrganizationRule,
  getOrganizationRuleById,
  updateOrganizationRule,
  getTaxGroup,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
  getGlobalOrganizationRuleRequest,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get OrganizationRule epic
const getOrganizationRuleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationRuleRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getOrganizationRule(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrganizationRuleSuccess(action?.payload)
        : getOrganizationRuleFail()
    )
  );
const getAllTaxGroup = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTaxGroupRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getTaxGroup(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getTaxGroupSuccess(action?.payload) : getTaxGroupFail()
    )
  );

//get organizationRule by Id
const getOrganizationRuleByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationRuleByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getOrganizationRuleById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? organizationRuleEditSuccess(action?.payload)
        : organizationRuleEditFail()
    )
  );

//get next
const getOrganizationRuleNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationRuleNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrganizationRuleSuccess(action?.payload)
        : getOrganizationRuleFail()
    )
  );
//get previous
const getOrganizationRulePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationRulePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrganizationRuleSuccess(action?.payload)
        : getOrganizationRuleFail()
    )
  );
//create OrganizationRule epic
const createOrganizationRuleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createOrganizationRuleRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);

        const response = await createOrganizationRule(body);
        if (response) {
          dispatch(getOrganizationRuleRequest({ rowsPerPage, page }));
          dispatch(getGlobalOrganizationRuleRequest());
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createOrganizationRuleSuccess()
        : createOrganizationRuleFail();
    })
  );
//update OrganizationRule epic
const updateOrganizationRuleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateOrganizationRuleRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      try {
        const body = JSON.stringify(values);
        const response = await updateOrganizationRule(body, id);
        if (response) {
          dispatch(getOrganizationRuleRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(getGlobalOrganizationRuleRequest());
          dispatch(clearOrganizationRuleData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? updateOrganizationRuleSuccess()
        : updateOrganizationRuleFail()
    )
  );

export const organizationRuleEpics = combineEpics(
  getOrganizationRuleEpic,
  createOrganizationRuleEpic,
  updateOrganizationRuleEpic,
  getOrganizationRuleNext,
  getOrganizationRulePrevious,
  getOrganizationRuleByIdEpic,
  getAllTaxGroup
);
