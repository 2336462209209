import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  count: 0,
  previous: "",
  next: "",
  //
  edit: false,
  customerOrder: null,
  customerOrders: [],
  loadingCustomerOrder: false,
  //
  customerOrderDetail: null,
  customerOrderDetails: [],
  customerOrderDetailEdit: false,
  orderDetails: null,
  loadingdeliveryPartners: false,
  deliveryPartners: [],
  //
  loadingMakeBill: false,
  loadingOrderStatus: false,
  loadingUpdateOrderStatus: false,
  loadingUpdateOrderDetails: false,
  //
  loadingOrderCount: false,
  orderCountData: [],
  //
  loadingLastSale: false,
  lastInvoiceNo: "",
};

export const CustomerOrder = createSlice({
  name: "CustomerOrder Reducer",
  initialState,
  reducers: {
    loadingCustomerOrder: (state) => {
      state.loadingCustomerOrder = true;
    },
    getCustomerOrderRequest: (state, payload) => {
      state.loadingCustomerOrder = true;
    },
    getCustomerOrderSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCustomerOrder = false;
      state.customerOrders = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCustomerOrderFail: (state) => {
      state.loadingCustomerOrder = false;
    },
    getCustomerOrderNextRequest: (state, payload) => {
      state.loadingCustomerOrder = true;
    },
    getCustomerOrderPreviousRequest: (state, payload) => {
      state.loadingCustomerOrder = true;
    },
    getCustomerOrderByIdRequest: (state, payload) => {
      state.edit = true;
    },
    clearCustomerOrderData: (state) => {
      state.edit = false;
      state.customerOrder = null;
      state.orderDetails = null;
      state.customerOrderDetail = null;
      state.customerOrderDetails = [];
      state.customerOrderDetailEdit = false;
      state.orderCountData = [];
    },

    updateOrderStatusRequest: (state, payload) => {
      state.loadingUpdateOrderStatus = true;
    },
    updateOrderStatusSuccess: (state) => {
      state.loadingUpdateOrderStatus = false;
    },
    updateOrderStatusFail: (state) => {
      state.loadingUpdateOrderStatus = false;
    },

    createMakeBillRequest: (state, payload) => {
      state.loadingMakeBill = true;
    },
    createMakeBillSuccess: (state) => {
      state.loadingMakeBill = false;
    },
    createMakeBillFail: (state) => {
      state.loadingMakeBill = false;
    },

    // get order details
    getOrderDetailsRequest: (state, payload) => {
      state.loadingUpdateOrderDetails = true;
    },
    getOrderDetailsSuccess: (state, { payload }) => {
      state.loadingUpdateOrderDetails = false;
      state.orderDetails = payload;
    },
    getOrderDetailsFail: (state) => {
      state.loadingUpdateOrderDetails = false;
      state.orderDetails = null;
    },

    // get deliverys partners
    getDeliveryPartnersRequest: (state) => {
      state.loadingdeliveryPartners = true;
    },
    getDeliveryPartnersSuccess: (state, { payload: { results } }) => {
      state.loadingdeliveryPartners = false;
      state.deliveryPartners = results;
    },
    getDeliveryPartnersFail: (state) => {
      state.loadingdeliveryPartners = false;
    },

    // get order counts
    getOrderCountRequest: (state) => {
      state.loadingOrderCount = true;
    },
    getOrderCountSuccess: (state, { payload }) => {
      state.loadingOrderCount = false;
      state.orderCountData = payload;
    },
    getOrderCountFail: (state) => {
      state.loadingOrderCount = false;
    },

    // get last sale
    getLastSaleRequest: (state) => {
      state.loadingLastSale = true;
    },
    getLastSaleSuccess: (state, { payload }) => {
      state.loadingLastSale = false;
      state.lastInvoiceNo = payload?.invoiceNo;
    },
    getLastSaleFail: (state) => {
      state.loadingLastSale = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadingCustomerOrder,
  getCustomerOrderRequest,
  getCustomerOrderSuccess,
  getCustomerOrderFail,
  getCustomerOrderNextRequest,
  getCustomerOrderPreviousRequest,
  getCustomerOrderByIdRequest,
  clearCustomerOrderData,
  //
  updateOrderStatusRequest,
  updateOrderStatusSuccess,
  updateOrderStatusFail,
  //
  getOrderDetailsRequest,
  getOrderDetailsSuccess,
  getOrderDetailsFail,
  //
  createMakeBillRequest,
  createMakeBillSuccess,
  createMakeBillFail,
  //
  getDeliveryPartnersRequest,
  getDeliveryPartnersFail,
  getDeliveryPartnersSuccess,
  //
  getOrderCountRequest,
  getOrderCountSuccess,
  getOrderCountFail,
  //
  getLastSaleRequest,
  getLastSaleFail,
  getLastSaleSuccess,
} = CustomerOrder.actions;

export default CustomerOrder.reducer;
