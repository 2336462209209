import ShopIcon from "@mui/icons-material/Shop";
import SummarizeIcon from "@mui/icons-material/Summarize";
import user from "../../../../Assets/CoreSetup/User.webp";
// custom icon
import inventoryIcon from "../../../../Assets/icons/InventoryIcons/inventory.svg";
import financialIcon from "../../../../Assets/icons/InventoryIcons/financial.svg";
import itemRequestIcon from "../../../../Assets/icons/InventoryIcons/item_request.svg";
import itemTransferIcon from "../../../../Assets/icons/InventoryIcons/item_transfer.svg";
import customerIcon from "../../../../Assets/icons/InventoryIcons/customer.svg";
import purchaseIcon from "../../../../Assets/icons/InventoryIcons/purchase.svg";
import serviceIcon from "../../../../Assets/icons/InventoryIcons/service.svg";
import stockIcon from "../../../../Assets/icons/InventoryIcons/stock.svg";
import supplierIcon from "../../../../Assets/icons/InventoryIcons/supplier.svg";

//inventory submenu  mid customer icon
import itemSubMenuSetup from "../../../../Assets/icons/InventoryMidIcon/item_setup.svg";
import itemSubMenuTransfer from "../../../../Assets/icons/InventoryMidIcon/item_transfer.svg";
import manufactureSubMenu from "../../../../Assets/icons/InventoryMidIcon/manufacture.svg";
import itemCategorySubMenuSetup from "../../../../Assets/icons/InventoryMidIcon/item_category_setup.svg";

import customerReportSubMenu from "../../../../Assets/icons/InventoryMidIcon/customer_report.svg";
import irdSyncSubMenu from "../../../../Assets/icons/InventoryMidIcon/ird_sync.svg";
import materializedReportSubMenu from "../../../../Assets/icons/InventoryMidIcon/materialized_report.svg";
import packingTypeSubMenu from "../../../../Assets/icons/InventoryMidIcon/packaging_type.svg";

import patientSubMenu from "../../../../Assets/icons/InventoryMidIcon/patient.svg";
import purchaseReportSubMenu from "../../../../Assets/icons/InventoryMidIcon/purchase_report.svg";
import purchaseReturnSubMenu from "../../../../Assets/icons/InventoryMidIcon/purchase_return.svg";
import purchaseSubMenu from "../../../../Assets/icons/InventoryMidIcon/purchase.svg";

import serviceSubMenu from "../../../../Assets/icons/InventoryMidIcon/service.svg";
import supplierSubMenu from "../../../../Assets/icons/InventoryMidIcon/supplier.svg";
import stockAnalysisSubMenu from "../../../../Assets/icons/InventoryMidIcon/stock_analysis.svg";
import supplierReportSubMenu from "../../../../Assets/icons/InventoryMidIcon/supplier_report.svg";
import userReportSubMenu from "../../../../Assets/icons/InventoryMidIcon/user_report.svg";
import sale from "../../../../Assets/icons/InventoryMidIcon/sale.svg";
import saleReturn from "../../../../Assets/icons/InventoryMidIcon/sale_return.svg";
import saleReport from "../../../../Assets/icons/InventoryMidIcon/sale_report.svg";
import itemUnit from "../../../../Assets/icons/InventoryMidIcon/item_unit.svg";

// permissions
import {
  invCreditClearancePermission,
  invCustomerPermission,
  invCustomerWiseReportPermission,
  invIrdSyncPermission,
  invItemRequestPermission,
  invItemTransferPermission,
  invItemUnitPermission,
  invManufacturerPermission,
  invMaterializedReportPermission,
  invPackingTypePermission,
  invPartyPaymentPermission,
  invPurchasePermission,
  invPurchaseReportPermission,
  invPurchaseReturnPermission,
  invSalePermission,
  invSaleReportPermission,
  invSaleReturnPermission,
  invServicePermission,
  invStockAnalysisPermission,
  invStockAnalysisReportPermission,
  invSupplierPermission,
  invSupplierWiseReportPermission,
  invUserActivityLogReportPermission,
  invUserWiseReportPermission,
  itemCategoryPermission,
  itemPermission,
} from "./InvPermissionConstants";

export const invSidebarData = [
  {
    name: "Payment Clearance",
    customIcon: serviceIcon,
    items: [
      {
        name: "Party Payment",
        image: purchaseSubMenu,
        link: "/inventory/party-payment",
        permission: invPartyPaymentPermission,
      },
      {
        name: "Credit Clearance",
        image: purchaseReturnSubMenu,
        link: "/inventory/credit-clearance",
        permission: invCreditClearancePermission,
      },
    ],
  },
  {
    name: "Customer",
    // for custom icons
    customIcon: customerIcon,
    items: [
      {
        name: `Customer`,
        image: patientSubMenu,
        link: "/inventory/customer",
        permission: invCustomerPermission,
      },
    ],
  },
  {
    name: "Supplier",
    // for custom icons
    customIcon: supplierIcon,
    items: [
      {
        name: "Supplier",
        image: supplierSubMenu,
        link: "/inventory/supplier",
        permission: invSupplierPermission,
      },
    ],
  },
  {
    name: "Order",
    // for custom icons
    customIcon: supplierIcon,
    items: [
      {
        name: "Manage Orders",
        image: itemSubMenuSetup,
        link: "/inventory/order",
        permission: invSupplierPermission,
      },
    ],
  },
  {
    name: "Purchase",
    // for custom icons
    customIcon: purchaseIcon,
    items: [
      {
        name: "Purchase",
        image: purchaseSubMenu,
        link: "/inventory/purchase",
        permission: invPurchasePermission,
      },
      {
        name: "Purchase Return",
        image: purchaseReturnSubMenu,
        link: "/inventory/purchase-return",
        permission: invPurchaseReturnPermission,
      },
    ],
  },
  {
    name: "Sale",
    Icon: ShopIcon,
    items: [
      {
        name: "Sale",
        image: sale,
        link: "/inventory/sale",
        permission: invSalePermission,
      },
      {
        name: "Sale Return",
        image: saleReturn,
        link: "/inventory/sale-return",
        permission: invSaleReturnPermission,
      },
    ],
  },
  {
    name: "Stock Analysis",
    // for custom icons
    customIcon: stockIcon,
    items: [
      {
        name: "Stock Analysis",
        image: stockAnalysisSubMenu,
        link: "/inventory/stock-analysis",
        permission: invStockAnalysisPermission,
      },
    ],
  },
  {
    name: "Financial Report",
    // for custom icons
    customIcon: financialIcon,
    items: [
      {
        name: "Purchase Report",
        image: purchaseReportSubMenu,
        link: "/inventory/purchase-report",
        permission: invPurchaseReportPermission,
      },
      {
        name: "Sale Report",
        image: saleReport,
        link: "/inventory/sale-report",
        permission: invSaleReportPermission,
      },
      {
        name: "Customer Wise Report",
        image: customerReportSubMenu,
        link: "/inventory/customer-wise-report",
        permission: invCustomerWiseReportPermission,
      },
      {
        name: "Supplier Wise Report",
        image: supplierReportSubMenu,
        link: "/inventory/supplier-wise-report",
        permission: invSupplierWiseReportPermission,
      },
      {
        name: "User Wise Report",
        image: customerReportSubMenu,
        link: "/inventory/user-wise-report",
        permission: invUserWiseReportPermission,
      },
      {
        name: "Stock Analysis Report",
        image: stockAnalysisSubMenu,
        link: "/inventory/stock-analysis-report",
        permission: invStockAnalysisReportPermission,
      },
    ],
  },
];
