import {
  getFeaturedVideoRequest,
  getFeaturedVideoFail,
  getFeaturedVideoSuccess,
  createFeaturedVideoFail,
  createFeaturedVideoRequest,
  createFeaturedVideoSuccess,
  updateFeaturedVideoFail,
  updateFeaturedVideoSuccess,
  clearAllData,
  getFeaturedVideoNextRequest,
  getFeaturedVideoPreviousRequest,
  updateFeaturedVideoRequest,
  getFeaturedVideoByIdRequest,
  featuredVideoEditSuccess,
  featuredVideoEditFail,
} from "./featuredVideoSlice";
import { mergeMap } from "rxjs";
import { getFeaturedVideo, createFeaturedVideo, updateFeaturedVideo, getFeaturedVideoById } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get FeaturedVideo
const getFeaturedVideoEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedVideoRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getFeaturedVideo(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getFeaturedVideoSuccess(action?.payload) : getFeaturedVideoFail()
    )
  );

//get featuredVideo by id epic
const getFeaturedVideoByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedVideoByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getFeaturedVideoById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? featuredVideoEditSuccess(action.payload) : featuredVideoEditFail()
    )
  );

//create
const createFeaturedVideoEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createFeaturedVideoRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { video, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof video !== "string") {
          body.append("video", video);
        }
        const response = await createFeaturedVideo(body);
        if (response) {
          dispatch(getFeaturedVideoRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createFeaturedVideoSuccess() : createFeaturedVideoFail();
    })
  );

//update
const updateFeaturedVideoEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateFeaturedVideoRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      const { video, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          body.append(`${key}`, String(value));
        }
        if (typeof video !== "string") {
          body.append("video", video);
        }
        const response = await updateFeaturedVideo(body, id);
        if (response) {
          dispatch(getFeaturedVideoRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearAllData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? updateFeaturedVideoSuccess() : updateFeaturedVideoFail();
    })
  );

//get next
const getFeaturedVideoNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedVideoNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getFeaturedVideoSuccess(action?.payload)
        : getFeaturedVideoFail();
    })
  );

//get previous
const getFeaturedVideoPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getFeaturedVideoPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getFeaturedVideoSuccess(action?.payload) : getFeaturedVideoFail()
    )
  );

export const featuredVideoEpic = combineEpics(
  getFeaturedVideoEpic,
  createFeaturedVideoEpic,
  updateFeaturedVideoEpic,
  getFeaturedVideoNext,
  getFeaturedVideoPrevious,
  getFeaturedVideoByIdEpic
);
