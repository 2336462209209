import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const creditClearanceModURL =
  "/api/v1/admin/inventory-app/inv-credit-clearance/credit-clearance";
export const createCreditClearanceReturnModUrl =
  "/api/v1/admin/inventory-app/inv-credit-clearance/credit-clearance-return";

//GET Credit Clearance
export const getCreditClearance = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${creditClearanceModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//GET Credit Clearance Customer
export const getCreditClearanceCustomer = () => {
  return axiosInstance.get(
    `${creditClearanceModURL}/customers?offset=0&limit=0`
  );
};

//GET Credit Clearance Payment Methods
export const getPaymentMethods = () => {
  return axiosInstance.get(
    `${creditClearanceModURL}/payment-methods?offset=0&limit=0`
  );
};

// Search credit clearance
export const searchCreditClearance = ({ rowsPerPage, search }: searchProps) => {
  return `${creditClearanceModURL}?offset=0&limit=${rowsPerPage}$search=${search}`;
};

// Create Credit Clearance
export const createCreditClearance = (body: string | FormData) => {
  return axiosInstance.post(`${creditClearanceModURL}`, body);
};

// Update Credit Clearance
export const updateCreditClearance = (body: string | FormData, id: number) => {
  return axiosInstance.patch(`${creditClearanceModURL}/${id}`, body);
};

// Retrieve Credit Clearance Detail
export const getCreditClearanceById = (id: number) => {
  return axiosInstance.get(`${creditClearanceModURL}/${id}`);
};

export const getCreditClearanceSalesWise = ({ rowsPerPage, page, id }: any) => {
  return axiosInstance.get(
    `${creditClearanceModURL}/sales-wise?customer=${id}&offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}`
  );
};

export const getCreditClearanceCustomerWise = ({ id }: any) => {
  return axiosInstance.get(`${creditClearanceModURL}/customer-wise/${id}`);
};
