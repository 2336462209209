import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const tagModURL = 'api/v1/admin/website-setup-app/blog/tag';

//get Tag data api
export const getTag = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${tagModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get Tag by Id
export const getTagById = (id: number) => {
  return axiosInstance.get(`${tagModURL}/${id}`);
}

//handle search
export const searchTag = ({ rowsPerPage, search }: searchProps) => {
  return `${tagModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Tag
export const createTag = (body: string | FormData) =>
  axiosInstance.post(`${tagModURL}`, body);
//update Tag
export const updateTag = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${tagModURL}/${id}`, body);
