import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";
//get title data api

const titleModURL = '/api/v1/core-app/title'

export const getTitle = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${titleModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get title by id
export const getTitleById = (id: number) => {
  return axiosInstance.get(`${titleModURL}/${id}`);
};

export const searchTitle = ({ rowsPerPage, search }: searchProps) => {
  return `${titleModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createTitle = (body: string | FormData) =>
  axiosInstance.post(`${titleModURL}`, body);
//update title
export const updateTitle = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${titleModURL}/${id}`, body);