import { axiosInstance } from "../../../AppUtils/Utils/axios";

import { branch, credential, user } from "./types";

const loginModURL = "api/v1/admin/user-app/user";

//login api
export const getLogin = (body: credential) =>
  axiosInstance.post<user>(`${loginModURL}/login`, body);
//branches api
export const getAllBranches = () => axiosInstance.get<branch[]>(`branches`);
//for logout
export const logout = (body: string) =>
  axiosInstance.post(`${loginModURL}/logout`, body);
//for reset password request
export const resetPassword = (body: string) =>
  axiosInstance.post(`${loginModURL}/forget-password-request`, body);
//
export const confirmPassword = (body: string) =>
  axiosInstance.post(`${loginModURL}/forget-password`, body);
//
export const changePassword = (id: number, body: string) =>
  axiosInstance.patch(`${loginModURL}/change-password/${id}`, body);

//verify email request
export const verifyEmailRequest = (body: string) => {
  return axiosInstance.post(`${loginModURL}/email-verification-request`, body);
};

//send token along with email for verification
export const verifyEmail = (body: string) => {
  return axiosInstance.post(`${loginModURL}/verify-email`, body);
};

