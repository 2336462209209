import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";

export const customerOrderModURL = "/api/v1/admin/order-app/orders";

//GET Customer Orders API
export const getCustomerOrder = (data: any) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${customerOrderModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

// Update Order Status API
export const updateOrderStatus = (body: string, id: number) => {
  return axiosInstance.patch(
    `api/v1/admin/order-app/order/update-status/${id}`,
    body
  );
};

// GET Order Detail API
export const getOrderDetails = (id: number) => {
  return axiosInstance.get(`/api/v1/admin/order-app/order/${id}`);
};

// Create Order Bill
export const createMakeBill = (body: any) => {
  return axiosInstance.post(`/api/v1/admin/order-app/order/make-bill`, body);
};

// GET Sales
export const getSales = (invoiceNo: string | number) => {
  return axiosInstance.get(
    `/api/v1/admin/inventory-app/inv-sale/sale?invoice_no=${invoiceNo}`
  );
};

// GET Latest Sale
export const getLatestSale = () => {
  return axiosInstance.get(
    `/api/v1/admin/inventory-app/inv-sale/sale?ordering=-invoice_no&limit=1`
  );
};

//GET Delivery Partners API
export const getDeliveryPatners = () => {
  return axiosInstance.get(`api/v1/admin/order-app/order/delivery-partners`);
};

//GET Order Counts API
export const getOrderCounts = () => {
  return axiosInstance.get(`api/v1/admin/order-app/order/order-counts`);
};
