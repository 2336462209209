import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import ResetPasswordConfirm from "../Pages/Auth/ResetPassword/ResetPasswordConfirm";
const Login = lazy(() => import("../Pages/Auth/LoginPages/Login"));
const ResetPassword = lazy(() => import("../Pages/Auth/ResetPassword/index"));

const PublicRoutes = () => {
  return (
    <>
      <Suspense fallback="">
        <Switch>
          <Route exact path="/reset-password">
            <ResetPassword />
          </Route>
          <Route exact path="/confirm-password">
            {<ResetPasswordConfirm />}
          </Route>
          <Route exact path="/user/reset-password/:token">
            {<ResetPasswordConfirm />}
          </Route>
          <Route path="/">{<Login />}</Route>
        </Switch>
      </Suspense>
    </>
  );
};

export default PublicRoutes;
