import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const userRoleModURL = "api/v1/admin/user-group-app/user-group";

export const getUserRole = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${userRoleModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const searchUserRole = ({ rowsPerPage, search }: searchProps) => {
  return `${userRoleModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createUserRole = (body: string | FormData) =>
  axiosInstance.post(`${userRoleModURL}`, body);

export const updateUserRole = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${userRoleModURL}/${id}`, body);

export const getAllPermission = () =>
  axiosInstance.get(`${userRoleModURL}/user-permission`);

//get data
export const getUserGroup = (postsPerPage: number) =>
  axiosInstance.get(`${userRoleModURL}?offset=0&limit=${postsPerPage}`);
//creating function
export const createUserGroup = (body: string | FormData) =>
  axiosInstance.post(`${userRoleModURL}`, body);

//deleting function
export const deleteUserGroup = (id: number) =>
  axiosInstance.delete(`${userRoleModURL}/${id}`);

//update function
export const updateUserGroup = (id: number, body: string | FormData) =>
  axiosInstance.patch(`${userRoleModURL}/${id}`, body);

//
export const getUserRoleById = (id: number) => {
  return axiosInstance.get(`${userRoleModURL}/${id}`);
};

//get all permission Category
export const getAllPermissionCategory = () => {
  return axiosInstance.get(
    `${userRoleModURL}/user-permission-category?offset=0&limit=0`
  );
};
//searching function
// export const searchUserRole = (search: string, rowsPerPage: number) =>
//   axiosInstance.get(
//     `api/v1/user-group-app/user-group?offset=0&limit=${rowsPerPage}&search=${search}`
//   );
export const getSpecificPermissions = (id: number) =>
  axiosInstance.get(
    `/api/v1/admin/user-group-app/user-group/user-permission-category?main_module=${id}&offset=0&limit=0`
  );
export const getSpecificUserPermissionCategory = (id: number) =>
  axiosInstance.get(
    `/api/v1/admin/user-group-app/user-group/user-permission?permission_category=${id}&offset=0&limit=0`
  );
export const getAllUserPermissions = (id: number) =>
  axiosInstance.get(
    `/api/v1/admin/user-group-app/user-group/user-permission?main_module=${id}&offset=0&limit=0`
  );

//runs when click on module dropdown in user role form
export const getUserRoleSystemModule = () =>
  axiosInstance.get(
    `api/v1/admin/user-group-app/user-group/main-module?offset=0&limit=0`
  );
