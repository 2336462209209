import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createEpicMiddleware } from "redux-observable";
import { RootEpic } from "./rootEpic";
const persistConfig = {
  key: "root",
  storage: storage,
  // which reducer you want to store
  whitelist: ["auth","commonApp"],
};
const epicMiddleware = createEpicMiddleware();
//ensures your redux state is stored to persisted storage whenever it changes.
const persistedReducer = persistReducer(persistConfig, rootReducer);
// store
const store = configureStore({
  reducer: persistedReducer,
  middleware: [epicMiddleware],
  devTools: process.env.NODE_ENV !== "production",
});
//store your redux reducers using redux persist
const persistor = persistStore(store);
export { store, persistor };
// epicMiddleware.run(RootEpic);
epicMiddleware.run((action$, state$) => {
  return RootEpic(action$, state$, { dispatch: store.dispatch });
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
