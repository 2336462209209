import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
    getApiProps,
    searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const customerEditModURL = "/api/v1/customer-app/customer";
//get CustomerEdit by id
export const getCustomerEditById = (id: number) => {
  return axiosInstance.get(`${customerEditModURL}/${id}`);
};
//get CustomerEdit data api
export const getCustomerEdit = (data: getApiProps) => {
    const { rowsPerPage, page, search } = data;
    return axiosInstance.get(
        `${customerEditModURL}?offset=${
            rowsPerPage * page
        }&limit=${rowsPerPage}&search=${search ?? ""}${
            data?.filterData ? filterApiData(data?.filterData) : ""
        }`
    );
};
//handle search
export const searchCustomerEdit = ({ rowsPerPage, search }: searchProps) => {
  return `${customerEditModURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};

//create CustomerEdit
export const createCustomerEdit = (body: string | FormData) =>
  axiosInstance.post(`${customerEditModURL}`, body);
//update CustomerEdit
export const updateCustomerEdit = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${customerEditModURL}/${id}`, body);
//get extranal data
export const getCustomerEditDepartment = () =>
  axiosInstance.get(`${customerEditModURL}/department?offset=0&limit=${0}`);
  export const getCustomerEditDoctor = () =>
  axiosInstance.get(`${customerEditModURL}/doctor?offset=0&limit=${0}`);
  export const getCustomerEditProvince = () =>
  axiosInstance.get(`${customerEditModURL}/province?offset=0&limit=${0}`);
  export const getCustomerEditDistrict = () =>
  axiosInstance.get(`${customerEditModURL}/district?offset=0&limit=${0}`);
  export const getCustomerEditCustomerType = () =>
  axiosInstance.get(`${customerEditModURL}/customer-type?offset=0&limit=${0}`);
  export const getCustomerEditTitle = () =>
  axiosInstance.get(`${customerEditModURL}/title?offset=0&limit=${0}`);

  export const getCustomerEditPalika = (rowsPerPage:number) =>
  axiosInstance.get(`${customerEditModURL}/palika?offset=0&limit=${rowsPerPage}`);
  export const getCustomerEditWard = (id:number) =>
  axiosInstance.get(`${customerEditModURL}/ward?palika=${id}&offset=0&limit=${0}`);
  export const getCustomerEditJaatiType = (rowsPerPage:number) =>
  axiosInstance.get(`${customerEditModURL}/jaati-type?offset=0&limit=${rowsPerPage}`);
  export const getCustomerEditDiscountScheme = (rowsPerPage:number) =>
  axiosInstance.get(`${customerEditModURL}/discount-scheme?offset=0&limit=${rowsPerPage}`);
  
  export const getCustomerEditServiceType = (rowsPerPage:number) =>
  axiosInstance.get(`${customerEditModURL}/service-type?offset=0&limit=${rowsPerPage}`);
  
 
  export const getCustomerEditDepartmentInvestigationDetail = (data: any) => {
    const {   value,customerType} = data;

    return axiosInstance.get(
      `${customerEditModURL}/investigation-charge?department=${value?.id}&customer_type=${customerType?.id}`
    );
  };
    
  export const getPrintCustomerDetails = ({ id }: any) =>
  axiosInstance.get(
    `${customerEditModURL}/${id}?offset=0&limit=0`
  );
  export const getCustomerEditPrintCount = (id: string) =>
  axiosInstance.get(
    `api/v1/clinic-sale-app/clinic-sale/invoice-print-count?id=${id}`
  );
//print post count
export const printCount = (body: string) =>
  axiosInstance.post(
    `api/v1/clinic-sale-app/clinic-sale/update-invoice-print-count`,
    body
  );

  //
  export const getCustomerEditOccupation = () =>
  axiosInstance.get(
    `${customerEditModURL}/occupation?offset=0&limit=${0}`
  );
  export const getCustomerEditCountry = () =>
  axiosInstance.get(
    `${customerEditModURL}/country?offset=0&limit=${0}`
  );
