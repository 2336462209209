import {
  createPartyPaymentFail,
  createPartyPaymentRequest,
  createPartyPaymentSuccess,
  //
  getPartyPaymentFail,
  getPartyPaymentSuccess,
  loadingPartyPayment,
  getPartyPaymentRequest,
  //
  updatePartyPaymentFail,
  updatePartyPaymentSuccess,
  updatePartyPaymentRequest,
  //
  getPartyPaymentNextRequest,
  getPartyPaymentPreviousRequest,
  //
  clearPartyPaymentData,
  //
  getPartyPaymentByIdRequest,
  partyPaymentEditSuccess,
  partyPaymentEditFail,
  //
  getPartyPaymentSupplierRequest,
  getPartyPaymentSupplierSuccess,
  getPartyPaymentSupplierFail,
  //payment mode
  getPartyPaymentModeRequest,
  getPartyPaymentModeSuccess,
  getPartyPaymentModeFail,
} from "./partyPaymentSlice";
import { mergeMap } from "rxjs";
import {
  createPartyPayment,
  getPartyPayment,
  getPartyPaymentById,
  getPartyPaymentMode,
  getPartyPaymentSupplier,
  updatePartyPayment,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get PartyPayment epic
const getPartyPaymentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPartyPaymentRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingPartyPayment());
      try {
        const response = await getPartyPayment(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPartyPaymentSuccess(action?.payload)
        : getPartyPaymentFail()
    )
  );

//get PartyPayment by Id Epic
const getPartyPaymentByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPartyPaymentByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPartyPaymentById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? partyPaymentEditSuccess(action?.payload)
        : partyPaymentEditFail()
    )
  );

//get partyPayment supplier
const getPartyPaymentSupplierEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPartyPaymentSupplierRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPartyPaymentSupplier();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPartyPaymentSupplierSuccess(action?.payload)
        : getPartyPaymentSupplierFail()
    )
  );

//get next
const getPartyPaymentNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPartyPaymentNextRequest.match),
    mergeMap(async (action) => {
      //   dispatch(loadingPartyPayment());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPartyPaymentSuccess(action?.payload)
        : getPartyPaymentFail()
    )
  );
//get previous
const getPartyPaymentPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getPartyPaymentPreviousRequest.match),
    mergeMap(async (action) => {
      //   dispatch(loadingPartyPayment());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getPartyPaymentSuccess(action?.payload)
        : getPartyPaymentFail()
    )
  );
//create PartyPayment epic
const createPartyPaymentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createPartyPaymentRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      //   const { name, province, active } = values;
      try {
        const body = JSON.stringify(values);
        const response = await createPartyPayment(body);
        if (response) {
          dispatch(getPartyPaymentRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createPartyPaymentSuccess()
        : createPartyPaymentFail();
    })
  );
//update PartyPayment epic
const updatePartyPaymentEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updatePartyPaymentRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      //   const { name, province, active, remarks } = values;
      try {
        const body = JSON.stringify(values);
        const response = await updatePartyPayment(body, id);
        if (response) {
          dispatch(getPartyPaymentRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearPartyPaymentData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updatePartyPaymentSuccess() : updatePartyPaymentFail()
    )
  );

// payment mode for party payment
const getPartyPaymentModeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch } : dispatchAction
) => action$.pipe(
  filter(getPartyPaymentModeRequest.match),
  mergeMap( async (action) => {
    try {
      const response = await getPartyPaymentMode();
      return { payload: response.data};
    } catch (e) {
      return { error: e};
    }
  }),
  map((action) => action?.payload ? getPartyPaymentModeSuccess(action?.payload) : getPartyPaymentModeFail())
)

export const partyPaymentEpics = combineEpics(
  getPartyPaymentEpic,
  createPartyPaymentEpic,
  updatePartyPaymentEpic,
  getPartyPaymentNext,
  getPartyPaymentPrevious,
  getPartyPaymentByIdEpic,
  getPartyPaymentSupplierEpic,
  getPartyPaymentModeEpic,
);
