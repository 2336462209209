import {
  getSaleFail,
  getSaleSuccess,
  getSaleRequest,
  getSaleNextRequest,
  getSalePreviousRequest,
  clearSaleData,
  //
  createSaleFail,
  createSaleRequest,
  createSaleSuccess,
  //
  getSaleCustomerRequest,
  getSaleCustomerSuccess,
  getSaleCustomerFail,
  //
  getSaleItemRequest,
  getSaleItemSuccess,
  getSaleItemFail,
  // delivery partners
  getDeliveryPartnersRequest,
  getDeliveryPartnersSuccess,
  getDeliveryPartnersFail,
  // order platforms
  getOrderPlatFormRequest,
  getOrderPlatFormSuccess,
  getOrderPlatFormFail,
  //external reducer
  getSalePaymentModeRequest,
  getSalePaymentModeSuccess,
  getSalePaymentModeFail,
  //
  getSaleAdditionalChargeRequest,
  getSaleAdditionalChargeSuccess,
  getSaleAdditionalChargeFail,
  //
  getViewSaleDetailsFail,
  getViewSaleDetailsRequest,
  getViewSaleDetailsSuccess,

} from "./saleSlice";
import { mergeMap } from "rxjs";
import { createSale, getSale } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import * as API from "./api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
import { clearPaymentDetails } from "../../../CommonAppPages/PaymentModeDetails/Redux/paymentDetailsSlice";
import { filterDataTodayListing } from "../../../../AppUtils/Utils/appFunctions";

export const controller = new AbortController();

//GET Sales Epic
export const getSaleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSale(action?.payload);
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSaleSuccess(action?.payload) : getSaleFail()
    )
  );

// GET Next Sale
const getSaleNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action?.payload);
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSaleSuccess(action?.payload) : getSaleFail()
    )
  );

// GET Previous Sale
const getSalePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSalePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action?.payload);
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSaleSuccess(action?.payload) : getSaleFail()
    )
  );

// Create Sale Epic
const createSaleEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createSaleRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, saveAndPrint } }) => {
        try {
          const body = JSON.stringify(values);
          const response = await createSale(body);
          if (response) {
            dispatch(
              getSaleRequest({
                rowsPerPage,
                page,
                filterData: filterDataTodayListing,
              })
            );
            dispatch(alertSuccessAction(messages?.createMessage));
            dispatch(clearSaleData());
            !saveAndPrint && dispatch(closeModal());
            dispatch(clearPaymentDetails());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages?.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createSaleSuccess(action?.payload)
        : createSaleFail();
    })
  );

// GET Sale Customers
const getSaleCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleCustomer(action?.payload);
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleCustomerSuccess(action?.payload)
        : getSaleCustomerFail()
    )
  );

// GET Sale Delivery Partners
const getSaleDeliveryPartnersEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeliveryPartnersRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleDeliveryPartners();
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getDeliveryPartnersSuccess(action?.payload)
        : getDeliveryPartnersFail()
    )
  );

// GET Order Platforms
const getSaleOrderPlatformsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrderPlatFormRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleOrderPlatforms();
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrderPlatFormSuccess(action?.payload)
        : getOrderPlatFormFail()
    )
  );

const getSaleItemEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleItemRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleItem(action?.payload);
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSaleItemSuccess(action?.payload) : getSaleItemFail()
    )
  );

//get purchase payment mode
const getSalePaymentModeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSalePaymentModeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSalePaymentMode();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSalePaymentModeSuccess(action?.payload)
        : getSalePaymentModeFail()
    )
  );

//view details
const getSaleDetails = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getViewSaleDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleDetails(action?.payload);
        return { payload: response?.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getViewSaleDetailsSuccess(action?.payload)
        : getViewSaleDetailsFail()
    )
  );

//additional mode for billing
const getSaleAdditionalChargeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleAdditionalChargeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleAdditionalCharge();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleAdditionalChargeSuccess(action?.payload)
        : getSaleAdditionalChargeFail()
    )
  );

export const saleEpics = combineEpics(
  getSaleEpic,
  createSaleEpic,
  getSaleNext,
  getSalePrevious,
  getSaleCustomerEpic,
  getSaleItemEpic,
  //external Epics
  getSaleDeliveryPartnersEpic,
  getSaleOrderPlatformsEpic,
  getSalePaymentModeEpic,
  getSaleAdditionalChargeEpic,
  //
  getSaleDetails,
);
