import { createSlice } from "@reduxjs/toolkit";
import { initialStateTag } from "./types";
const initialState: initialStateTag = {
  tags: [],
  loadingTag: false,
  loadingCreateTag: false,
  edit: false,
  tag: null,
  count: 0,
  previous: "",
  next: "",
};

export const BlogTag = createSlice({
  name: "TagReducer",
  initialState,
  reducers: {
    getTagRequest: (state, payload) => {
      state.loadingTag = true;
    },
    getTagSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingTag = false;
      state.tags = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getTagFail: (state) => {
      state.loadingTag = false;
    },
    getTagNextRequest: (state, payload) => {
      state.loadingTag = true;
    },
    getTagPreviousRequest: (state, payload) => {
      state.loadingTag = true;
    },
    createTagRequest: (state, payload) => {
      state.loadingCreateTag = true;
    },
    createTagSuccess: (state) => {
      state.loadingCreateTag = false;
    },
    createTagFail: (state) => {
      state.loadingCreateTag = false;
    },
    getTagByIdRequest: (state, payload) => {
      state.edit = true;
    },
    tagEditSuccess: (state, { payload }) => {
      state.tag = payload;
    },
    tagEditFail: (state) => {
      state.edit = false;
    },
    updateTagRequest: (state, payload) => {
      state.loadingCreateTag = true;
    },
    updateTagSuccess: (state) => {
      state.loadingCreateTag = false;
    },
    updateTagFail: (state) => {
      state.loadingCreateTag = false;
    },
    clearTagData: (state) => {
      state.edit = false;
      state.tag = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getTagRequest,
  getTagNextRequest,
  getTagPreviousRequest,
  getTagSuccess,
  getTagFail,
  //
  createTagRequest,
  createTagSuccess,
  createTagFail,
  //
  getTagByIdRequest,
  tagEditSuccess,
  tagEditFail,
  //
  updateTagRequest,
  updateTagSuccess,
  updateTagFail,
  clearTagData,
} = BlogTag.actions;
export default BlogTag.reducer;
