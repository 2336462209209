import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { searchProps, getApiProps } from "../../../../AppUtils/Utils/globalTypes";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

const bankModURL = `api/v1/core-app/bank`;

//get bank
export const getBank = (data: getApiProps) => {
    const { rowsPerPage, page, search } = data;
    return axiosInstance.get(`${bankModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
    );
};
//get bank by id 
export const getBankById = (id: number) => {
  return axiosInstance.get(`${bankModURL}/${id}`);
}
//search bank
export const searchBank = ({ rowsPerPage, search}: searchProps) => {
    return `${bankModURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`
}
//update bank
export const updateBank = (body: string | FormData,
  id: number) => axiosInstance.patch(`${bankModURL}/${id}`, body);
//create bank
export const createBank = (body: string | FormData) => axiosInstance.post(`${bankModURL}`, body);