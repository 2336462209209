interface permission {
  name: string;
  category: number | null;
  codeName: string;
}
export const handlePermission = (
  permissions: any,
  modulePermission: string
) => {
  let permission = permissions?.some(
    ({ codeName }: any) => codeName === modulePermission
  );
  return permission;
};
