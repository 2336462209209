import { createSlice } from "@reduxjs/toolkit";
import { initialStateCategory } from "./types";
const initialState: initialStateCategory = {
  categories: [],
  loadingCategory: false,
  loadingCreateCategory: false,
  edit: false,
  category: null,
  count: 0,
  previous: "",
  next: "",
};

export const BlogCategory = createSlice({
  name: "CategoryReducer",
  initialState,
  reducers: {
    loadingBlogCategory: (state) => {
      state.loadingCategory = true;
    },
    getBlogCategoryRequest: (state, payload) => {
      state.loadingCategory = true;
    },
    getBlogCategorySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCategory = false;
      state.categories = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getBlogCategoryFail: (state) => {
      state.loadingCategory = false;
    },
    getBlogCategoryNextRequest: (state, payload) => {
      state.loadingCategory = true;
    },
    getBlogCategoryPreviousRequest: (state, payload) => {
      state.loadingCategory = true;
    },
    createBlogCategoryRequest: (state, payload) => {
      state.loadingCreateCategory = true;
    },
    createBlogCategorySuccess: (state) => {
      state.loadingCreateCategory = false;
    },
    createBlogCategoryFail: (state) => {
      state.loadingCreateCategory = false;
    },
    getBlogCategoryByIdRequest: (state, payload) => {
      state.edit = true;
    },
    categoryBlogEditSuccess: (state, { payload }) => {
      state.category = payload;
    },
    categoryBlogEditFail: (state) => {
      state.edit = false;
    },
    updateBlogCategoryRequest: (state, payload) => {
      state.loadingCreateCategory = true;
    },
    updateBlogCategorySuccess: (state) => {
      state.loadingCreateCategory = false;
    },
    updateBlogCategoryFail: (state) => {
      state.loadingCreateCategory = false;
    },
    clearBlogCategoryData: (state) => {
      state.edit = false;
      state.category = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingBlogCategory,
  getBlogCategoryRequest,
  getBlogCategoryNextRequest,
  getBlogCategoryPreviousRequest,
  getBlogCategorySuccess,
  getBlogCategoryFail,
  //
  createBlogCategoryRequest,
  createBlogCategorySuccess,
  createBlogCategoryFail,
  //
  getBlogCategoryByIdRequest,
  categoryBlogEditSuccess,
  categoryBlogEditFail,
  //
  updateBlogCategoryRequest,
  updateBlogCategorySuccess,
  updateBlogCategoryFail,
  clearBlogCategoryData,
} = BlogCategory.actions;
export default BlogCategory.reducer;
