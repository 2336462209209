import {
  getSupplierWiseReportUserRequest,
  getSupplierWiseReportAllSummaryRequest,
  getSupplierWiseReportAllDetailRequest,
  getSupplierWiseReportQuickSummaryRequest,
  getSupplierWiseReportQuickDetailRequest,
  //reducer get data
  getSupplierWiseReportUserSuccess,
  getSupplierWiseReportUserFail,
  getSupplierWiseReportQuickDetailSuccess,
  getSupplierWiseReportQuickDetailFail,
  getSupplierWiseReportAllDetailSuccess,
  getSupplierWiseReportAllDetailFail,
  getSupplierWiseReportQuickSummarySuccess,
  getSupplierWiseReportQuickSummaryFail,
  getSupplierWiseReportAllSummarySuccess,
  getSupplierWiseReportAllSummaryFail,
  getSupplierWiseReportDetailSuccess,
  getSupplierWiseReportDetailFail,
  loadingSupplierWiseReportDetail,
  getSupplierWiseReportPreviousRequest,
  getSupplierWiseReportNextRequest,
  getSupplierWiseReportDetailRequest,
} from "./supplierWiseReportSlice";
import { mergeMap } from "rxjs";
import *  as API from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";

export const controller = new AbortController();

//user
const getSupplierWiseReportUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierWiseReportUserRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSupplierWiseReportUser();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSupplierWiseReportUserSuccess(action?.payload)
        : getSupplierWiseReportUserFail()
    )
  );
//item

//get all purchase summary detail
const getSupplierWiseReportAllSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierWiseReportAllSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSupplierWiseReportAllReport(action?.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSupplierWiseReportAllSummarySuccess(action?.payload)
        : getSupplierWiseReportAllSummaryFail()
    )
  );
//quick summary
const getSupplierWiseReportQuickSummaryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierWiseReportQuickSummaryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSupplierWiseReportQuickReport(action.payload);
        if (response?.data?.count === 0) {
          dispatch(
            alertErrorAction(`No Materialized Data Found`)
          )
        }
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSupplierWiseReportQuickSummarySuccess(action?.payload)
        : getSupplierWiseReportQuickSummaryFail()
    )
  );

//get all purchase summary detail
const getSupplierWiseReportAllDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierWiseReportAllDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSupplierWiseReportAllReport(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSupplierWiseReportAllDetailSuccess(action?.payload)
        : getSupplierWiseReportAllDetailFail()
    )
  );
//quick Detail
const getSupplierWiseReportQuickDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierWiseReportQuickDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSupplierWiseReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSupplierWiseReportQuickDetailSuccess(action?.payload)
        : getSupplierWiseReportQuickDetailFail()
    )
  );

//next and previous
//get next
const getSupplierWiseReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierWiseReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSupplierWiseReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSupplierWiseReportDetailSuccess(action?.payload)
        : getSupplierWiseReportDetailFail()
    )
  );
//get previous
const getSupplierWiseReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierWiseReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSupplierWiseReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSupplierWiseReportDetailSuccess(action?.payload)
        : getSupplierWiseReportDetailFail()
    )
  );
//het purchase report details
const getSupplierWiseReportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSupplierWiseReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSupplierWiseReportQuickReport(action.payload);

        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSupplierWiseReportDetailSuccess(action?.payload)
        : getSupplierWiseReportDetailFail()
    )
  );
export const SupplierWiseReportEpics = combineEpics(
  getSupplierWiseReportUserEpic,
  getSupplierWiseReportAllSummaryEpic,
  getSupplierWiseReportQuickSummaryEpic,
  getSupplierWiseReportQuickDetailEpic,
  getSupplierWiseReportAllDetailEpic,
  getSupplierWiseReportPrevious,
  getSupplierWiseReportNext,
  getSupplierWiseReportEpic,
);
