import {
  //GET
  getCustomerRequest,
  getCustomerSuccess,
  getCustomerFail,
  clearCustomerData,
  //get next / previous
  getCustomerNextRequest,
  getCustomerPreviousRequest,
  //
  updatecustomerStatusRequest,
  updatecustomerStatusFail,
  updatecustomerStatusSuccess,
  //
  getCustomerDetailsRequest,
  getCustomerDetailsSuccess,
  getCustomerDetailsFail,
  //
  createCustomerFail,
  createCustomerRequest,
  createCustomerSuccess,
  //edit customer
  getCustomerByIdRequest,
  customerEditFail,
  customerEditSuccess,
  //update customer
  updateCustomerRequest,
  updateCustomerSuccess,
  updateCustomerFail,
} from "./customerSlice";
import { mergeMap } from "rxjs";
import { createCustomer, getCustomer, getCustomerById, getcustomerDetails, updateCustomer, updateCustomerStatus } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

//get customer epic
const getCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCustomerSuccess(action.payload) : getCustomerFail()
    )
  );

//get next
const getCustomerNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCustomerSuccess(action?.payload) : getCustomerFail()
    )
  );

//get previous
const getCustomerPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCustomerSuccess(action?.payload) : getCustomerFail()
    )
  );

// GET Customer Details
const getCustomerDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerDetailsRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getcustomerDetails(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCustomerDetailsSuccess(action?.payload)
        : getCustomerDetailsFail()
    )
  );

// Update Customer Status
const updateCustomerStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updatecustomerStatusRequest.match),
    mergeMap(
      async ({
        payload: { values, id, rowsPerPage, page, setShowUpdateStatus },
      }) => {
        try {
          const body = JSON.stringify(values);
          const response = await updateCustomerStatus(body, id);
          if (response) {
            dispatch(getCustomerRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages?.updateMessage));
            dispatch(clearCustomerData());
            dispatch(closeModal());
            setShowUpdateStatus?.(false);
            return { success: true, rowsPerPage };
          }
        } catch (e) {
          dispatch(alertErrorAction(messages?.updateFailMessage));
          return { success: false, error: e };
        }
      }
    ),
    map((action) =>
      action?.success
        ? updatecustomerStatusSuccess()
        : updatecustomerStatusFail()
    )
  );

//create Customer epic
const createCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createCustomerRequest.match),
    mergeMap(
      async ({ payload: { values, rowsPerPage, page, setShowCustomer } }) => {
        try {
          const body = JSON.stringify(values);
          const response = await createCustomer(body);
          if (response) {
            dispatch(alertSuccessAction(messages?.createMessage));
            !setShowCustomer &&
              dispatch(getCustomerRequest({ rowsPerPage, page }));
            setShowCustomer ? setShowCustomer(false) : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages?.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createCustomerSuccess(action?.payload)
        : createCustomerFail();
    })
  );

//update Customer epic
const updateCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateCustomerRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        // let body = new FormData();
        const body = JSON.stringify(values);
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await updateCustomer(body, id);
        if (response) {
          dispatch(getCustomerRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearCustomerData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages?.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateCustomerSuccess() : updateCustomerFail()
    )
  );

const getCustomerByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCustomerByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCustomerById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? customerEditSuccess(action?.payload)
        : customerEditFail()
    )
  );

export const customerEpics = combineEpics(
  getCustomerEpic,
  getCustomerNext,
  getCustomerPrevious,
  updateCustomerStatusEpic,
  getCustomerDetailsEpic,
  createCustomerEpic,
  updateCustomerEpic,
  getCustomerByIdEpic
);
