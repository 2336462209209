import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./types";


const initialState: InitialState = {
  paymentModes: [],
  loadingPaymentMode: false,
  cashPaymentMode: [],
  loadingCreatePaymentMode: false,
  edit: false,
  paymentMode: null,
  count: 0,
  previous: "",
  next: "",
};


export const PaymentMode = createSlice({
  name: "paymentModeReducer",
  initialState,
  reducers: {
    getPaymentModeRequest: (state, payload) => {
      state.loadingPaymentMode = true;
    },
    getPaymentModeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingPaymentMode = false;
      state.paymentModes = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPaymentModeFail: (state) => {
      state.loadingPaymentMode = false;
    },
    getPaymentModeNextRequest: (state, payload) => {
      state.loadingPaymentMode = true;
    },
    getPaymentModePreviousRequest: (state, payload) => {
      state.loadingPaymentMode = true;
    },
    createPaymentModeRequest: (state, payload) => {
      state.loadingCreatePaymentMode = true;
    },
    createPaymentModeSuccess: (state) => {
      state.loadingCreatePaymentMode = false;
    },
    createPaymentModeFail: (state) => {
      state.loadingCreatePaymentMode = false;
    },
    updatePaymentModeRequest: (state, payload) => {
      state.loadingCreatePaymentMode = true;
    },
    getPaymentModeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    paymentModeEditSuccess: (state, { payload }) => {
      state.paymentMode = payload;
    },
    paymentModeEditFail: (state) => {
      state.edit = false;
    },
    updatePaymentModeSuccess: (state) => {
      state.loadingCreatePaymentMode = false;
    },
    updatePaymentModeFail: (state) => {
      state.loadingCreatePaymentMode = false;
    },
    getCashRequest: (state) => {
      state.loadingPaymentMode = true;
    },
    getCashSuccess: (state, { payload }) => {
      state.cashPaymentMode = payload.results;
      state.loadingPaymentMode = false;
    },
    getCashFail: (state) => {
      state.loadingPaymentMode = false;
    },
    clearPaymentModeData: (state) => {
      state.edit = false;
      state.paymentMode = null;
    },
  },
});

//Action creators generated for each case reducer function
export const {
  getPaymentModeRequest,
  getPaymentModeSuccess,
  getPaymentModeFail,
  getPaymentModeNextRequest,
  getPaymentModePreviousRequest,
  getCashRequest,
  getCashSuccess,
  getCashFail,
  createPaymentModeRequest,
  createPaymentModeSuccess,
  createPaymentModeFail,
  paymentModeEditSuccess,
  updatePaymentModeSuccess,
  updatePaymentModeFail,
  updatePaymentModeRequest,
  clearPaymentModeData,
  getPaymentModeByIdRequest,
  paymentModeEditFail,
} = PaymentMode.actions;

export default PaymentMode.reducer;
