import { createSlice } from "@reduxjs/toolkit";
import { productCategoryInitialState } from "./types";

const initialState: productCategoryInitialState = {
  edit: false,
  loadingProductCategory: false,
  loadingCreateProductCategory: false,
  productCategories: [],
  productCategory: null,
  count: 0,
  previous: "",
  next: "",
};

export const ProductCategory = createSlice({
  name: "ProductCategoryReducer",
  initialState,
  reducers: {
    //
    loadingProductCategory: (state) => {
      state.loadingProductCategory = true;
    },
    getProductCategoryRequest: (state, payload) => {
      state.loadingProductCategory = true;
    },
    getProductCategorySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingProductCategory = false;
      state.productCategories = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getProductCategoryFail: (state) => {
      state.loadingProductCategory = false;
    },
    //
    getProductCategoryNextRequest: (state, payload) => {
      state.loadingProductCategory = true;
    },
    getProductCategoryPreviousRequest: (state, payload) => {
      state.loadingProductCategory = true;
    },
    //
    createProductCategoryRequest: (state, payload) => {
      state.loadingCreateProductCategory = true;
    },
    createProductCategorySuccess: (state) => {
      state.loadingCreateProductCategory = false;
    },
    createProductCategoryFail: (state) => {
      state.loadingCreateProductCategory = false;
    },
    //
    getProductCategoryByIdRequest: (state, payload) => {
      state.edit = true;
    },
    productCategoryEditSuccess: (state, { payload }) => {
      state.productCategory = payload;
    },
    productCategoryEditFail: (state) => {
      state.edit = false;
    },
    //
    updateProductCategoryRequest: (state, payload) => {
      state.loadingCreateProductCategory = true;
    },
    updateProductCategorySuccess: (state) => {
      state.loadingCreateProductCategory = false;
    },
    updateProductCategoryFail: (state) => {
      state.loadingCreateProductCategory = false;
    },
    //
    clearProductCategoryData: (state) => {
      state.edit = false;
      state.productCategory = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadingProductCategory,
  getProductCategoryRequest,
  getProductCategorySuccess,
  getProductCategoryFail,
  //
  createProductCategoryRequest,
  createProductCategorySuccess,
  createProductCategoryFail,
  //
  getProductCategoryByIdRequest,
  productCategoryEditSuccess,
  productCategoryEditFail,
  //
  getProductCategoryNextRequest,
  getProductCategoryPreviousRequest,
  //
  updateProductCategoryRequest,
  updateProductCategorySuccess,
  updateProductCategoryFail,
  //
  clearProductCategoryData,
} = ProductCategory.actions;

export default ProductCategory.reducer;
