const messages = {
  required: "Required!",
  email: "Invalid email format",
  date: "Invalid date",
  password: "Must have 6 characters, One alphabet and One Number.",
  confirmPassword: "Passwords must match.",
  duplicateData: "Already exists!",
  createMessage: "Created successfully",
  createFailMessage: "Failed to create",
  updateMessage: "Updated Successfully",
  updateFailMessage: "Failed to update",
  branchSuccess: "Branch Get successfully",
  branchFail: "Failed to get branch",
  loginSuccess: "Successfully Login",
  loginFail: "Login Failed",
  positiveMessage: "Must be greater than 0.",
  deleteDataSuccess: "Item Delete SuccessFully",
  addedSuccess: "Added SuccessFully",
  //calculation error msg start
  discountAmountValidation:
    "Discount Amount Should Not more then Discountable Amount or Negative",
  discountRateValidation: "Discount Rate Should Not more then 100 or negative",
  payType: "Select Paytype",
  paymentDetails: "Add Payment Details.",
  checkGrandTotal: "Grand Total and Payment Amount didn't match.",
  checkReturnQty: "Return Qty must not be zero.",
  remarks: "Add remarks",
  // report data
  reportData: "Data Not Found.",
  otpFailMsg: "Failed to get OTP code.",
};

export const minCharValidation = (numb: number) => {
  return `Must have at least ${numb} characters.`;
};

export const maxCharValidation = (numb: number) => {
  return `Must have at most ${numb} characters.`;
};

//in byte(equals to 3KB)
export const ThreeKBInBytes = 512000;

export default messages;
