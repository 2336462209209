import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const prodURL = "/api/v1/admin/product-app/product";

//get product
export const getProduct = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${prodURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get product by id
export const getProductById = (id: number) => {
  return axiosInstance.get(`${prodURL}/${id}`);
};

//search
export const searchProduct = ({ rowsPerPage, search }: searchProps) => {
  return `${prodURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};

//create
export const createProduct = (body: string | FormData) => {
  return axiosInstance.post(`${prodURL}`, body);
};

//update
export const updateProduct = (body: string | FormData, id: number) => {
  return axiosInstance.patch(`${prodURL}/${id}`, body);
};

//get product attributes for specific category
export const getProductAtt = ({ id, defaultRowsPerPage }: any) => {
  return axiosInstance.get(
    `${prodURL}/attributes?category=${id}&offset=0&limit=${defaultRowsPerPage}`
  );
};

//get product category
export const getProductCategory = (defaultRowsPerPage: number) => {
  return axiosInstance.get(
    `${prodURL}-category?offset=0&limit=${defaultRowsPerPage}`
  );
};

//get product variant attributes for specific category
export const getProductVariantAtt = ({ id, defaultRowsPerPage }: any) => {
  return axiosInstance.get(
    `${prodURL}-variant/attributes?category=${id}&offset=0&limit=${defaultRowsPerPage}`
  );
};
