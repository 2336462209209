import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
// icon
import FmdGoodIcon from "@mui/icons-material/FmdGood";
// images
// import customerType from "../../../../Assets/CoreSetup/customerType.webp";
// import customerEdit from "../../../../Assets/CoreSetup/customerEdit.webp";
// import district from "../../../../Assets/CoreSetup/district.webp";
// import organization from "../../../../Assets/CoreSetup/organization.webp";
// import organizationRule from "../../../../Assets/CoreSetup/organizationRule.webp";
// import province from "../../../../Assets/CoreSetup/province.webp";
// import userRole from "../../../../Assets/CoreSetup/userRole.webp";
// import country from "../../../../Assets/CoreSetup/country.webp";
// import discountScheme from "../../../../Assets/CoreSetup/discountScheme.webp";
// import bank from "../../../../Assets/CoreSetup/bank.webp";
// import title from "../../../../Assets/CoreSetup/title.webp";
// import additionalCharge from "../../../../Assets/CoreSetup/additionalCharge.webp";
// import paymentMode from "../../../../Assets/CoreSetup/paymentMode.webp";
// custom icon
import userIcon from "../../../../Assets/icons/CoreSetUpIcons/user_setup.svg";
import organizationIcon from "../../../../Assets/icons/CoreSetUpIcons/organization.svg";
import paymentIcon from "../../../../Assets/icons/CoreSetUpIcons/payment.svg";
///core mid icons

import additionalCharge from "../../../../Assets/icons/CoreSetupMidIcons/additional_charge.svg";
import bank from "../../../../Assets/icons/CoreSetupMidIcons/bank.svg";
import country from "../../../../Assets/icons/CoreSetupMidIcons/country.svg";
import customerEdit from "../../../../Assets/icons/CoreSetupMidIcons/customer_edit.svg";
import customerType from "../../../../Assets/icons/CoreSetupMidIcons/customer_types.svg";
import discountScheme from "../../../../Assets/icons/CoreSetupMidIcons/discount_schemes.svg";
import district from "../../../../Assets/icons/CoreSetupMidIcons/district.svg";
import organizationRule from "../../../../Assets/icons/CoreSetupMidIcons/organization_rule.svg";
import organizationSetup from "../../../../Assets/icons/CoreSetupMidIcons/organization_setup.svg";
import palika from "../../../../Assets/icons/CoreSetupMidIcons/palika.svg";
import paymentMethod from "../../../../Assets/icons/CoreSetupMidIcons/payment_method.svg";
import province from "../../../../Assets/icons/CoreSetupMidIcons/Province.svg";
import store from "../../../../Assets/icons/CoreSetupMidIcons/store-1.svg";
import taxGroup from "../../../../Assets/icons/CoreSetupMidIcons/tax_group.svg";
import title from "../../../../Assets/icons/CoreSetupMidIcons/tittle1.svg";
import userRole from "../../../../Assets/icons/CoreSetupMidIcons/user_role.svg";
import user from "../../../../Assets/icons/CoreSetupMidIcons/user.svg";

import {
  additionalChargeTypePermission,
  bankPermission,
  countryPermission,
  customerEditPermission,
  customerTypePermission,
  discountSchemePermission,
  districtPermission,
  organizationPermission,
  organizationRulePermission,
  palikaPermission,
  paymentModePermission,
  provincePermission,
  purchaseOrderAcceptPermission,
  storePermission,
  taxGroupPermission,
  titlePermission,
  userGroupPermission,
  userPermission,
} from "./MasterPermissionConstants";
export const masterSidebarData = [
  {
    name: "User Setup",
    // for custom icons
    customIcon: userIcon,
    items: [
      {
        name: "User",
        image: user,
        link: "/core-setup/user",
        permission: userPermission,
      },
      {
        name: "User Role",
        image: userRole,
        link: "/core-setup/user-group",
        permission: userGroupPermission,
      },
    ],
  },
  {
    name: "Organization Info",
    customIcon: organizationIcon,
    items: [
      {
        name: "Organization Setup",
        image: organizationSetup,
        // Icon: BusinessTwoToneIcon,
        link: "/core-setup/organization-setup",
        permission: organizationPermission,
      },
      {
        name: "Organization Rule",
        // Icon: BusinessTwoToneIcon,
        image: organizationRule,
        link: "/core-setup/organization-rule",
        permission: organizationRulePermission,
      },
    ],
  },
  {
    name: "Payment Setup",
    customIcon: paymentIcon,
    items: [
      {
        name: "Discount Scheme",
        link: "/core-setup/discount-scheme",
        image: discountScheme,
        permission: discountSchemePermission,
      },
      {
        name: "Additional Charge Type",
        image: additionalCharge,
        // Icon: BusinessTwoToneIcon,
        link: "/core-setup/additional-charge-type",
        permission: additionalChargeTypePermission,
      },
      {
        name: "Bank",
        // Icon: BusinessTwoToneIcon,
        image: bank,
        link: "/core-setup/bank",
        permission: bankPermission,
      },
      {
        name: "Payment Mode",
        // Icon: BusinessTwoToneIcon,
        link: "/core-setup/payment-mode",
        image: paymentMethod,
        permission: paymentModePermission,
      },
      {
        name: "Tax Group",
        // Icon: BusinessTwoToneIcon,
        link: "/core-setup/tax-group",
        image: taxGroup,
        permission: taxGroupPermission,
      },
    ],
  },
  {
    name: "Address Setup",
    Icon: FmdGoodIcon,
    items: [
      {
        name: "Country",
        link: "/core-setup/country",
        image: country,
        permission: countryPermission,
      },
      {
        name: "Province",
        link: "/core-setup/province",
        image: province,
        permission: provincePermission,
      },
      {
        name: "District",
        link: "/core-setup/district",
        image: district,
        permission: districtPermission,
      },
      {
        name: "Palika",
        link: "/core-setup/palika",
        image: palika,
        permission: palikaPermission,
      },
    ],
  },
  {
    name: "Admin Setup",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "Title",
        image: title,
        link: "/core-setup/title",
        permission: titlePermission,
      },
    ],
  },
  {
    name: "Correction",
    // for custom icons
    customIcon: userIcon,
    items: [
      {
        name: "Customer Edit",
        image: customerEdit,
        link: "/core-setup/customer-edit",
        permission: customerEditPermission,
      },
    ],
  },
];
