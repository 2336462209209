import { createSlice } from "@reduxjs/toolkit";
import { featuredImageInitialState } from "./types";

const initialState: featuredImageInitialState = {
  featuredImages: [],
  edit: false,
  featuredImage: null,
  loadingFeaturedImage: false,
  loadingCreateFeaturedImage: false,
  count: 0,
  previous: "",
  next: "",
};

export const FeaturedImage = createSlice({
  name: "featuredImageReducer",
  initialState,
  reducers: {
    getFeaturedImageRequest: (state, payload) => {
      state.loadingFeaturedImage = true;
    },
    getFeaturedImageSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingFeaturedImage = false;
      state.featuredImages = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getFeaturedImageFail: (state) => {
      state.loadingFeaturedImage = false;
    },

    getFeaturedImageNextRequest: (state, payload) => {
      state.loadingFeaturedImage = true;
    },
    getFeaturedImagePreviousRequest: (state, payload) => {
      state.loadingFeaturedImage = true;
    },
    createFeaturedImageRequest: (state, payload) => {
      state.loadingCreateFeaturedImage = true;
    },
    updateFeaturedImageRequest: (state, payload) => {
      state.loadingCreateFeaturedImage = true;
    },
    createFeaturedImageSuccess: (state) => {
      state.loadingCreateFeaturedImage = false;
    },
    createFeaturedImageFail: (state) => {
      state.loadingCreateFeaturedImage = false;
    },
    getFeaturedImageByIdRequest: (state, payload) => {
      state.edit = true;
    },
    featuredImageEditSuccess: (state, { payload }) => {
      state.featuredImage = payload;
    },
    featuredImageEditFail: (state) => {
      state.edit = false;
    },
    updateFeaturedImageSuccess: (state) => {
      state.loadingCreateFeaturedImage = false;
    },
    updateFeaturedImageFail: (state) => {
      state.loadingCreateFeaturedImage = false;
    },
    clearAllData: (state) => {
      state.edit = false;
      state.featuredImage = null;
    },
  },
});

//action creaters generated for each case reducer
export const {
  getFeaturedImageRequest,
  getFeaturedImageFail,
  getFeaturedImageSuccess,
  getFeaturedImageNextRequest,
  getFeaturedImagePreviousRequest,
  createFeaturedImageFail,
  createFeaturedImageRequest,
  createFeaturedImageSuccess,
  featuredImageEditSuccess,
  updateFeaturedImageFail,
  updateFeaturedImageSuccess,
  updateFeaturedImageRequest,
  clearAllData,
  getFeaturedImageByIdRequest,
  featuredImageEditFail,
} = FeaturedImage.actions;

export default FeaturedImage.reducer;
