import { makeStyles } from "@mui/styles";
export const useAppStyles = makeStyles({
  full_width: {
    width: "100% !important",
  },
  BtnroundBadgePrimary: {
    // display: "block",
    height: "1em",
    width: "1em",
    borderRadius: "100%",
    backgroundcolor: " #26801B",
    textAlign: "center",
    color: "white",
    fontSize: "1em",
    backgroundColor: "#2CA60E",
    "&:hover": {
      backgroundColor: "#2CA60E",
    },
  },
  searchSidebar: {
    position: "relative",
    margin: 9,
    borderRadius: "4px",
    "&:hover": {
      borderColor: "#a4adb2",
    },
    padding: "2px",
  },
  textCenter: {
    textAlign: "center",
  },
  checkCircleIconCustom: {
    color: "green",
  },
  cancelIconCustom: {
    color: "#eb3333",
  },
  BtnroundBadgeDanger: {
    height: "1em",
    width: "1em",
    borderRadius: "100%",
    textAlign: "center",
    color: "white",
    fontSize: "1em",
    backgroundColor: "#eb3333",
    "&:hover": {
      backgroundColor: "#dd3333",
    },
  },
  root: {
    // flexGrow: 1,
  },
  viewRoot: {
    flexGrow: 1,
    border: 5,
    boxShadow: "2px 2px 2px 2px #9E9E9E",
  },
  viewInput: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 16,
  },
  viewTextField: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  autoCompleteListBoxCustom: {
    fontSize: "13px !important",
    width: "550px !important",
  },
  gridWrapper: {
    border: "1px solid grey",
    display: "grid",
    gridRowGap: 1,
    gridColumnGap: 1,
  },
  purchaseMargin: {
    flexGrow: 1,
    marginBottom: "1.05rem",
  },
  displayFlex: {
    display: "flex",
  },
  display: {
    position: "absolute",
    top: 2,
    left: 0,
    bottom: 2,
    background: "white",
    pointerEvents: "none",
    right: 50,
    display: "flex",
    alignItems: "center",
  },
  input: {},
  paddingGrid: {
    padding: "0 30px",
  },
  BtnPrimary: {
    backgroundColor: "#305bb3 !important",
    color: "white !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2f59b2",
    },
  },
  BtnPrimaryOutline: {
    borderColor: "#4796ae",
    textTransform: "none",
    "&:hover": {
      borderColor: "#3c8095",
    },
  },
  BtnDanger: {
    backgroundColor: "#eb3333",
    color: "white",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#dd3333",
    },
  },
  BtnDangerOutline: {
    borderColor: "#eb3333",
    textTransform: "none",
    "&:hover": {
      borderColor: "#dd3333",
    },
  },
  fullWidth: {
    width: "100% !important",
  },
  billPurNo: {
    padding: "8px",
  },
  badgeChipsBg: {
    backgroundColor: "#356384 !important",
    color: "#fff !important",
    margin: 2,
  },
  table: {
    minWidth: "650",
  },
  snackbar: {
    bottom: "104px",
  },
  BtnSuccess: {
    backgroundColor: "#28C800",
    color: "white",
    "&:hover": {
      backgroundColor: "#2CA60E",
    },
  },
  iconStyle: {
    padding: "3px !important",
  },
  floatRight: {
    float: "right",
  },
  TextalignRight: {
    textAlign: "right",
  },
  TextalignLeft: {
    textAlign: "left",
  },
  TextCenter: {
    textAlign: "center",
    marginTop: "6px",
  },
  marginBottom: {
    marginBottom: "1rem",
  },
  addButtom: {
    paddingBottom: "12px",
  },
  marginright_Report: {
    marginRight: "5rem",
  },
  marginTop_Purchase: {
    marginTop: 4,
    float: "right",
  },
  addbtncenter: {
    padding: "10px 0",
    textAlign: "center",
  },
  mainButton: {
    padding: "6px 8px",
    backgroundColor: "#305bb3",
    color: "white",
    border: "1px solid #305bb3",
    borderRadius: "6px",
    cursor: "pointer",
  },
  purchaseDatePicker: {
    height: "10px",
  },
  customChipHeight: {
    height: "32px",
    backgroundColor: " #f5f5f5 !important",
  },
  customChip: {
    width: "100%",
    marginTop: "7px",
    marginLeft: "6px",
    fontSize: "0.9rem",
    borderRadius: "2px !important",
  },
  marginTop: {
    marginTop: "16px",
  },
});
