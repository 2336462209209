import { createAction, createSlice } from "@reduxjs/toolkit";
import { purchaseInitialState } from "../types";
const initialState: purchaseInitialState = {
  loadingPurchase: false,
  loadingCreatePurchase: false,
  purchases: [],
  // for edit
  purchase: null,
  loadingPurchaseDetails: false,
  // items
  loadingPurchaseItem: false,
  purchaseItems: [],
  // suppliers
  purchaseSuppliers: [],
  supplerId: "",
  createdSupplier: null,
  // payment modes
  purchasePaymentModes: [],
  loadingPurchasePaymentMode: false,
  // packing type
  purchaseDetails: [],
  purchaseMasterData: [],
  loadingPurchaseSupplier: false,
  purchaseId: 0,
  purchaseRowData: null,
  // view details
  loadingPurchaseViewDetails: false,
  viewPurchaseDetails: [],
  purchaseDetailCount: 0,
  purchaseDetailNext: "",
  purchaseDetailPrevious: "",
  edit: false,
  count: 0,
  previous: "",
  next: "",
  //search data
  purchaseDetailsCopy: [],
  //additional charge type
  purchaseAdditionalCharges: [],
  loadingPurchaseAdditionalCharge: false,
  // discount scheme
  loadingPurchaseDiscountScheme: false,
  purchaseDiscountSchemes: [],
};
// get purchase request
export const getPurchaseRequest = createAction(
  "getPurchaseRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
// get next request
export const getPurchaseNextRequest = createAction(
  "getPurchaseNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getPurchasePreviousRequest = createAction(
  "getPurchasePreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
// purchase slice
export const Purchase = createSlice({
  name: "purchaseReducer",
  initialState,
  reducers: {
    // purchase
    loadingPurchase: (state) => {
      state.loadingPurchase = true;
    },
    getPurchaseSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingPurchase = false;
      state.purchases = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getPurchaseFail: (state) => {
      state.loadingPurchase = false;
    },
    createPurchaseRequest: (state, payload) => {
      state.loadingCreatePurchase = true;
    },
    createPurchaseSuccess: (state) => {
      state.loadingCreatePurchase = false;
    },
    createPurchaseFail: (state) => {
      state.loadingCreatePurchase = false;
    },
    handleDeletePurchaseDetails: (state, { payload }) => {
      state.purchaseDetails = state.purchaseDetails?.filter(
        (details: any) => details?.uniqueId !== payload
      );
      state.purchaseDetailsCopy = state.purchaseDetails?.filter(
        (details: any) => details?.uniqueId !== payload
      );
    },
    getPurchaseDetailsById: (state, payload) => {
      state.edit = true;
      state.loadingPurchaseDetails = true;
    },
    editPurchaseSuccess: (
      state,
      { payload: { purchase, purchaseDetails } }
    ) => {
      state.purchase = purchase;
      state.purchaseDetails = purchaseDetails;
      state.loadingPurchaseDetails = false;
    },
    editPurchaseFail: (state) => {
      state.loadingPurchaseDetails = false;
    },
    // update
    updatePurchaseRequest: (state, payload) => {
      state.loadingCreatePurchase = true;
    },
    updatePurchaseSuccess: (state) => {
      state.loadingCreatePurchase = false;
    },
    updatePurchaseFail: (state) => {
      state.loadingCreatePurchase = false;
    },
    addPurchaseDetails: (state, { payload }) => {
      console.log("addPurchaseSlice", payload);
      console.log("statePurcahseDetails", state.purchaseDetails)
      const { purchaseDetails } = payload;
      const purchaseDetail = [
        ...state.purchaseDetails,
        purchaseDetails,
      ];
      // set purchase details in local storage
      localStorage.setItem("purchaseDetails", JSON.stringify(purchaseDetail));
      state.purchaseDetails = purchaseDetail;
      state.purchaseMasterData = delete payload.freePurchaseDetails && payload;
      state.purchaseDetailsCopy = state.purchaseDetails;
    },

    //additional charge
    getPurchaseAdditionalChargeRequest: (state) => {
      state.loadingPurchaseAdditionalCharge = true;
    },
    getPurchaseAdditionalChargeSuccess: (state, { payload }) => {
      state.purchaseAdditionalCharges = payload.results;
      state.loadingPurchaseAdditionalCharge = false;
    },
    getPurchaseAdditionalChargeFail: (state) => {
      state.loadingPurchaseAdditionalCharge = false;
    },
    updatePurchaseDetailsSuccess: (state, { payload }) => {
      state.purchaseDetails = payload;
    },
    loadingPurchaseSupplierReq: (state) => {
      state.loadingPurchaseSupplier = true;
    },
    // supplier reducers
    getPurchaseSupplierRequest: (state, { payload }) => {
      state.loadingPurchaseSupplier = true;
      state.supplerId = payload?.id;
    },
    getPurchaseSuppliersSuccess: (state, { payload }) => {
      state.purchaseSuppliers = payload.results;
      state.loadingPurchaseSupplier = false;
      state.createdSupplier =
        state.supplerId !== "" ? payload?.results[0] : null;
    },
    getPurchaseSuppliersFail: (state) => {
      state.loadingPurchaseSupplier = false;
    },
    getPurchasePaymentModeRequest: (state) => {
      state.loadingPurchasePaymentMode = true;
    },
    getPurchasePaymentModeSuccess: (state, { payload }) => {
      state.purchasePaymentModes = payload.results;
      state.loadingPurchasePaymentMode = false;
    },
    getPurchasePaymentModeFail: (state) => {
      state.loadingPurchasePaymentMode = false;
    },
    //purchase items
    getPurchaseItemRequest: (state, payload) => {
      state.loadingPurchaseItem = true;
    },
    loadingPurchaseItemReq: (state) => {
      state.loadingPurchaseItem = true;
    },
    getPurchaseItemsSuccess: (state, { payload }) => {
      state.purchaseItems = payload.results;
      state.loadingPurchaseItem = false;
    },
    getPurchaseItemsFail: (state) => {
      state.loadingPurchaseItem = false;
    },
    // discount scheme
    getPurchaseDiscountSchemeRequest: (state) => {
      state.loadingPurchaseDiscountScheme = true;
    },
    getPurchaseDiscountSchemeSuccess: (state, { payload: { results } }) => {
      state.loadingPurchaseDiscountScheme = false;
      state.purchaseDiscountSchemes = results;
    },
    getPurchaseDiscountSchemeFail: (state) => {
      state.loadingPurchaseDiscountScheme = false;
    },

    //view details
    getViewPurchaseDetailsRequest: (state, { payload }) => {
      state.loadingPurchaseViewDetails = true;
      state.purchaseRowData = payload?.rowData;
      state.purchaseId = payload?.id;
    },
    getViewPurchaseDetailsSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingPurchaseViewDetails = false;
      state.viewPurchaseDetails = results;
      state.purchaseDetailCount = count;
      state.purchaseDetailNext = next;
      state.purchaseDetailPrevious = previous;
    },
    getViewPurchaseDetailsFail: (state) => {
      state.loadingPurchaseViewDetails = false;
    },
    getViewPurchaseDetailsNextRequest: (state, payload) => {
      state.loadingPurchaseViewDetails = true;
    },
    getViewPurchaseDetailsPreviousRequest: (state, payload) => {
      state.loadingPurchaseViewDetails = true;
    },
    // get data with key pressed
    getViewPurchaseDetailsWithKeyPress: (state, { payload }) => {
      state.purchaseDetails = payload;
      state.purchaseDetailsCopy = payload;
    },
    clearPurchaseData: (state) => {
      state.edit = false;
      state.purchaseMasterData = [];
      state.purchaseDetails = [];
      state.purchaseDetailsCopy = [];
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingPurchase,
  getPurchaseSuccess,
  getPurchaseFail,
  clearPurchaseData,
  // create
  createPurchaseRequest,
  createPurchaseFail,
  createPurchaseSuccess,
  loadingPurchaseSupplierReq,
  // supplier
  getPurchaseSupplierRequest,
  getPurchaseSuppliersSuccess,
  getPurchaseSuppliersFail,
  // purchase item
  getPurchaseItemRequest,
  loadingPurchaseItemReq,
  getPurchaseItemsSuccess,
  getPurchaseItemsFail,
  // add edit and del
  addPurchaseDetails,
  updatePurchaseDetailsSuccess,
  handleDeletePurchaseDetails,
  // payment mode
  getPurchasePaymentModeSuccess,
  getPurchasePaymentModeFail,
  getPurchasePaymentModeRequest,
  // purchase details
  getViewPurchaseDetailsRequest,
  getViewPurchaseDetailsFail,
  getViewPurchaseDetailsSuccess,
  getViewPurchaseDetailsNextRequest,
  getViewPurchaseDetailsPreviousRequest,
  //additional charge
  getPurchaseAdditionalChargeRequest,
  getPurchaseAdditionalChargeSuccess,
  getPurchaseAdditionalChargeFail,
  // getPurchaseDetails,
  getPurchaseDetailsById,
  editPurchaseSuccess,
  editPurchaseFail,
  // discount schemes
  getPurchaseDiscountSchemeRequest,
  getPurchaseDiscountSchemeSuccess,
  getPurchaseDiscountSchemeFail,
  // update purchase
  updatePurchaseRequest,
  updatePurchaseSuccess,
  updatePurchaseFail,
  // get data when key is pressed
  getViewPurchaseDetailsWithKeyPress,
} = Purchase.actions;
export default Purchase.reducer;
