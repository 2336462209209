// @ts-ignore
import { filterData } from "../../Component/FilterData/types";
import { rowProps } from "../../Pages/ViewDetails/CommonTable/types";
import { globalSearchPostPerPage, prefixes } from "./globalConstant";
import { convertDateAdToBs, dateFormater } from "./dateFunctions";
import {
  alertErrorAction,
  commonAppFormikData,
} from "../../Pages/CommonAppRedux/CommonAppSlice";
import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { axiosInstance } from "./axios";
import { v4 as uuidv4 } from "uuid";

//function to filter api data
const filterApiData = (filterData: filterData) => {
  const { fromDateAd, toDateAd, fromDateBs, toDateBs, filterField } =
    filterData;
  // return array of string
  let filterFieldValue = filterField?.map(
    (x) =>
      `&${x.filterField ? x.filterField : x.id}=${x.filterFieldValue ?? ""}`
  );
  return `&date_after=${
    fromDateAd
      ? dateFormater(new Date(fromDateAd))
      : fromDateBs
      ? fromDateBs
      : ""
  }&date_before=${
    toDateAd ? dateFormater(new Date(toDateAd)) : toDateBs ? toDateBs : ""
  }${filterFieldValue?.join("")}`;
};
//function to check duplicate value
const checkDuplicateValue = (duplicateDataList: any, filedName: string) => {
  return duplicateDataList[0][`${filedName}`]?.length > 0
    ? duplicateDataList[0][`${filedName}`]?.length === 0
    : duplicateDataList[0][`${filedName}`] !== "undefined";
};
//custom round function
const customRound = (num: number | string) => {
  // The rounding problem can be avoided by using numbers represented in exponential notation:
  // @ts-ignore
  return +(Math.round(num + "e+2") + "e-2");
};

//data to convert file
const dataURLtoFile = (dataurl: any, filename: any) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
// date formater for start and end date in appointment module
const formatStartOrEndDate = (date: any): any => {
  // @ts-ignore
  const dateToFormat = new Date(date);
  const dateOnly = dateToFormat?.toISOString().substring(0, 10);
  const timeOnly = dateToFormat?.toTimeString().substring(0, 5);
  const finalDateTime = dateOnly + "T" + timeOnly;
  return finalDateTime;
};
// handle url when user search from autocomplete
const getSearchURL = (value: string) =>
  `offset=0&limit=${globalSearchPostPerPage}&search=${value.trimStart()}`;

// update details
const updateDetails = (data: any, key: string) => {
  return data?.map((data: any) => {
    const {
      [key]: { id },
    } = data;
    return { ...data, [key]: id };
  });
};
// check equality between two objects
const checkEqualityData = (
  values: any,
  initialValues: any,
  checkEquality: React.MutableRefObject<boolean>,
  dispatch: Dispatch<AnyAction>
) => {
  checkEquality.current =
    JSON.stringify(values) === JSON.stringify(initialValues) ? true : false;
  dispatch(commonAppFormikData(checkEquality.current));
};
const onKeyUpFunction = (e: any) => {
  if (e.key === "Enter") {
    // @ts-ignore
    const next = inputRef.current[i + 1];
    if (next) {
      //@ts-ignore
      return next.focus();
    }
  }
};

//
const handleFollowUpCheck = (
  values: any,
  setFieldValue: any,
  dispatch: any
) => {
  axiosInstance
    .get(`/api/v1/global-api/customer/check-follow-up-status/${values?.id}`)
    .then((response: any) => {
      if (response.status === 200) {
        // dispatch(alertSuccessAction("Successfully"));
      }
    })
    .catch((error: any) => {
      dispatch(
        alertErrorAction(
          `${values?.fullName} Patient Not Follow Up Today Please Follow Up First For claim Code`
        )
      );
      setFieldValue("customer", null);
    });
};
//isDefault Check api unique
const handleIsDefaultCheck = (
  setFieldValue: any,
  dispatch: any,
  apiUrl: any,
  keyName: any
) => {
  axiosInstance
    .get(`${apiUrl}?is_default=true`)
    .then((response: any) => {
      let data = response?.data?.results[0];
      if (response.status === 200) {
        setFieldValue(`${keyName}`, data ?? null);
      }
    })
    .catch((error: any) => {
      dispatch(alertErrorAction(`No Default Data`));
    });
};

//nshi get data from url
const handleGetNshiDataCheck = (
  setNshiData: any,
  dispatch: any,
  nshiNo: any
) => {
  const formattedNshiNo = nshiNo.replace(/-/g, "");
  axiosInstance
    .get(`api/v1/global-api/enquire-insuree-alt?nshi_no=${formattedNshiNo}`)
    .then((response: any) => {
      let data = response?.data;
      if (response.status === 200) {
        setNshiData(data);
      }
    })
    .catch((error: any) => {
      dispatch(alertErrorAction(`Bad Server Connection Insurance`));
    });
};

const getDynamicPermissionData = (
  permissionData: string[]
): { [key: string]: string } => {
  const passPermission: { [key: string]: string } = {};

  prefixes.forEach((prefix) => {
    const permission = permissionData.find((p) => p.includes(`${prefix}_`));
    if (permission) {
      passPermission[`${prefix}_permission`] = permission;
    }
  });

  return passPermission;
};

const parseDateFormaterNshi = (inputString: any, rule: any) => {
  const bsIndex = inputString.indexOf("BS:");
  const adIndex = inputString.indexOf("AD:");
  if (bsIndex >= 0 && adIndex >= 0) {
    const bsDate = inputString.substring(bsIndex + 3, adIndex - 1);
    const adDate = inputString.substring(adIndex + 4);
    let formattedBsDate = bsDate.replace(/\//g, "-");
    let formattedAdDate = adDate.replace(/\//g, "-");
    let dateArrAd = formattedAdDate.split("-");
    let dateArrBs = formattedBsDate.split("-");

    let formattedDateAd = "";
    let formattedDateBs = "";

    //@ts-ignore
    if (rule?.dateFormat === "YYYY-MM-DD") {
      formattedDateAd = dateArrAd[2] + "-" + dateArrAd[1] + "-" + dateArrAd[0];
      formattedDateBs = dateArrBs[2] + "-" + dateArrBs[1] + "-" + dateArrBs[0];
    } else {
      formattedDateAd = dateArrAd[0] + "-" + dateArrAd[1] + "-" + dateArrAd[2];
      formattedDateBs = dateArrBs[0] + "-" + dateArrBs[1] + "-" + dateArrBs[2];
    }

    return {
      formattedBsDate,
      formattedAdDate,
      formattedDateAd,
      formattedDateBs,
    };
  } else {
    return null; // Return null for invalid input
  }
};

const parseExpiryDateFormaterRemaining = (inputString: any, rule: any) => {
  let dateArrAd = inputString.split("/");
  let formattedDateAdForAmount = "";
  //@ts-ignore
  if (rule?.dateFormat === "YYYY-MM-DD") {
    formattedDateAdForAmount =
      dateArrAd[2] + "-" + dateArrAd[1] + "-" + dateArrAd[0];
  } else {
    formattedDateAdForAmount =
      dateArrAd[0] + "-" + dateArrAd[1] + "-" + dateArrAd[2];
  }
  return { formattedDateAdForAmount };
};

const filterDataTodayListing = {
  filterField: [],
  fromDateAd: new Date(),
  fromDateBs: convertDateAdToBs(new Date()),
  toDateAd: new Date(),
  toDateBs: convertDateAdToBs(new Date()),
};

const isValidURL = (value: string | undefined) => {
  if (typeof value === "string") {
    try {
      new URL(value);
      return true;
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
};

interface AttributeValue {
  attribute: number;
  value: string;
}

interface Attribute {
  attribute: number;
  title: string;
  id: AttributeValue[];
}

const generateCombinations = (obj: Attribute[]) => {
  const combinations: any[] = [];
  function helper(currentCombination: any, index: number) {
    if (index === obj.length) {
      // const hasNonEmptyValue = currentCombination.some(
      //   (item: any) => item.value !== "")
      // if (!hasNonEmptyValue) {
      const newObj = {
        //assinging a unique id to the row which is needed for deleting it from the table
        uniqueId: uuidv4(),
        sku: "",
        name: "",
        maxOrderableQty: "",
        featuredImage: null,
        hoverImage: null,
        price: "",
        hasOffer: false,
        offerPrice: "",
        isDefault: false,
        isActive: true,
        productVariantAttributes: currentCombination.map((item: any) => {
          return {
            variantId: item?.variantId,
            id: item?.id,
            attribute: item.attribute,
            value: item.value,
          };
        }),
        productVariantImages: [
          {
            id: null,
            image: null,
            displayOrder: null,
            altText: "",
          },
        ],
      };
      combinations.push(newObj);
      // }
      return;
    }
    const currentArray = obj[index].id;

    for (let i = 0; i < currentArray.length; i++) {
      helper([...currentCombination, currentArray[i]], index + 1);
    }
    // if (currentArray.length === 0) {
    //   helper(currentCombination, index + 1);
    // } else {
    //   for (let i = 0; i < currentArray.length; i++) {
    //     helper([...currentCombination, currentArray[i]], index + 1);
    //   }
    // }
  }
  helper([], 0);
  return combinations;
};

const handleGetDailyFollowPatientCheck = (
  dispatch: any,
  searchData: string,
  apiUrl:string,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`${apiUrl}?offset=0&limit=0&search=${searchData}`)
      .then((response: any) => {
        let data = response?.data?.results;
        if (response.status === 200) {
          resolve(data); // Resolve the promise with the data
        } else {
          reject('Error: Unexpected response status');
        }
      })
      .catch((error: any) => {
        dispatch(alertErrorAction(`Bad Server Connection Server`));
        reject(error); 
      });
  });
};

export {
  dateFormater,
  formatStartOrEndDate,
  dataURLtoFile,
  checkDuplicateValue,
  filterApiData,
  customRound,
  getSearchURL,
  checkEqualityData,
  onKeyUpFunction,
  updateDetails,
  handleFollowUpCheck,
  handleIsDefaultCheck,
  getDynamicPermissionData,
  handleGetNshiDataCheck,
  parseExpiryDateFormaterRemaining,
  parseDateFormaterNshi,
  filterDataTodayListing,
  isValidURL,
  generateCombinations,
  handleGetDailyFollowPatientCheck
};

//read time for blog
export const getReadTime = (content: string): number => {
  //creating a temp element and setting the html content to get the corresponding string representation
  const tempElem = document.createElement("div");
  tempElem.innerHTML = content;
  //for counting no of images in the content
  const imageCount = tempElem.getElementsByTagName("img").length;

  const textContent = tempElem.textContent;
  const wordCount = textContent?.trim().split(/\s+/).length;

  let readTimeInMinHigh: number = 0;
  if (wordCount) {
    readTimeInMinHigh = Math.ceil(wordCount / 265);
  }

  //for the first 10 image set the read time starting from 12 sec decrease it for subsiquent images and for images after 10th count set read time to 3 sec.

  let imageReadTime: number = 0;
  for (let i = 0; i < imageCount; i++) {
    if (i < 10) {
      imageReadTime += 12 - i;
    } else {
      imageReadTime += 3;
    }
  }
  const imageReadTimeInMinHigh = Math.ceil(imageReadTime / 60);
  return readTimeInMinHigh + imageReadTimeInMinHigh;
};

//check duplicate string in array of string
export const doesStringExists = (strArray: string[], value: string) => {
  if (strArray?.length > 0 && value !== undefined) {
    return strArray.find((str: string) => {
      return str === value;
    });
  }
};
