import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const palikaModURL = "api/v1/core-app/palika";

//get Palika data api
export const getPalika = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${palikaModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get palika district
export const getPalikaDistrict = () => {
  return axiosInstance.get(`${palikaModURL}/district`);
};

//get palika by id
export const getPalikaById = (id: number) => {
  return axiosInstance.get(`${palikaModURL}/${id}`);
};

//handle search
export const searchPalika = ({ rowsPerPage, search }: searchProps) => {
  return `${palikaModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Palika
export const createPalika = (body: string | FormData) =>
  axiosInstance.post(`${palikaModURL}`, body);
//update Palika
export const updatePalika = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${palikaModURL}/${id}`, body);
//get default palika
export const getDefaultPalika = () => {
  return axiosInstance.get(`${palikaModURL}?is_default=true`);
};
