import {
  getSaleReportCustomerRequest,
  getSaleReportItemRequest,
  getSaleReportUserRequest,
  getSaleReportAllSummaryRequest,
  getSaleReportAllDetailRequest,
  getSaleReportQuickSummaryRequest,
  getSaleReportQuickDetailRequest,
//reducer get data
getSaleReportItemSuccess,
getSaleReportItemFail,
getSaleReportCustomerSuccess,
getSaleReportCustomerFail,
getSaleReportUserSuccess,
getSaleReportUserFail,
getSaleReportQuickDetailSuccess,
getSaleReportQuickDetailFail,
getSaleReportAllDetailSuccess,
getSaleReportAllDetailFail,
getSaleReportQuickSummarySuccess,
getSaleReportQuickSummaryFail,
getSaleReportAllSummarySuccess,
getSaleReportAllSummaryFail,
getSaleReportDetailSuccess,
getSaleReportDetailFail,
loadingSaleReportDetail,
getSaleReportPreviousRequest,
getSaleReportNextRequest,
getSaleReportDetailRequest,
} from "./saleReportSlice";
import { mergeMap } from "rxjs";
import  *  as API from  "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../../AppUtils/Utils/validationConstants";
import { ContactsOutlined } from "@mui/icons-material";
export const controller = new AbortController();
const getSaleReportCustomerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleReportCustomerRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleReportCustomer(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleReportCustomerSuccess(action?.payload)
        : getSaleReportCustomerFail()
    )
  );
  //user
  const getSaleReportUserEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
  ) =>
    action$.pipe(
      filter(getSaleReportUserRequest.match),
      mergeMap(async (action) => {
        try {
          const response = await API.getSaleReportUser();
          return { payload: response.data };
        } catch (e) {
          return { error: e };
        }
      }),
      map((action) =>
        action?.payload
          ? getSaleReportUserSuccess(action?.payload)
          : getSaleReportUserFail()
      )
    );
    //item
    const getSaleReportItemEpic = (
      action$: Observable<Action>,
      _: stateAction,
      { dispatch }: dispatchAction
    ) =>
      action$.pipe(
        filter(getSaleReportItemRequest.match),
        mergeMap(async (action) => {
          try {
            const response = await API.getSaleReportItem();
            return { payload: response.data };
          } catch (e) {
            return { error: e };
          }
        }),
        map((action) =>
          action?.payload
            ? getSaleReportItemSuccess(action?.payload)
            : getSaleReportItemFail()
        )
      );

      //get all Sale summary detail
      const getSaleReportAllSummaryEpic = (
        action$: Observable<Action>,
        _: stateAction,
        { dispatch }: dispatchAction
      ) =>
        action$.pipe(
          filter(getSaleReportAllSummaryRequest.match),
          mergeMap(async (action) => {
            try {
              const response = await API.getSaleReportAllReport(action?.payload);
              if (response.data.result?.length === 0) {
                dispatch(
                  alertErrorAction(`No Summary Data Found`)
                );
              }
              return { payload: response.data };
            } catch (e) {
              return { error: e };
            }
          }),
          map((action) =>
            action?.payload
              ? getSaleReportAllSummarySuccess(action?.payload)
              : getSaleReportAllSummaryFail()
          )
        );
        //quick summary
        const getSaleReportQuickSummaryEpic = (
          action$: Observable<Action>,
          _: stateAction,
          { dispatch }: dispatchAction
        ) =>
          action$.pipe(
            filter(getSaleReportQuickSummaryRequest.match),
            mergeMap(async (action) => {
              try {
                const response = await API.getSaleReportQuickReport(action.payload);
                if (response?.data?.result?.length === 0) {
                  dispatch(
                    alertErrorAction(`No Summary Data Found`)
                  );
                }
                return { payload: response.data };
              } catch (e) {
                return { error: e };
              }
            }),
            map((action) =>
              action?.payload
                ? getSaleReportQuickSummarySuccess(action?.payload)
                : getSaleReportQuickSummaryFail()
            )
          );

           //get all Sale summary detail
      const getSaleReportAllDetailEpic = (
        action$: Observable<Action>,
        _: stateAction,
        { dispatch }: dispatchAction
      ) =>
        action$.pipe(
          filter(getSaleReportAllDetailRequest.match),
          mergeMap(async (action) => {
            try {
              const response = await API.getSaleReportAllReport(action?.payload);
              if (response.data.result?.length === 0) {
                dispatch(
                  alertErrorAction(`No Detail Data Found`)
                );
              }
              return { payload: response.data };
            } catch (e) {
              return { error: e };
            }
          }),
          map((action) =>
            action?.payload
              ? getSaleReportAllDetailSuccess(action?.payload)
              : getSaleReportAllDetailFail()
          )
        );
        //quick Detail
        const getSaleReportQuickDetailEpic = (
          action$: Observable<Action>,
          _: stateAction,
          { dispatch }: dispatchAction
        ) =>
        action$.pipe(
          filter(getSaleReportQuickDetailRequest.match),
          mergeMap(async (action) => {
            try {
              const response = await API.getSaleReportQuickReport(action.payload);
              if (response.data.result?.length === 0) {
                dispatch(
                  alertErrorAction(`No Detail Data Found`)
                );
              }
              return { payload: response.data };
            } catch (e) {
              return { error: e };
            }
          }),
          map((action) =>
            action?.payload
              ? getSaleReportQuickDetailSuccess(action?.payload)
              : getSaleReportQuickDetailFail()
          )
        );

        //next and previous
        //get next
const getSaleReportNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleReportNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSaleReportDetail());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleReportDetailSuccess(action?.payload)
        : getSaleReportDetailFail()
    )
  );
//get previous
const getSaleReportPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSaleReportPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSaleReportDetail());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleReportDetailSuccess(action?.payload)
        : getSaleReportDetailFail()
    )
  );
  //het Sale report details
  const getSaleReportEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
  ) =>
  action$.pipe(
    filter(getSaleReportDetailRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await API.getSaleReportQuickReport(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSaleReportDetailSuccess(action?.payload)
        : getSaleReportDetailFail()
    )
  );
export const saleReportEpics = combineEpics(
  getSaleReportCustomerEpic,
  getSaleReportItemEpic,
  getSaleReportUserEpic,
  getSaleReportAllSummaryEpic,
  getSaleReportQuickSummaryEpic,
  getSaleReportQuickDetailEpic,
  getSaleReportAllDetailEpic,
  getSaleReportPrevious,
  getSaleReportNext,
  getSaleReportEpic,
);
