import {
  loadingOrganization,
  getOrganizationSuccess,
  getOrganizationFail,
  createOrganizationSuccess,
  createOrganizationFail,
  organizationEditSuccess,
  updateOrganizationSuccess,
  updateOrganizationFail,
  clearOrganizationData,
  getOrganizationRequest,
  updateOrganizationRequest,
  getOrganizationNextRequest,
  getOrganizationPreviousRequest,
  createOrganizationRequest,
  getOrganizationCountryRequest,
  getOrganizationCountrySuccess,
  getOrganizationCountryFail,
  getOrganizationByIdRequest,
  organizationEditFail,
} from "./organizationSlice";
import { mergeMap } from "rxjs";
import {
  createOrganization,
  getOrganization,
  updateOrganization,
  getOrganizationCountry,
  getOrganizationById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
  getGlobalOrganizationRequest,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get oeganization epic
const getOrganizationEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingOrganization());
      try {
        const response = await getOrganization(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrganizationSuccess(action?.payload)
        : getOrganizationFail()
    )
  );

//get organization by Id epic
const getOrganizationByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getOrganizationById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? organizationEditSuccess(action.payload)
        : organizationEditFail()
    )
  );

const getOrganizationCountryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationCountryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getOrganizationCountry();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrganizationCountrySuccess(action?.payload)
        : getOrganizationCountryFail()
    )
  );
//get next
const getOrganizationNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingOrganization());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrganizationSuccess(action?.payload)
        : getOrganizationFail()
    )
  );
//get previous
const getOrganizationPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingOrganization());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrganizationSuccess(action?.payload)
        : getOrganizationFail()
    )
  );
//create user epic
const createOrganizationEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createOrganizationRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { socialMediaLinks, location, ...restValues } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        for (let x in socialMediaLinks) {
          body.append(
            `socialMediaLinks[${x}][socialMedia]`,
            socialMediaLinks[x].socialMedia
          );
          body.append(`socialMediaLinks[${x}][link]`, socialMediaLinks[x].link);
          body.append(
            `socialMediaLinks[${x}][isActive]`,
            socialMediaLinks[x].isActive
          );
        }
        for (let [key, value] of Object.entries(location)) {
          // @ts-ignore
          body.append(`location[${key}]`, value);
        }
        const response = await createOrganization(body);
        if (response) {
          dispatch(getOrganizationRequest({ rowsPerPage, page }));
          // dispatch(getGlobalOrganizationRequest());
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createOrganizationSuccess()
        : createOrganizationFail();
    })
  );
//update organization epic
const updateOrganizationEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateOrganizationRequest.match),
    mergeMap(
      async ({
        payload: {
          values: { socialMediaLinks, location, ...restValues },
          id,
          rowsPerPage,
          page,
        },
      }) => {
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }

        try {
          for (let x in socialMediaLinks) {
            body.append(
              `socialMediaLinks[${x}][socialMedia]`,
              socialMediaLinks[x].socialMedia
            );
            body.append(
              `socialMediaLinks[${x}][link]`,
              socialMediaLinks[x].link
            );
            body.append(
              `socialMediaLinks[${x}][isActive]`,
              socialMediaLinks[x].isActive
            );
          }
          for (let [key, value] of Object.entries(location)) {
            // @ts-ignore
            body.append(`location[${key}]`, value);
          }
          const response = await updateOrganization(body, id);
          if (response) {
            dispatch(getOrganizationRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.updateMessage));
            // dispatch(getGlobalOrganizationRequest());
            dispatch(clearOrganizationData());
            dispatch(closeModal());
          }
          return { payload: { response, rowsPerPage } };
        } catch (e) {
          dispatch(alertErrorAction(messages.updateFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload ? updateOrganizationSuccess() : updateOrganizationFail()
    )
  );

//

export const organizationEpics = combineEpics(
  getOrganizationEpic,
  createOrganizationEpic,
  updateOrganizationEpic,
  getOrganizationNext,
  getOrganizationPrevious,
  getOrganizationCountryEpic,
  getOrganizationByIdEpic
);
