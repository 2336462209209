import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const OrganizationModURL = "/api/v1/admin/core-app/organization-setup";

export const getOrganization = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${OrganizationModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//get organization by Id
export const getOrganizationById = (id: number) => {
  return axiosInstance.get(`${OrganizationModURL}/${id}`);
};

export const getOrganizationCountry = () =>
  axiosInstance.get(`${OrganizationModURL}/country`);

//handle search
export const searchOrganization = ({ rowsPerPage, search }: searchProps) => {
  return `${OrganizationModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createOrganization = (body: string | FormData) =>
  axiosInstance.post(`${OrganizationModURL}`, body);
export const updateOrganization = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${OrganizationModURL}/${id}`, body);
