import {
  getItemListDetailRequest,
  getItemListDetailFail,
  getItemListDetailSuccess,
  clearAllData,
  getItemListDetailNextRequest,
  getItemListDetailPreviousRequest,
} from "./ItemListDetailSlice";
import { mergeMap } from "rxjs";
import { getItemListDetail, searchItemListDetail } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get Bank
const getItemListDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemListDetailRequest.match),
    mergeMap(async (action: any) => {
      try {
        const response = await getItemListDetail(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getItemListDetailSuccess(action?.payload)
        : getItemListDetailFail()
    )
  );
//get next
const getItemListDetailNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemListDetailNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? getItemListDetailSuccess(action?.payload)
        : getItemListDetailFail();
    })
  );

//get previous
const getItemListDetailPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getItemListDetailPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getItemListDetailSuccess(action?.payload)
        : getItemListDetailFail()
    )
  );

export const itemListDetailEpics = combineEpics(
  getItemListDetailEpic,
  getItemListDetailNext,
  getItemListDetailPrevious
);
