import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  StockAnalysiss: [],
  edit: false,
  StockAnalysis: null,
  loadingStockAnalysis: false,
  count: 0,
  next: "",
  previous: "",
};


export const getStockAnalysisRequest = createAction(
  "getStockAnalysisRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);


// get next request
export const getStockAnalysisNextRequest = createAction(
  "getStockAnalysisNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getStockAnalysisPreviousRequest = createAction(
  "getStockAnalysisPreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);
export const StockAnalysis = createSlice({
  name: "StockAnalysisReducer",
  initialState,
  reducers: {
    loadingStockAnalysis: (state) => {
      state.loadingStockAnalysis = true;
    },
    getStockAnalysisSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingStockAnalysis = false;
      state.StockAnalysiss = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getStockAnalysisFail: (state) => {
      state.loadingStockAnalysis = false;
    },
    postStockAnalysisRequest:(state)=>{
      state.loadingUploadStockAnalysis=true;
    },
    postStockAnalysisUploadSuccess:(state)=>{
      state.loadingUploadStockAnalysis=false;
    },
    
    clearStockAnalysisData: (state) => {
      state.edit = false;
      state.StockAnalysis = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingStockAnalysis,
  getStockAnalysisSuccess,
  getStockAnalysisFail,
  clearStockAnalysisData,
  postStockAnalysisUploadSuccess,
  postStockAnalysisRequest,
} = StockAnalysis.actions;
export default StockAnalysis.reducer;
